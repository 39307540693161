import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const AdvancedStandingCommentsDialog = ({ classes, unitEnrolment, open, onClose }) => {
  if (unitEnrolment) {
    return (
      <Dialog onClose={onClose} aria-labelledby="advanced-standing-comments-dialog" open={open} TransitionComponent={Transition} maxWidth="md">
        <DialogTitle id="advanced-standing-comments-dialog-title">{unitEnrolment && unitEnrolment.title}</DialogTitle>
        <DialogContent>
          <p className={classes.comments}>{unitEnrolment.comments}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

AdvancedStandingCommentsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  unitEnrolment: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AdvancedStandingCommentsDialog.defaultProps = {
  unitEnrolment: null,
};

const styles = {
  comments: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
};

export default withStyles(styles)(AdvancedStandingCommentsDialog);
