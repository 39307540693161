import { Button, CircularProgress, Dialog, DialogActions, DialogContent, makeStyles, Slide, TextField } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import Alert from 'react-s-alert';
import bytes from 'bytes';
import api from '../../services/api';
import { extensionsString, image, msOffice, pdf } from '../../util/file-extensions';
import AttachmentList from '../comments/AttachmentList';
import Upload from '../common/Upload';
import { MAX_SIZE_BYTES } from '../forms/DropzoneField';
import DialogTitleWithClose from '../common/DialogTitleWithClose';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="left" {...props} />);

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    margin: theme.spacing(4, 1, 1, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  savedCourseMapContainer: {
    marginTop: theme.spacing(2),
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
}));

const UpdateStudentDetailsForm = ({ onSubmit, open, onCancel, submitting, initialValues, errorMessage }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    preferredName: initialValues.preferredName,
    courseMap: initialValues.courseMap,
  });

  const [uploading, updateUploading] = useState(false);

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const removeCourseMap = () => {
    setValues({ ...values, courseMap: null });
  };

  const uploadAttachment = (files) => {
    if (files && files.length > 0) {
      updateUploading(true);
      // only support single course map
      const file = files[0];
      api.storage
        .uploadCourseMapAttachmentForStudent(file, initialValues.studentId)
        .then((attachment) => setValues({ ...values, courseMap: attachment }))
        .catch((err) => {
          Alert.error(`Error uploading file: ${file.name}. ${err.message}`);
          console.error('Error uploading file %o: ', file, err);
        })
        .finally(() => updateUploading(false));
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth TransitionComponent={Transition}>
      <DialogTitleWithClose title="Update student details" onClose={onCancel} />
      <DialogContent>
        <form>
          <TextField
            id="preferredName"
            label="Juvo Nickname"
            value={values.preferredName}
            onChange={handleChange('preferredName')}
            type="search"
            autoFocus
            error={!!errorMessage}
            helperText={errorMessage && `Failed to update student details: ${errorMessage}`}
            margin="normal"
            fullWidth
            inputProps={{ maxLength: 30 }}
          />
          <div className={classes.savedCourseMapContainer}>
            <Upload
              handleUpload={uploadAttachment}
              accept={`image/*,${extensionsString(msOffice, pdf, image)}`}
              label="Course map"
              icon={<MapIcon className={classes.iconLeft} />}
              uploading={uploading}
              maxSize={MAX_SIZE_BYTES}
              tooltip={`Attach single course map for student (max ${bytes(MAX_SIZE_BYTES)})`}
              multiple={false}
              variant="outlined"
              iconPlacement="left"
            />
            <AttachmentList attachments={values.courseMap ? [values.courseMap] : []} onRemove={removeCourseMap} />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsContainer}>
          <div>
            <Button onClick={onCancel} disabled={submitting || uploading}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => onSubmit(values)} disabled={submitting || uploading}>
              {submitting ? <CircularProgress size={20} /> : 'Update student'}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

UpdateStudentDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default UpdateStudentDetailsForm;
