import { requestJSON } from './http';

const list = () =>
  requestJSON('/announcements');

const create = (announcement) =>
  requestJSON('/announcements', 'POST', announcement);

const deleteAnnouncement = (id) =>
  requestJSON(`/announcements/${id}`, 'DELETE');

export default {
  list,
  create,
  deleteAnnouncement,
};
