import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import EmptyLayout from '../components/layouts/EmptyLayout';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import NotFoundPage from '../pages/NotFoundPage';
import RegisterPage from '../pages/RegisterPage';
import { history } from '../store';
import AdminRoutes from './AdminRoutes';
import AdviserRoutes from './AdviserRoutes';
import AsyncComponent from './AsyncComponent';
import { loginRequired } from './hooks';
import RoleRedirect from './RoleRedirect';
import StaffRoutes from './StaffRoutes';
import StudentRoutes from './StudentRoutes';
import AdminOrAdviserRoutes from './AdminOrAdviserRoutes';
import Alert from 'react-s-alert';

const HomeRoutes = () => (
  <EmptyLayout>
    <AsyncComponent asyncActions={[loginRequired]}>
      <Route path="/" component={RoleRedirect} />

      <Switch>
        <Route path="/" component={HomePage} />
      </Switch>
    </AsyncComponent>
  </EmptyLayout>
);

export default () => (
  <ConnectedRouter history={history}>
    <>
      <Alert effect="slide" position="bottom-right" stack />
      <Helmet>
        <title>Adviser Dashboard</title>
      </Helmet>

      <Switch>
        <Route path="/admin" component={AdminRoutes} />
        <Route path="/adviser" component={AdviserRoutes} />
        <Route path="/student" component={StudentRoutes} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/register/:inviteCode" component={RegisterPage} />
        <Route exact path="/" component={HomeRoutes} />
        <Route exact path="/referrals" component={StaffRoutes} />
        <Route exact path="/my-profile" component={AdminOrAdviserRoutes} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  </ConnectedRouter>
);
