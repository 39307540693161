export default (state = { forwardedComment: null }, action) => {
  switch (action.type) {
    case 'ADD_FORWARDED_COMMENT':
      return { forwardedComment: action.comment };
    case 'REMOVE_FORWARDED_COMMENT':
      return { forwardedComment: null };
    default:
      return state;
  }
};

export const get = (state) => state.forwardedComment;
