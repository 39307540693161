import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Snackbar,
  Grid,
  Paper,
  Typography,
  IconButton,
  SnackbarContent,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  withStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Close as CloseIcon } from '@material-ui/icons';
import Helmet from 'react-helmet';
import api from '../services/api';
import ReferralForm from '../components/forms/ReferralForm';
import storage from '../services/api/storage';

const formName = 'referral';

class ReferralsPage extends Component {
  state = {
    confirmationMessage: null,
    serverErrorMessages: null,
    submitting: false,
    snackBarOpen: false,
    checkSpecConOpen: false,
    adviserName: '',
    formValues: {},
  };

  handleCloseSnackbar = () => {
    this.setState({ snackBarOpen: false });
  };

  handleSubmit = (values) => {
    const specConInText = values.message.search(/special consideration|spec con/gim) >= 0;

    if (specConInText && !values.specialConsideration) {
      this.setState({ checkSpecConOpen: true, formValues: values });
    } else {
      this.submit(values);
    }
  };

  submit = (values) => {
    const { resetForm } = this.props;
    this.setState({ submitting: true, serverErrorMessages: null, confirmationMessage: null });

    return api.messages
      .create(values.studentEmailOrId, values.message, values.attachments, values.specialConsideration)
      .then((response) => {
        resetForm('referral');
        this.setState({
          submitting: false,
          snackBarOpen: true,
          confirmationMessage: (
            <span>
              Message sent to adviser for student <b>{response.studentName}</b> ({response.studentEmailOrId})
            </span>
          ),
          checkSpecConOpen: false,
          adviserName: '',
        });
      })
      .catch((error) =>
        this.setState({
          submitting: false,
          serverErrorMessages: error.messages,
          confirmationMessage: null,
        }),
      );
  };

  onCloseCheckSpecCon = () => this.setState({ checkSpecConOpen: false });

  submitWithSpecCon = () => {
    const { changeForm } = this.props;
    const { formValues } = this.state;
    this.setState({ checkSpecConOpen: false });
    changeForm(formName, 'specialConsideration', true);
    this.submit({ ...formValues, specialConsideration: true });
  };

  lookupAdviser = (syncErrors, formValues) => {
    if (!syncErrors.studentEmailOrId) {
      api.students
        .getAdviser(formValues.studentEmailOrId)
        .then((response) => {
          this.setState({ adviserName: response.name });
        })
        .catch(() => this.setState({ adviserName: '' }));
    } else {
      console.log('syncErrors');
      this.setState({ adviserName: '' });
    }
  };

  render() {
    const { classes } = this.props;
    const { formValues } = this.state;
    const { submitting, serverErrorMessages, confirmationMessage, snackBarOpen, checkSpecConOpen, adviserName } = this.state;

    return (
      <>
        <Helmet>
          <title>Adviser Referral</title>
        </Helmet>

        <div style={{ padding: 50 }}>
          <Grid container justify="center">
            <Grid item lg={10} md={10} xs={12}>
              <Paper style={{ padding: 30 }}>
                <Typography variant="h4" paragraph component="h3">
                  Referral to Education Success Adviser
                </Typography>
                <Typography paragraph>
                  This website should be used by staff to refer current Faculty of Education student issues or enquiries to the Education Success
                  Advisers. This referral will be sent as a internal communication to the adviser only, and will not be visible to the student.
                </Typography>
                <ReferralForm
                  onSubmit={this.handleSubmit}
                  submitting={submitting}
                  serverErrorMessages={serverErrorMessages}
                  form={formName}
                  lookupAdviser={this.lookupAdviser}
                  adviserName={adviserName}
                  uploadFile={(file) => storage.uploadInternalAttachmentForStudents(file)}
                />
                <br />
                <Typography variant="caption" paragraph>
                  The Juvo system will automatically match the students email address with their adviser so you can be sure the correct staff member
                  will receive this referral. If you don&#39;t know the students email address, you can find this in Moodle in the students profile,
                  in UniCRM or in Callista.
                </Typography>
                <Typography variant="caption" paragraph>
                  If the system does not recognise the students email, please email your referral to{' '}
                  <a href="mailto:education.studentservices@monash.edu">education.studentservices@monash.edu</a>. Graduate Research/HDR students are
                  not managed by Education Success Advisers, instead contact{' '}
                  <a href="mailto:edu-gradresearch@monash.edu">edu-gradresearch@monash.edu</a>.
                </Typography>
                <Typography variant="caption" paragraph>
                  Please note that all student related records could be released as required by law in the unlikely event of a request under the{' '}
                  <a href="https://www.monash.edu/execserv/foi" target="_blank" rel="noopener noreferrer">
                    Freedom of Information Act
                  </a>{' '}
                  or Subpoenas.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'confirmation-message',
          }}
        >
          <SnackbarContent
            className={classes.success}
            message={<span id="confirmation-message">{confirmationMessage}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleCloseSnackbar}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>

        <Dialog onClose={this.onCloseCheckSpecCon} aria-labelledby="check-spec-con-dialog" open={checkSpecConOpen} maxWidth="md">
          <DialogTitle id="check-spec-con-dialog-title">Should this be marked as special consideration?</DialogTitle>
          <DialogActions>
            <Button onClick={() => this.submit(formValues)} variant="contained">
              No
            </Button>
            <Button onClick={this.submitWithSpecCon} variant="contained" color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ReferralsPage.propTypes = {
  resetForm: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

export default connect(
  null,
  {
    resetForm: reset,
    changeForm: change,
  },
)(withStyles(styles)(ReferralsPage));
