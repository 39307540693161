import { Button, makeStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { length, required } from 'redux-form-validators';
import { Switch, TextField } from '../wrappers/material';
import FormError from './FormError';
import RichTextReduxComponentWithQuickLinks from './RichTextReduxComponentWithQuickLinks';

const useStyles = makeStyles((theme) => ({
  activeAndActionButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
}));

const MessageTemplateForm = ({ error, handleSubmit, submitting, onCancel }) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <FormError value={error} />
      <Field
        name="title"
        margin="normal"
        component={TextField}
        placeholder="Title"
        label="Title"
        validate={[required(), length({ max: 150 })]}
        style={{ marginBottom: 20 }}
        fullWidth
      />
      <Field
        name="content"
        component={RichTextReduxComponentWithQuickLinks}
        placeholder="Message template body ..."
        label="Content"
        type="text"
        margin="normal"
        rows="8"
        multiline
        validate={[required({ msg: 'Please add some content' }), length({ max: 10000, msg: 'Content too long. Please shorten before submitting' })]}
        maxHeight={400}
        fullWidth
      />
      <div className={classes.activeAndActionButtonContainer}>
        <div>
          <Field name="active" margin="normal" component={Switch} label="Currently active" />
        </div>
        <div>
          <Button type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" disabled={submitting} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

MessageTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.any,
};

MessageTemplateForm.defaultProps = {
  error: undefined,
};

export default reduxForm({ form: 'messageTemplateForm' })(MessageTemplateForm);
