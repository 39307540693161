import { Table, TableBody, Grid, TableCell, TableHead, TableRow, Typography, CircularProgress, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAuditLogs } from '../../actions/audit-logs';
import { getAuditActionDescription, getAuditLogs, getAuditLogsLoading } from '../../reducers';
import TimeAgo from '../common/TimeAgo';
import AdvisingAppointments from './AdvisingAppointments';

const studentHistory = (classes, auditLogs, getActionDescription) => {
  if (!auditLogs.length) {
    return (
      <div className={classes.noEntries}>
        <Typography variant="subtitle1">No history exists for student</Typography>
      </div>
    );
  }

  return (
    <>
      <Typography className={classes.historyHeading} variant="subtitle1">
        Student history
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Who/When</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auditLogs.map((auditLog) => (
            <TableRow key={auditLog.id}>
              <TableCell className={classes.metaCol}>
                <ul className="unstyled-list">
                  <li>{auditLog.author.name}</li>
                  <li>
                    <Typography variant="caption">
                      <TimeAgo value={auditLog.created} expandable />
                    </Typography>
                  </li>
                </ul>
              </TableCell>
              <TableCell>{getActionDescription(auditLog.action)}</TableCell>
              <TableCell>
                <ul className="unstyled-list">
                  {auditLog.details.map((detail) => (
                    <li key={detail}>{detail}</li>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

class StudentHistory extends Component {
  componentDidMount() {
    const { studentId, fetchAuditLogsFromServer } = this.props;
    fetchAuditLogsFromServer(studentId);
  }

  render() {
    const { loading, auditLogs, classes, getActionDescription, studentId } = this.props;

    return (
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Paper className={classes.root} elevation={1}>
            <AdvisingAppointments studentId={studentId} />
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper className={classes.root} elevation={1}>
            {loading ? <CircularProgress /> : studentHistory(classes, auditLogs, getActionDescription, studentId)}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

StudentHistory.propTypes = {
  studentId: PropTypes.string.isRequired,
  auditLogs: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  fetchAuditLogsFromServer: PropTypes.func.isRequired,
  getActionDescription: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

StudentHistory.defaultProps = {
  auditLogs: [],
};

const styles = (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  }),
  metaCol: {
    minWidth: '13rem',
  },
  historyHeading: {
    paddingBottom: theme.spacing(1),
  },
  noEntries: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = (state, { studentId }) => ({
  auditLogs: getAuditLogs(state, studentId),
  loading: getAuditLogsLoading(state),
  getActionDescription: (action) => getAuditActionDescription(state, action),
});
const mapDispatchToProps = {
  fetchAuditLogsFromServer: fetchAuditLogs,
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StudentHistory));
