import { prependToArrayField } from '../../util/objects';

export default (state = { loading: false }, action) => {
  switch (action.type) {
    case 'FETCH_COMMENTS_SUCCESS':
      return {
        loading: false,
        [action.params.studentId]: [...action.response],
      };
    case 'FETCH_COMMENTS_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'CREATE_COMMENT_SUCCESS':
      return prependToArrayField(state, action.params.studentId, action.response);
    default:
      return state;
  }
};


export const getAll = (state, studentId) => state[studentId] || [];
export const getAllLoading = (state) => state.loading;
