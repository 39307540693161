import { Button, Card, CardContent, Grid, List, Tooltip, withStyles } from '@material-ui/core';
import { deepPurple, grey, yellow } from '@material-ui/core/colors';
import { Done as DoneIcon, ErrorOutline as ErrorOutlineIcon, Archive as ArchiveIcon } from '@material-ui/icons';
import cx from 'classnames';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import queue from 'async/queue';
import pluralize from 'pluralize';
import { moveAlert, updateAlertTaskStatus } from '../../actions/alerts';
import { getReferenceDataDescription, getStudentDetails } from '../../reducers';
import Task from './Task';
import { classificationColour } from '../common/Classifications';

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasksLoading: {},
    };
    this.queue = queue(this.onCheckTaskAsync);
  }

  onClickArchive = () => {
    const { moveAlertFunction, alert } = this.props;
    moveAlertFunction(alert.studentId, alert.id);
  };

  updateTaskLoadingState = (taskId, loading) => {
    const newTasksLoading = { ...this.state.tasksLoading };
    newTasksLoading[taskId] = loading;
    this.setState({ tasksLoading: newTasksLoading });
  };

  onCheckTask = (taskId, isChecked, onErrorCallback) => {
    const { alert } = this.props;
    this.updateTaskLoadingState(taskId, true);
    this.queue.push({ alertId: alert.id, taskId, isChecked }, (err) => {
      this.updateTaskLoadingState(taskId, false);
      if (err) {
        Alert.error(`Error updating task status: ${err.message}`);
        onErrorCallback();
      }
    });
  };

  onCheckTaskAsync = ({ alertId, taskId, isChecked }, callback) => {
    const { updateTaskOnServer } = this.props;
    updateTaskOnServer(alertId, taskId, isChecked)
      .then(() => callback(null))
      .catch(callback);
  };

  render() {
    const { classes, alert, getAlertType, isOpenView, studentDetails } = this.props;

    const { tasksLoading } = this.state;

    return (
      <Card className={cx(classes.card, classes[`card${capitalize(alert.classification)}`], { [classes.cardDone]: alert.completed })}>
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item md={5} lg={4}>
              <div className={classes.title}>
                <div className={classes.titleIcon}>
                  {alert.completed ? <DoneIcon className={classes.avatar} /> : <ErrorOutlineIcon className={classes.avatar} />}
                </div>
                <div>
                  <div className={classes.titleMain}>{alert.customText || getAlertType(alert.type)}</div>
                  <div className={classes.titleSub}>
                    <span className={classes.subLabel}>Created: </span> {moment(alert.created).format('MMMM Do YYYY - h:mm a')}
                  </div>
                  {alert.completed && (
                    <div>
                      <div className={classes.titleSub}>
                        <span className={classes.subLabel}>Completed: </span> {moment(alert.updated).format('MMMM Do YYYY - h:mm a')}
                      </div>
                      {alert.updatedBy && (
                        <div className={classes.titleSub}>
                          <span className={classes.subLabel}>Completed by: </span> {alert.updatedBy.name}
                        </div>
                      )}
                    </div>
                  )}

                  <div className={classes.titleSub}>
                    {alert.details.courseCode ? (
                      <span className={classes.titleSubBlock}>
                        <span className={classes.subLabel}>Course: </span> {alert.details.courseCode}{' '}
                        {alert.details.courseYear ? ` - ${alert.details.courseYear}` : ''}
                      </span>
                    ) : (
                      ''
                    )}

                    {alert.details.unitCodeOffering ? (
                      <span className={classes.titleSubBlock}>
                        <span className={classes.subLabel}>Unit code offering: </span> {alert.details.unitCodeOffering}
                      </span>
                    ) : (
                      ''
                    )}

                    {alert.details.assignmentName ? (
                      <span className={classes.titleSubBlock}>
                        <span className={classes.subLabel}>Assignment name: </span> {alert.details.assignmentName}
                      </span>
                    ) : (
                      ''
                    )}

                    {alert.details.intermissionStartDate ? (
                      <span className={classes.titleSubBlock}>
                        <span className={classes.subLabel}>Intermit start: </span> {moment(alert.details.intermissionStartDate).format('DD/MM/YY')}
                      </span>
                    ) : (
                      ''
                    )}

                    {alert.details.semester ? (
                      <span className={classes.titleSubBlock}>
                        <span className={classes.subLabel}>Sem: </span> {alert.details.semester}
                      </span>
                    ) : (
                      ''
                    )}

                    {alert.details.previousValueDecimal ? (
                      <span className={classes.titleSubBlock}>
                        <span className={classes.subLabel}>Prev value: </span> {alert.details.previousValueDecimal}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  {!!alert.details.unitCodes.length && (
                    <div className={classes.titleSub}>
                      <span className={classes.titleSubBlock}>
                        <span className={classes.subLabel}>{pluralize('Unit', alert.details.unitCodes.length)}: </span>
                        {alert.details.unitCodes.join(', ')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <List component="nav" className={classes.taskList} dense>
                {alert.tasks.map((task) => (
                  <Task
                    key={task.id}
                    task={task}
                    alertId={alert.id}
                    activeStudent={studentDetails.active}
                    loading={tasksLoading[task.id]}
                    onCheckTask={this.onCheckTask}
                  />
                ))}
              </List>

              {isOpenView && (
                <Tooltip title="Archive completed alert">
                  <Button className={classes.archive} onClick={this.onClickArchive} variant="contained" size="small" color="secondary">
                    Archive
                    <ArchiveIcon className={classes.archiveIcon} />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const styles = (theme) => ({
  card: {
    width: '100%',
    borderRadius: 3,
    position: 'relative',
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  avatar: {
    color: '#333333',
    height: 32,
    width: 32,
  },
  cardResponse: {
    '& $avatar': {
      color: classificationColour('RESPONSE'),
    },
    '& $title': {
      color: classificationColour('RESPONSE'),
      backgroundColor: classificationColour('RESPONSE', 50),
    },
  },
  cardAction: {
    '& $avatar': {
      color: classificationColour('ACTION'),
    },
    '& $title': {
      color: classificationColour('ACTION'),
      backgroundColor: classificationColour('ACTION', 50),
    },
  },
  cardInformation: {
    '& $avatar': {
      color: classificationColour('INFORMATION', true),
    },
    '& $title': {
      color: classificationColour('INFORMATION', true),
      backgroundColor: yellow[50],
    },
  },
  cardCelebration: {
    '& $avatar': {
      color: classificationColour('CELEBRATION'),
    },
    '& $title': {
      color: classificationColour('CELEBRATION'),
      backgroundColor: classificationColour('CELEBRATION', 50),
    },
  },
  cardDone: {
    '& $title': {
      backgroundColor: grey[200],
    },
    '& $titleMain': {
      textDecoration: 'line-through',
    },
    '&$cardAction': {
      '& $avatar': {
        color: deepPurple[300],
      },
      '& $title': {
        color: deepPurple[300],
      },
    },
    '& $archive': {
      display: 'inherit',
    },
  },
  title: {
    padding: '2rem',
    height: '100%',
    display: 'flex',
  },
  titleIcon: {
    marginRight: '1rem',
  },
  titleMain: {
    fontSize: '1.5rem',
    lineHeight: '1.7rem',
    marginBottom: '0.3rem',
  },
  titleSub: {
    color: grey[500],
    fontSize: '0.8 rem',
  },
  titleSubBlock: {
    marginRight: 10,
    display: 'inline-block',
    fontSize: 12,
  },
  subLabel: {
    color: grey[600],
  },
  taskList: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  archive: {
    marginLeft: 40,
    marginBottom: 20,
    display: 'none',
  },
  archiveIcon: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
});

Alert.propTypes = {
  classes: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  getAlertType: PropTypes.func.isRequired,
  moveAlertFunction: PropTypes.func.isRequired,
  updateTaskOnServer: PropTypes.func.isRequired,
  isOpenView: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  studentId: PropTypes.string.isRequired,
  studentDetails: PropTypes.object.isRequired,
};

Alert.defaultProps = {
  isOpenView: false,
};

const mapStateToProps = (state, { studentId }) => ({
  getAlertType: (ref) => getReferenceDataDescription(state, 'AlertType', ref),
  studentDetails: getStudentDetails(state, studentId),
});

const mapDispatchToProps = {
  moveAlertFunction: moveAlert,
  updateTaskOnServer: updateAlertTaskStatus,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(Alert));
