import { Button, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { grey } from '@material-ui/core/colors';
import { selectUnit, unselectAll } from '../../../actions/enrolmentUnits';
import { getReferenceDataDescription, getSelectedEnrolmentUnits } from '../../../reducers/index';
import EnrolmentUnit, { getUnitIdentifier } from './EnrolmentUnit';
import semesterOrder from './semesterOrder';

const enrolmentYear = () => 'year';
const enrolmentSemester = () => (unit) => {
  const index = semesterOrder.indexOf(unit.semester);
  // if we get a semester we don't know about (unlikely) throw them to the bottom
  return index > -1 ? index : 1000;
};

class EnrolmentUnits extends Component {
  static propTypes = {
    studentId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    course: PropTypes.object.isRequired,
    getAdvancedStandingRecognitionType: PropTypes.func.isRequired,
    selectedEnrolmentUnits: PropTypes.object.isRequired,
    selectEnrolmentUnit: PropTypes.func.isRequired,
    unselectAllEnrolmentUnits: PropTypes.func.isRequired,
  };

  onSelectUnit = (unitTitle, selected) => {
    const { selectEnrolmentUnit, studentId, course } = this.props;
    selectEnrolmentUnit(studentId, course.code, unitTitle, selected);
  };

  render() {
    const { classes, course, getAdvancedStandingRecognitionType, unselectAllEnrolmentUnits, selectedEnrolmentUnits, studentId } = this.props;

    // will return empty array if no unitEnrolments defined
    const sortedUnitEnrolments = orderBy(course.unitEnrolments, [enrolmentYear(), enrolmentSemester()], ['desc', 'asc']);

    const advancedStandingLevelsModified = course.advancedStandingLevels.map((unit) => ({
      ...unit,
      advancedStanding: true,
      advancedStandingLevel: true,
      code: 'N/A',
      title: `Advanced standing for level ${unit.level}`,
      enrolmentStatus: 'CREDIT',
    }));
    const advancedStandingUnitsModified = course.advancedStandingUnits.map((unit) => ({
      ...unit,
      advancedStanding: true,
      code: unit.unitCode,
      title: unit.unitTitle,
      enrolmentStatus: getAdvancedStandingRecognitionType(unit.recognitionType),
    }));
    const allUnits = [...sortedUnitEnrolments, ...advancedStandingUnitsModified, ...advancedStandingLevelsModified];

    return allUnits.length === 0 ? (
      <div className={classes.noData}>No enrolled units</div>
    ) : (
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={classes.specialConsiderationCell} />
            <TableCell>Year/Semester</TableCell>
            <TableCell />
            <TableCell>Unit</TableCell>
            <TableCell>
              {!isEmpty(selectedEnrolmentUnits) && (
                <Button variant="contained" onClick={() => unselectAllEnrolmentUnits(studentId, course.code)}>
                  Unselect all
                </Button>
              )}
            </TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Class</TableCell>
            <TableCell>Status</TableCell>
            <TableCell className={classes.creditPointsHeader} align="right">
              CP
            </TableCell>
            <TableCell colSpan={2} className={classes.gradeHeader}>
              Grade
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {allUnits.map((unit) => (
            <EnrolmentUnit
              key={getUnitIdentifier(unit)}
              unit={unit}
              onSelectUnit={this.onSelectUnit}
              selected={selectedEnrolmentUnits[getUnitIdentifier(unit)]}
            />
          ))}
        </TableBody>
      </Table>
    );
  }
}

const styles = (theme) => ({
  specialConsiderationCell: {
    width: 58,
  },
  tableHead: {
    '& th': {
      padding: 0,
    },
    '& th:first-child': {
      paddingLeft: 10,
    },
    '& th:last-child': {
      paddingRight: 24,
    },
  },
  tableHeadRow: {
    height: 45,
  },
  tableBody: {
    '& td': {
      padding: 0,
    },
    '& td:first-child': {
      paddingLeft: 10,
    },
    '& td:last-child': {
      paddingRight: 24,
    },
  },
  creditPointsHeader: {
    width: 50,
  },
  gradeHeader: {
    textAlign: 'right',
  },
  selectionsHelpText: {
    marginLeft: 5,
    color: '#000',
  },
  tableRow: {
    height: 35,
  },
  noData: {
    textAlign: 'center',
    backgroundColor: grey[200],
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

export default compose(
  withStyles(styles),
  connect(
    (state, props) => ({
      getAdvancedStandingRecognitionType: (status) => getReferenceDataDescription(state, 'AdvancedStandingRecognitionType', status),
      selectedEnrolmentUnits: getSelectedEnrolmentUnits(state, props.studentId, props.course.code),
    }),
    {
      selectEnrolmentUnit: selectUnit,
      unselectAllEnrolmentUnits: unselectAll,
    },
  ),
)(EnrolmentUnits);
