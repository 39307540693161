import { Chip, makeStyles, Paper, Typography } from '@material-ui/core';
/* eslint-disable react/no-array-index-key,react/jsx-indent,react/jsx-closing-tag-location */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import ErrorMessages from '../components/forms/ErrorMessages';
import UserProfileForm from '../components/forms/UserProfileForm';
import { getLoggedInUser, getReferenceDataDescription, getReferenceDataGenders } from '../reducers';
import api from '../services/api';
import { push } from 'connected-react-router';
import cx from 'classnames';
import JuvoAvatar from '../components/common/JuvoAvatar';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    minHeight: theme.spacing(10),
  },
  subHeader: {
    fontSize: '0.875rem',
    fontWeight: 300,
    paddingLeft: 3,
    color: grey[700],
  },
  muted: {
    fontWeight: 200,
  },
  formContainer: {
    maxWidth: 600,
  },
  userHeader: {
    position: 'inherit',
    display: 'flex',
    marginBottom: theme.spacing(),
  },
  value: {
    fontWeight: 'normal',
  },
  roles: {
    marginTop: theme.spacing(0.5),
    '& :nth-child(n+2)': {
      marginLeft: theme.spacing(),
    },
  },
  avatarWrapper: {},
  userDetailsWrapper: {
    marginLeft: 15,
    marginTop: 3,
    flexGrow: 1,
  },
  title: {
    fontSize: '2em',
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: theme.spacing(0.5),
  },
}));

const refDataToValueLabel = (orig) =>
  orig.map((input) => ({
    value: input.id,
    label: input.description,
  }));

const MyProfilePage = () => {
  const user = useSelector((state) => getLoggedInUser(state));
  const getRole = useSelector((state) => (role) => getReferenceDataDescription(state, 'Role', role));
  const genders = useSelector((state) => refDataToValueLabel(getReferenceDataGenders(state)));
  const [uploadedImage, setUploadedImage] = useState(user && user.image);
  const classes = useStyles();
  const dispatch = useDispatch();

  const navigateHome = () => dispatch(push('/'));

  const handleSubmit = (values) => {
    const payload = { ...values };
    if (payload && payload.image) {
      payload.image.public = true;
    }
    return api.users
      .saveMe(values)
      .then(() => {
        Alert.success('User updated');
        return navigateHome();
      })
      .catch((error) => {
        console.error('error ', error);
        Alert.error(<ErrorMessages error={error} />);
        throw new SubmissionError({ _error: error });
      });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.userHeader}>
        <div className={cx(classes.avatarWrapper)}>
          <JuvoAvatar image={uploadedImage} />
        </div>
        <div className={classes.userDetailsWrapper}>
          <Typography variant="h6" className={classes.title}>
            {user.name}
          </Typography>
          <div className={classes.subHeader}>
            Email: <span className={classes.value}>{user.email}</span>
          </div>
          <div className={classes.subHeader}>
            Allocated students: <span className={classes.value}>{user.advisingStudentCount}</span>
          </div>
          <div className={cx(classes.subHeader, classes.roles)}>
            {user.roles.map((role) => (
              <Chip key={role} size="small" label={getRole(role)} />
            ))}
          </div>
        </div>
      </div>
      <div className={classes.formContainer}>
        <UserProfileForm
          initialValues={user}
          genders={genders}
          advisingStudentCount={user.advisingStudentCount}
          adviserGreetingName={user.greetingName}
          onCancel={navigateHome}
          onSubmit={handleSubmit}
          onImageChange={setUploadedImage}
          concise
        />
      </div>
    </Paper>
  );
};

export default MyProfilePage;
