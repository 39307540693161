import { Card, CardContent, CardHeader, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import CalendlyAppointmentWidget from '../../components/students/CalendlyAppointmentWidget';

const AdviserCalendlyWidget = (props) => {
  const { classes, adviser } = props;
  return (
    <Card className={classes.container}>
      <CardHeader title="My appointments" />
      <CardContent className={classes.content}>
        <CalendlyAppointmentWidget url={adviser.calendlyUrl} />
      </CardContent>
    </Card>
  );
};

AdviserCalendlyWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  adviser: PropTypes.object.isRequired,
};

const styles = () => ({
  content: {
    padding: 0,
  },
  container: {
    height: 850,
  },
});

export default withStyles(styles)(AdviserCalendlyWidget);
