import { combineReducers } from 'redux';

const allLockedJobs = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_LOCKED_JOBS_SUCCESS':
      return action.response.lockedJobs;
    default:
      return state;
  }
};

export const getAllLockedJobs = (state) => state.allLockedJobs;

export default combineReducers({
  allLockedJobs,
});
