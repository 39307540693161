import { requestJSON } from './http';

const list = () => requestJSON('/advisers');

const alertSummaries = () => requestJSON('/advisers/alert-counts');

const reallocateStudents = (adviserId, studentIds) => requestJSON('/advisers/reallocate-students', 'PUT', { adviserId, studentIds });

const archiveStudents = (studentIds) => requestJSON('/advisers/archive-students', 'PUT', { studentIds });

const startLeave = (adviserId) => requestJSON(`/advisers/${adviserId}/start-leave`, 'PUT', {});

const endLeave = (adviserId) => requestJSON(`/advisers/${adviserId}/end-leave`, 'PUT', {});

const alertSummary = (adviserId) => requestJSON(`/advisers/${adviserId}/alert-summary`);

const listGroupedActivityLogs = (adviserId) => requestJSON(`/advisers/${adviserId}/grouped-activity-logs`);

const listAlertFrequency = (adviserId) => requestJSON(`/advisers/${adviserId}/alert-frequency`);

export default {
  list,
  reallocateStudents,
  archiveStudents,
  startLeave,
  endLeave,
  alertSummary,
  alertSummaries,
  listGroupedActivityLogs,
  listAlertFrequency,
};
