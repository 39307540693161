import { formEncode, getJSON, postJSON, putJSON, request } from './http';

const invite = (userDetails) => postJSON('/users/invite', userDetails);
const redeemInvite = (inviteCode, userDetails) => postJSON(`/users/invite/${inviteCode}`, userDetails);
const list = () => getJSON('/users');

const login = (credentials) =>
  request('/login', 'POST', formEncode({ ...credentials, 'remember-me': true }), { 'Content-Type': 'application/x-www-form-urlencoded' });

const logout = () => request('/users/logout?local=true', 'POST');
const me = () => getJSON('/users/me');
const get = (userId) => getJSON(`/users/${userId}`);
const save = (user) => putJSON(`/users/${user.id}`, user);
const saveMe = (user) => putJSON('/users/me', user);

export default {
  get,
  invite,
  list,
  login,
  logout,
  me,
  redeemInvite,
  save,
  saveMe,
};
