import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { classificationColour } from '../common/Classifications';
import FlagRow from './FlagRow';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    backgroundColor: '#fff',
  },
  flag: {
    borderLeft: '4px solid',
  },
  noData: {
    textAlign: 'center',
    color: grey[700],
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  flagACTION: {
    borderLeftColor: classificationColour('ACTION'),
  },
  flagRESPONSE: {
    borderLeftColor: classificationColour('RESPONSE'),
  },
  flagINFORMATION: {
    borderLeftColor: classificationColour('INFORMATION'),
  },
  flagCELEBRATION: {
    borderLeftColor: classificationColour('CELEBRATION'),
  },
}));

const FlagList = ({ flags, onUpdate }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Text</TableCell>
          <TableCell>Classification</TableCell>
          <TableCell>Students</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {flags.length ? (
          flags.map((flag) => <FlagRow key={flag.text} flag={flag} onUpdate={onUpdate} />)
        ) : (
          <TableRow>
            <TableCell className={classes.noData} colSpan={4}>
              No student flags exist
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

FlagList.propTypes = {
  flags: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default FlagList;
