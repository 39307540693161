import { IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Progress from '../../components/common/Progress';
import moodleIngestionApi from '../../services/api/moodle-ingestions';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import MoodleIngestions from '../../components/moodle-logs/MoodleIngestions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  header: {
    position: 'relative',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(13),
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
  link: {
    padding: theme.spacing(0, 1),
  },
  subActions: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: 0,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 2),
    margin: 0,
  },
}));

const MoodleIngestionsPage = () => {
  const classes = useStyles();
  const [auditLogs, setAuditLogs] = useState(undefined);

  const load = () => {
    setAuditLogs(undefined);
    return moodleIngestionApi.list().then((res) => setAuditLogs(res));
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">Moodle file ingestions</Typography>
        <div className={classes.subActions}>
          <Tooltip title="Refresh" aria-label="Refresh results">
            <div>
              <IconButton onClick={load} disabled={!auditLogs}>
                <RefreshIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
      {auditLogs ? <MoodleIngestions logs={auditLogs} onUpdate={load} /> : <Progress />}
    </Paper>
  );
};

export default MoodleIngestionsPage;
