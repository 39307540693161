import React from 'react';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import JuvoTooltip from '../common/JuvoTooltip';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getLoggedInUserId } from '../../reducers';

const useStyles = makeStyles(() => ({
  adviserIcon: {
    marginBottom: -6,
    marginRight: '0.2rem',
    marginLeft: 15,
    height: 24,
    width: 24,
  },
  adviserNameLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  muted: {
    color: grey[500],
  },
}));

const Adviser = ({ adviser, icon: Icon, muted }) => {
  const classes = useStyles();
  const loggedInUserId = useSelector((state) => getLoggedInUserId(state));

  return (
    !!adviser && (
      <JuvoTooltip title="View student list" placement="bottom">
        <span className={cx({ [classes.muted]: muted })}>
          <Link to={loggedInUserId === adviser.id ? '/adviser/students' : `/admin/advisers/${adviser.id}`} className={classes.adviserNameLink}>
            <Icon className={classes.adviserIcon} />
            <span>{adviser.name}</span>
          </Link>
        </span>
      </JuvoTooltip>
    )
  );
};

Adviser.propTypes = {
  adviser: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  muted: PropTypes.bool,
};

Adviser.defaultProps = {
  adviser: undefined,
  muted: false,
};

export default Adviser;
