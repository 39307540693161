import { normalize } from 'normalizr';
import schemas from '../schemas';
import api from '../services/api';
import { asyncAction } from './actions';

export const fetchAllAnnouncements = () => asyncAction(
  'ANNOUNCEMENTS_FETCH',
  api.announcements.list(),
  {
    responseTransformer: (announcements) => normalize(announcements, schemas.arrayOfAnnouncements),
  },
);

export const createAnnouncement = (announcement) => asyncAction(
  'ANNOUNCEMENT_CREATE',
  api.announcements.create(announcement),
  {
    responseTransformer: (a) => normalize(a, schemas.announcement),
  },
);

export const deleteAnnouncement = (id) => asyncAction(
  'ANNOUNCEMENT_DELETE',
  api.announcements.deleteAnnouncement(id),
  {
    params: { id },
  },
);
