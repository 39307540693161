import { TableCell, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import { blue, grey, purple, red } from '@material-ui/core/colors';
import { Info as InfoIcon } from '@material-ui/icons';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getReferenceDataDescription } from '../../../reducers/index';
import AdvancedStandingCommentsDialog from './AdvancedStandingCommentsDialog';
import LevelOfEngagement from './LevelOfEngagement';
import SpecialConsiderationsDialog from './SpecialConsiderationsDialog';

// Advanced standing "units" do not have an ID so we use title instead
export const getUnitIdentifier = (unit) => (unit.advancedStanding ? unit.title : unit.id);

class EnrolmentUnit extends Component {
  static propTypes = {
    getEnrolmentStatus: PropTypes.func.isRequired,
    onSelectUnit: PropTypes.func.isRequired,
    getLocationModeName: PropTypes.func.isRequired,
    unit: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    selected: PropTypes.bool,
  };

  static defaultProps = {
    selected: false,
  };

  state = {
    showSpecialConsiderations: false,
    showAdvancedStandingLevelComments: false,
  };

  showSpecialConsiderations = (e) => {
    e.stopPropagation();
    this.setState({
      showSpecialConsiderations: true,
    });
  };

  hideSpecialConsiderations = () => {
    this.setState({
      showSpecialConsiderations: false,
    });
  };

  showAdvancedStandingLevelComments = (e) => {
    e.stopPropagation();
    this.setState({
      showAdvancedStandingLevelComments: true,
    });
  };

  hideAdvancedStandingLevelComments = () => {
    this.setState({
      showAdvancedStandingLevelComments: false,
    });
  };

  render() {
    const { unit, classes, getEnrolmentStatus, getLocationModeName, onSelectUnit, selected } = this.props;
    const { showAdvancedStandingLevelComments, showSpecialConsiderations } = this.state;

    return (
      <>
        <TableRow
          onClick={() => onSelectUnit(getUnitIdentifier(unit), !selected)}
          hover
          key={unit.title + unit.enrolmentStatus}
          className={cx(classes.tableRow, {
            [classes.tableRowCompleted]: unit.mark >= 50,
            [classes.tableRowFailed]: _.includes(['N', 'NGO', 'WN'], unit.grade),
            [classes.tableRowWithheld]: _.includes(['WH', 'NS'], unit.grade),
            [classes.tableRowEnrolled]: unit.enrolmentStatus === 'ENROLLED',
            [classes.tableRowAdvancedStanding]: unit.advancedStanding,
            [classes.tableRowSelected]: selected,
          })}
        >
          <TableCell>
            {unit.specialConsiderations && unit.specialConsiderations.length > 0 && (
              <Tooltip id="tooltip-spec-con" title="Spec con" placement="top-start">
                <InfoIcon className={cx(classes.specialConsiderationsButton, 'clickable')} onClick={this.showSpecialConsiderations} color="primary" />
              </Tooltip>
            )}
            {unit.advancedStandingLevel && unit.comments && (
              <Tooltip id="tooltip-advanced-standing" title="Additional comments" placement="top-start">
                <InfoIcon className={cx(classes.advancedStandingLevelCommentsIcon, 'clickable')} onClick={this.showAdvancedStandingLevelComments} />
              </Tooltip>
            )}
          </TableCell>
          <TableCell>
            {unit.advancedStanding && <span className={classes.advancedStanding}>Advanced standing</span>}
            {!unit.advancedStanding && (
              <>
                {unit.year} <span className={classes.semester}>{unit.semester}</span>
              </>
            )}
          </TableCell>
          <TableCell>
            <LevelOfEngagement level={unit.levelOfEngagement} />
          </TableCell>
          <TableCell className={classes.codeTableCell}>
            <div className={cx(classes.darkerText, classes.code)}>
              <a
                onClick={(e) => e.stopPropagation()}
                href={`http://www.monash.edu/pubs/${unit.year ? unit.year : ''}handbooks/units/${unit.code.split('-')[0]}.html`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {unit.code.split('-')[0]}
              </a>
            </div>
          </TableCell>
          <TableCell>
            <span className={classes.title}>
              <ul className={cx(classes.additionalUnitDetailsList, 'unstyled-list')}>
                <li>{unit.title}</li>
                {unit.advancedStandingLevel && unit.discipline && <li>Discipline: {unit.discipline}</li>}
              </ul>
            </span>
          </TableCell>
          <TableCell>{unit.location && <span>{unit.location}</span>}</TableCell>
          <TableCell>{unit.locationMode && <span>{getLocationModeName(unit.locationMode)}</span>}</TableCell>
          <TableCell>
            <span className={classes.capitalize}>{getEnrolmentStatus(unit.enrolmentStatus)}</span>
          </TableCell>
          <TableCell align="right">
            <span className={classes.darkerText}>{unit.creditPoints}</span>
          </TableCell>
          <TableCell className={classes.gradeCell}>
            {unit.grade && (
              <>
                <span className={classes.darkerText}>{unit.grade}</span>
              </>
            )}
          </TableCell>
          <TableCell className={classes.markCell}>
            {_.isNumber(unit.mark) && (
              <Typography className={classes.inlineTypography} component="span" variant="caption">
                ({unit.mark})
              </Typography>
            )}
          </TableCell>
        </TableRow>

        {!!unit.specialConsiderations && (
          <SpecialConsiderationsDialog unitEnrolment={unit} open={showSpecialConsiderations} onClose={this.hideSpecialConsiderations} />
        )}

        <AdvancedStandingCommentsDialog
          unitEnrolment={unit}
          open={showAdvancedStandingLevelComments}
          onClose={this.hideAdvancedStandingLevelComments}
        />
      </>
    );
  }
}

const styles = () => ({
  inlineTypography: {
    display: 'inline',
  },
  specialConsiderationsButton: {
    position: 'relative',
    top: 3,
    left: 5,
    height: 18,
    width: 18,
  },
  advancedStandingLevelCommentsIcon: {
    position: 'relative',
    top: 3,
    left: 5,
    color: red[300],
    height: 18,
    width: 18,
  },
  additionalUnitDetailsList: {
    display: 'inline-block',
  },
  tableRow: {
    height: 25,
    '& td': {
      position: 'relative',
    },
  },
  semester: {
    marginLeft: 5,
    color: grey[500],
  },
  advancedStanding: {
    color: grey[500],
  },
  codeTableCell: {
    width: 85,
  },
  code: {
    width: 85,
    display: 'inline-block',
    '& a': {
      color: 'inherit',
    },
  },
  title: {
    color: grey[500],
  },
  lighterText: {
    color: grey[500],
  },
  darkerText: {
    fontWeight: 700,
  },
  gradeCell: {
    paddingLeft: 10,
    textAlign: 'right',
    width: 40,
  },
  markCell: {
    textAlign: 'right',
    width: 60,
  },
  capitalize: {
    textTransform: 'uppercase',
  },
  tableRowCompleted: {},
  tableRowFailed: {
    background: red[50],
  },
  tableRowWithheld: {
    background: purple[50],
  },
  tableRowEnrolled: {
    background: blue[50],
  },
  tableRowAdvancedStanding: {},
  tableRowSelected: {
    '& td::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      borderBottom: '1px solid #333',
      width: '100%',
    },
  },
});

export default compose(
  withStyles(styles),
  connect((state) => ({
    getEnrolmentStatus: (status) => getReferenceDataDescription(state, 'UnitEnrolmentStatus', status),
    getLocationModeName: (locationMode) => getReferenceDataDescription(state, 'LocationMode', locationMode),
  })),
)(EnrolmentUnit);
