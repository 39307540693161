import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const byId = (state = {}, action) => {
  if (action.response && action.response.entities) {
    return {
      ...state,
      ...action.response.entities.users,
    };
  }

  // if someone has retrieved "me" then put it in here so we can get
  // it from the store via id
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        [action.user.id]: action.user,
      };
    case 'ADVISERS_START_LEAVE_SUCCESS':
    case 'ADVISERS_END_LEAVE_SUCCESS':
      return {
        ...state,
        [action.response.id]: action.response,
      };
    default:
      return state;
  }
};

const listIds = (state = { users: [], loading: true }, action) => {
  switch (action.type) {
    case 'INVITE_USER_SUCCESS':
    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        users: [...state.users, action.response.result],
      };
    case 'FETCH_USERS_SUCCESS':
      return {
        loading: false,
        users: [...action.response.result],
      };
    case 'FETCH_USERS_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export const getAllIsLoading = (state) => state.listIds.loading;

export const getAll = createSelector(
  (state) => state.listIds.users,
  (state) => state.byId,
  (users, usersById) => users.map((id) => usersById[id]),
);
export const getById = (state, id) => state.byId[id];

export default combineReducers({
  byId,
  listIds,
});
