import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Add as NewQuickLinkIcon, Edit as EditIcon, DeleteForever as DeleteIcon } from '@material-ui/icons';
import cx from 'classnames';
import Alert from 'react-s-alert';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import FormDialog from '../components/forms/FormDialog';
import QuickLinkForm from '../components/forms/QuickLinkForm';
import api from '../services/api';

const useStyles = makeStyles((theme) => ({
  header: {
    minHeight: theme.spacing(10),
  },
  headerAndCreateButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  noQuickLinksMessage: {
    textAlign: 'center',
  },
  recentlyCreatedRow: {
    backgroundColor: green[50],
  },
  recentlyCreatedName: {
    borderLeft: `4px solid ${green[500]}`,
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
  actionButtonsCell: {
    textAlign: 'end',
  },
}));

const QuickLinksPage = () => {
  const classes = useStyles();
  const [quickLinks, updateQuickLinks] = useState([]);
  const [dialogOpen, updateDialogOpen] = useState(false);
  const [deleteDialogOpen, updateDeleteDialogOpen] = useState(false);
  const [submitting, updateSubmitting] = useState(false);
  const [quickLinkPayload, updateQuickLinkPayload] = useState({});

  useEffect(() => {
    updateSubmitting(true);
    api.quickLinks
      .list()
      .then((response) => {
        updateQuickLinks(response);
      })
      .finally(() => updateSubmitting(false));
  }, []);

  const deriveSaveFunction = (values) => (values.id ? api.quickLinks.update : api.quickLinks.create);

  const deriveMode = () => (quickLinkPayload.id ? 'Update' : 'Create');

  const createNewQuickLink = (values) => {
    updateSubmitting(true);
    const saveFunction = deriveSaveFunction(values);
    saveFunction(values)
      .then((response) => {
        const updatedLink = { ...response, recentlyCreated: true };
        const newVar = [updatedLink, ...quickLinks.filter((link) => link.id !== updatedLink.id)];
        updateQuickLinks(newVar);
        updateDialogOpen(false);
        Alert.success(`${deriveMode()}d quick link successfully`);
      })
      .catch((error) => Alert.error(error.message))
      .finally(() => updateSubmitting(false));
  };

  const getListContent = () => {
    if (submitting) {
      return (
        <Typography className={classes.noQuickLinksMessage} variant="body2">
          Loading quick links...
        </Typography>
      );
    }

    if (quickLinks && quickLinks.length > 0) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Table className={classes.tableContainer}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {quickLinks.map((quickLink) => (
                  <TableRow className={cx({ [classes.recentlyCreatedRow]: quickLink.recentlyCreated })} key={quickLink.id}>
                    <TableCell className={cx({ [classes.recentlyCreatedName]: quickLink.recentlyCreated })}>{quickLink.name}</TableCell>
                    <TableCell>
                      <Link href={quickLink.url} target="_blank">
                        {quickLink.url}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.actionButtonsCell}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          updateQuickLinkPayload(quickLink);
                          updateDialogOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          updateQuickLinkPayload(quickLink);
                          updateDeleteDialogOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      );
    }

    return (
      <Typography className={classes.noQuickLinksMessage} variant="body2">
        There are currently no quick links configured
      </Typography>
    );
  };

  const deleteQuickLink = () =>
    api.quickLinks
      .deleteLink(quickLinkPayload.id)

      .then(() => {
        updateQuickLinks([...quickLinks.filter((ql) => ql.id !== quickLinkPayload.id)]);
        updateDeleteDialogOpen(false);
        Alert.success(`Quick link '${quickLinkPayload.name}' deleted successfully`);
        updateQuickLinkPayload({});
      })
      .catch(() => Alert.error('Quick link delete failed'));

  return (
    <>
      <FormDialog
        title={`${deriveMode()} quick link`}
        submitButtonText={deriveMode()}
        formComponent={QuickLinkForm}
        formName="quickLinkForm"
        open={dialogOpen}
        onSubmit={createNewQuickLink}
        onCancel={() => updateDialogOpen(false)}
        submitting={submitting}
        initialValues={quickLinkPayload}
      />

      <ConfirmationDialog
        title="Delete quick link?"
        content={`Confirmation of this will delete the quick link: ${quickLinkPayload.name}`}
        submitting={submitting}
        open={deleteDialogOpen}
        onCancel={() => updateDeleteDialogOpen(false)}
        onConfirm={() => {
          deleteQuickLink();
        }}
      />

      <div className={classes.header}>
        <div className={classes.headerAndCreateButtonContainer}>
          <Typography variant="h5">Manage quick links</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateQuickLinkPayload({});
              updateDialogOpen(true);
            }}
            disabled={submitting}
          >
            <NewQuickLinkIcon className={classes.iconLeft} />
            Create
          </Button>
        </div>
      </div>

      {getListContent()}
    </>
  );
};

export default QuickLinksPage;
