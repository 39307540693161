import React from 'react';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import Count from '../Count';

const FlagStudentCount = ({ count }) => <Count count={count} color={count ? grey[900] : grey[400]} />;

FlagStudentCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default FlagStudentCount;
