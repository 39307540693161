import { blue, deepPurple, green, grey, orange } from '@material-ui/core/colors';

const classificationColours = {
  RESPONSE: blue,
  ACTION: deepPurple,
  INFORMATION: orange,
  CELEBRATION: green,
};

export const classificationColour = (classification, altOrCustom) => {
  const colour = classificationColours[classification] || grey;
  if (typeof altOrCustom === 'number') {
    return colour[altOrCustom];
  }

  return colour[altOrCustom ? 600 : 500];
};
