import { normalize } from 'normalizr';
import { isAlertCompleted } from '../reducers';
import schemas from '../schemas';
import api from '../services/api';
import { asyncAction } from './actions';

export const fetchOpenStudentAlerts = (studentId) =>
  asyncAction('FETCH_STUDENT_ALERTS_OPEN', api.alerts.listForStudent(studentId), {
    responseTransformer: (alerts) => normalize(alerts, schemas.arrayOfAlerts),
    params: { studentId },
  });

export const fetchCompletedStudentAlerts = (studentId) =>
  asyncAction('FETCH_STUDENT_ALERTS_COMPLETED', api.alerts.listCompletedForStudent(studentId), {
    responseTransformer: (alerts) => normalize(alerts, schemas.arrayOfAlerts),
    params: { studentId },
  });

export const updateAlertTaskStatus = (alertId, taskId, completedStatus) =>
  asyncAction('UPDATE_ALERT_TASK_STATUS', api.alerts.updateAlertTaskStatus(alertId, taskId, completedStatus), {
    params: { alertId },
  });

export const moveAlert = (studentId, alertId) => (dispatch, getState) => {
  const state = getState();
  const alertCompleted = isAlertCompleted(state, alertId);
  return dispatch({
    type: 'MOVE_ALERT',
    studentId,
    alertId,
    alertCompleted,
  });
};

export const moveAlertToCompleted = (studentId, alertId) => (dispatch) =>
  dispatch({
    type: 'MOVE_ALERT',
    studentId,
    alertId,
    alertCompleted: true,
  });
