import { func } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createMessageTemplate } from '../../actions/message-templates';
import SaveMessageTemplate from '../../components/message-templates/SaveMessageTemplate';

const CreateMessageTemplatePage = ({ create }) => (
  <SaveMessageTemplate
    title="Create message template"
    successMessage="Message template created"
    onSubmit={create}
    // eslint-disable-next-line no-template-curly-in-string
    initialValues={{ content: 'Dear ${student.greetingName},<br><br><br><br>Regards,<br><br>${currentUser.greetingName}', active: true }}
  />
);

CreateMessageTemplatePage.propTypes = {
  create: func.isRequired,
};

const mapDispatchToProps = {
  create: createMessageTemplate,
};

export default connect(null, mapDispatchToProps)(CreateMessageTemplatePage);
