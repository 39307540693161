import { Paper, TextField, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import Alert from 'react-s-alert';
import { object } from 'prop-types/prop-types';
import debounce from 'lodash/debounce';
import ErrorMessages from '../../components/forms/ErrorMessages';
import LoadingIndicator from '../../components/LoadingIndicator';
import ArchivedStudentsTable from '../../components/students/ArchivedStudents/ArchivedStudentsTable';
import api from '../../services/api';

class ArchivedStudentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivedStudents: [],
      loading: false,
      searchText: '',
    };
  }

  componentDidMount() {
    this.searchForArchivedStudents('');
  }

  searchForArchivedStudents = debounce((searchCriteria) => {
    this.setState({ loading: true });
    api.students
      .listArchived(searchCriteria)
      .then((archivedStudents) =>
        this.setState({
          archivedStudents,
        }),
      )
      .catch((error) => Alert.error(<ErrorMessages error={error} />))
      .finally(() => this.setState({ loading: false }));
  }, 300);

  searchTextChanged = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText });
    this.searchForArchivedStudents(searchText);
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        {this.state.loading && <LoadingIndicator text="Loading archived students..." />}
        <div className={classes.inputContainer}>
          <TextField
            name="archivedStudentSearchCriteria"
            margin="normal"
            placeholder="Search student id, name, email, course code"
            label="Search archived students"
            fullWidth
            value={this.state.searchText}
            onChange={this.searchTextChanged}
          />
        </div>
        <ArchivedStudentsTable students={this.state.archivedStudents} />
      </Paper>
    );
  }
}

ArchivedStudentsPage.propTypes = {
  classes: object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  inputContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(),
  },
});

export default withStyles(styles)(ArchivedStudentsPage);
