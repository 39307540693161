/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';


const ErrorMessages = ({ error }) => (
  <div>{error.messages.map((err, index) => <p key={index}>{err}</p>)}</div>
);

ErrorMessages.propTypes = {
  error: PropTypes.object.isRequired,
};

export default ErrorMessages;
