import { Grid, makeStyles, Typography } from '@material-ui/core';
import { CloudUpload as UploadIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import { uploadCustomAlertsCsv } from '../../../actions/users';
import Upload from '../../common/Upload';
import ErrorMessages from '../../forms/ErrorMessages';
import { useDispatch } from 'react-redux';
import CustomAlertCsvHelp from './CustomAlertCsvHelp';

const useStyles = makeStyles((theme) => ({
  fileIcon: {
    marginRight: theme.spacing(),
  },
  downloadLink: {
    marginLeft: theme.spacing(2),
  },
  header: {
    minHeight: theme.spacing(10),
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
}));

const CustomAlerts = () => {
  const [uploading, setUploading] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const uploadCustomAlerts = (file) => dispatch(uploadCustomAlertsCsv(file));

  const uploadData = (file) => {
    setUploading(true);
    uploadCustomAlerts(file)
      .then(() => Alert.success('Custom alerts created'))
      .catch((error) => {
        Alert.error(<ErrorMessages error={error} />);
        throw new SubmissionError({ _error: error });
      })
      .finally(() => setUploading(false));
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5">Manage custom alerts</Typography>
      </div>
      <Grid container justify="flex-end">
        <Grid item>
          <CustomAlertCsvHelp className={classes.iconLeft} />
          <Upload
            handleUpload={([file]) => uploadData(file)}
            accept="text/csv,.csv"
            label="CSV Upload"
            variant="outlined"
            uploading={uploading}
            icon={<UploadIcon className={classes.fileIcon} />}
            iconPlacement="left"
            padded
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomAlerts;
