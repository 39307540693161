/* eslint-disable react/no-danger */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Delete as DeleteIcon } from '@material-ui/icons';
import cx from 'classnames';
import moment from 'moment';
import { bool, func, object } from 'prop-types';
import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { compose } from 'recompose';
import { deleteAnnouncement } from '../../actions/announcements';
import Authorised from '../common/Authorised';
import ConfirmationDialog from '../common/ConfirmationDialog';
import TimeAgo from '../common/TimeAgo';

class AnnouncementRow extends Component {
  static propTypes = {
    announcement: object.isRequired,
    classes: object.isRequired,
    deleteItem: func,
    concise: bool,
  };

  static defaultProps = {
    deleteItem: undefined,
    concise: false,
  };

  state = {
    confirmationDialogOpen: false,
    fullDialogOpen: false,
  };

  openConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: true });
  };

  closeConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: false });
  };

  openFullDialog = () => {
    const { concise } = this.props;
    if (concise) this.setState({ fullDialogOpen: true });
  };

  closeFullDialog = () => {
    this.setState({ fullDialogOpen: false });
  };

  onDelete = () => {
    const { announcement } = this.props;
    this.props.deleteItem(announcement.id).then(() => {
      Alert.success('Announcement deleted');
      this.closeConfirmationDialog();
    });
  };

  render() {
    const { announcement, classes, concise } = this.props;
    const { confirmationDialogOpen, fullDialogOpen } = this.state;

    const isNew = moment(announcement.created).isAfter(moment().subtract({ hour: 24 }));

    return (
      <>
        <TableRow
          key={announcement.id}
          className={cx({ clickable: concise }, { [classes.newAnnouncement]: isNew }, classes.contentRow)}
          role="presentation"
          onClick={() => this.openFullDialog()}
        >
          <TableCell className={concise ? classes.metaColConcise : classes.metaCol}>
            <ul className="unstyled-list">
              <li>
                <Typography variant={isNew ? 'subtitle1' : 'caption'} className={cx({ [classes.newAnnouncementCreated]: isNew })}>
                  <TimeAgo value={announcement.created} expandable={!concise} />
                </Typography>
              </li>
              <li>{announcement.author.name}</li>
            </ul>
          </TableCell>
          <TableCell>
            <div role="presentation">
              <Dotdotdot clamp={concise ? 3 : 0}>
                <div className={cx({ [classes.announcementContent]: concise })} dangerouslySetInnerHTML={{ __html: announcement.content }} />
              </Dotdotdot>
            </div>
          </TableCell>

          {!concise && (
            <Authorised roles="ADMIN">
              <TableCell>
                <IconButton variant="text" onClick={() => this.openConfirmationDialog()}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </Authorised>
          )}
        </TableRow>

        <Dialog onClose={this.closeFullDialog} aria-labelledby="announcement-dialog" open={fullDialogOpen} maxWidth="md">
          <DialogTitle id="announcement-dialog-title">
            Announcement by {announcement.author.name} - <TimeAgo value={announcement.created} expandable />
          </DialogTitle>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeFullDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmationDialog
          content="Are you sure you would like to delete the announcement?"
          open={confirmationDialogOpen}
          onCancel={this.closeConfirmationDialog}
          onConfirm={this.onDelete}
        />
      </>
    );
  }
}

const styles = () => ({
  metaCol: {
    minWidth: '12rem',
  },
  metaColConcise: {
    minWidth: '10rem',
  },
  contentRow: {
    verticalAlign: 'top',
    '& td': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  newAnnouncement: {
    backgroundColor: green[50],
    borderLeft: `5px solid ${green[500]};`,
  },
  newAnnouncementCreated: {
    fontWeight: 'bold',
  },
  announcementContent: {
    paddingTop: 5,
  },
});

export default compose(
  connect(null, {
    deleteItem: deleteAnnouncement,
  }),
  withStyles(styles),
)(AnnouncementRow);
