import PropTypes from 'prop-types';
import React from 'react';
import CourseList from './Course/CourseList';
import AlertList from '../alerts/AlertList';

const StudentOverview = ({ alerts, student }) => (
  <div>
    <AlertList alerts={alerts} studentId={student.id}/>
    <CourseList courses={student.courseEnrolments} studentId={student.id}/>
  </div>
);

StudentOverview.propTypes = {
  alerts: PropTypes.array,
  student: PropTypes.object.isRequired,
};

StudentOverview.defaultProps = {
  alerts: [],
};

export default StudentOverview;
