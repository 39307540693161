/* eslint-disable react/no-danger */
import { Avatar, Typography, withStyles } from '@material-ui/core';
import { CheckBox as AdvisingAppointmentIcon } from '@material-ui/icons';
import { blue, grey, lightGreen, purple } from '@material-ui/core/colors';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import highlightTextInHtml from '../../util/highlightTextInHtml';
import { deriveAvatar } from '../common/JuvoAvatar';
import JuvoTooltip from '../common/JuvoTooltip';
import TimeAgo from '../common/TimeAgo';
import AttachmentList from './AttachmentList';

class Comment extends React.Component {
  state = {
    highlightedContent: '',
  };

  constructor(props) {
    super(props);
    this.el = React.createRef();
  }

  componentDidMount() {
    this.clonedEl = this.el.current.cloneNode(true);
    this.highlight();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.highlight.length !== this.props.highlight.length) {
      this.highlight();
    }
  }

  highlight() {
    const { highlight } = this.props;

    if (highlight.length > 1) {
      const highlightedEl = highlightTextInHtml.highlight(this.clonedEl, highlight);
      this.setState({ highlightedContent: highlightedEl.innerHTML });
    } else {
      this.setState({ highlightedContent: null });
    }
  }

  render() {
    const { classes, comment, children, viewingAsStudent } = this.props;
    const { highlightedContent } = this.state;
    const primaryComment = viewingAsStudent ? comment.isStudent : !comment.isStudent;
    const renderAdditionalRecipient = (c) => !viewingAsStudent && !c.isStudent && c.additionalRecipient;
    return (
      <div key={comment.id} className={classes.commentWrapper}>
        <div
          className={cx(classes.comment, {
            [classes.commentPrimary]: primaryComment,
            [classes.commentSecondary]: !primaryComment,
            [classes.message]: !comment.internal,
            [classes.commentReferral]: comment.referral,
            [classes.commentNote]: !comment.referral && comment.internal,
          })}
        >
          {!comment.internal && (
            <div>
              <Avatar className={classes.avatar} src={deriveAvatar(comment.author.image)} alt="Student avatar" />
            </div>
          )}

          <div>
            <JuvoTooltip title={comment.referral ? 'This note is a Juvo referral' : ''}>
              <div className={classes.commentContent}>
                <div ref={this.el} className={classes.commentText} dangerouslySetInnerHTML={{ __html: highlightedContent || comment.content }} />
                <AttachmentList className={classes.attachments} attachments={comment.attachments} concise />
              </div>
            </JuvoTooltip>
            <div className={classes.commentInfo}>
              <span className={classes.time}>
                <TimeAgo value={comment.created} expandable />
              </span>{' '}
              -{' '}
              <span>
                {comment.referral && 'Juvo referral by '}
                {viewingAsStudent && !comment.isStudent ? comment.author.givenNames : comment.author.name}
              </span>
              {children}
              {renderAdditionalRecipient(comment) && (
                <div className={classes.additionalInfoContainer}>
                  <Typography className={classes.additionalRecipient} variant="caption">
                    Additional recipient: {comment.additionalRecipient}
                  </Typography>
                </div>
              )}
              {!viewingAsStudent && comment.advisingAppointment && (
                <div className={classes.additionalInfoContainer}>
                  <span>
                    <AdvisingAppointmentIcon className={classes.advisingAppointmentIcon} fontSize="small" />
                    <Typography variant="caption">Advising appointment</Typography>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Comment.propTypes = {
  classes: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  children: PropTypes.object,
  viewingAsStudent: PropTypes.bool,
  highlight: PropTypes.string,
};

Comment.defaultProps = {
  children: undefined,
  viewingAsStudent: false,
  highlight: '',
};

const styles = (theme) => ({
  commentWrapper: {
    marginBottom: 25,
    width: '100%',
    position: 'relative',
  },
  comment: {
    '& $avatar': {
      right: 0,
    },
  },
  commentContent: {
    position: 'relative',
    borderRadius: 8,
    marginBottom: 7,
    maxWidth: '80%',
    float: 'right',
  },
  commentPrimary: {
    '& $commentContent': {
      color: 'white',
      backgroundColor: blue[400],
      fontWeight: 500,
    },
  },
  message: {
    '& ,$comment': {
      margin: theme.spacing(0, 5),
    },
    '& $commentContent:before': {
      content: '""',
      position: 'absolute',
      right: -8,
      top: 6,
      width: 0,
      height: 0,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderLeftColor: blue[400],
      borderTopColor: blue[400],
    },
  },
  commentText: {
    padding: 10,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& p': {
      margin: 0,
    },
  },
  attachments: {
    padding: 10,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: blue[50],
  },
  commentSecondary: {
    '& $commentContent': {
      textAlign: 'left',
      backgroundColor: grey[200],
      float: 'left',
    },
    '& $commentContent:before': {
      left: -8,
      borderColor: 'transparent',
      borderRightColor: grey[200],
      borderTopColor: grey[200],
    },
    '& $attachments': {
      backgroundColor: grey[300],
    },
    '& $commentInfo': {
      textAlign: 'left',
    },
    '& $avatar': {
      right: 'inherit',
      left: 0,
    },
  },
  commentNote: {
    '& $commentContent': {
      backgroundColor: '#30C530',
      fontWeight: 400,
    },
    '& $attachments': {
      backgroundColor: lightGreen[100],
    },
  },
  commentReferral: {
    '& $commentContent': {
      backgroundColor: purple[50],
      color: grey[800],
      fontWeight: 400,
    },
    '& $attachments': {
      backgroundColor: grey[300],
    },
  },
  avatar: {
    height: 30,
    width: 30,
    position: 'absolute',
    right: 0,
    top: 6,
    fontSize: '1rem',
  },
  commentInfo: {
    fontSize: 12,
    textAlign: 'right',
    clear: 'both',
  },
  time: {
    fontWeight: 300,
  },
  additionalInfoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  additionalRecipient: {
    marginRight: theme.spacing(0.5),
  },
  advisingAppointmentIcon: {
    color: theme.palette.secondary.main,
    verticalAlign: 'bottom',
    marginRight: theme.spacing(0.5),
  },
});

export default withStyles(styles)(Comment);
