import { Tabs, Tab, AppBar, Toolbar, Tooltip, withStyles } from '@material-ui/core';
import { InsertInvitation as InvitationIcon, Message as MessagesIcon } from '@material-ui/icons/';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import CalendlyAppointmentWidget from '../../components/students/CalendlyAppointmentWidget';
import { getCurrentStudentDetails, getCurrentStudentDetailsLoading } from '../../reducers';
import { fetchCurrent } from '../../actions/studentDetails';
import SharedCommentsAsStudentContainer from '../SharedCommentsAsStudentContainer';
import StudentBookAppointment from '../../components/students/StudentBookAppointment';
import PageLoading from '../../components/PageLoading';
import StudentViewHeader from '../../components/students/StudentViewHeader';
import NoStudentData from './NoStudentData';

class StudentDashboardPage extends Component {
  componentDidMount() {
    const { fetchCurrentFromServer } = this.props;
    fetchCurrentFromServer();
  }

  onClickTab = (e, val) => {
    const { navigateTo } = this.props;
    navigateTo(`/student/${val}`);
  };

  render() {
    const {
      loading,
      student,
      classes,
      match: {
        params: { page = 'messages' },
      },
    } = this.props;

    if (loading) return <PageLoading />;
    if (!student) return <NoStudentData />;

    const { adviser, temporaryAdviser } = student;

    const hasTempAdviser = () => !!temporaryAdviser;
    const useAdviserCalendly = () => adviser.calendlyUrl;
    const useTempAdviserCalendly = () => hasTempAdviser() && temporaryAdviser.calendlyUrl;
    return (
      <div>
        <div>
          <div className={classes.header}>
            <StudentViewHeader student={student} />

            <AppBar elevation={0} position="static" color="default" className={classes.tabBar}>
              <Toolbar className={classes.toolbar}>
                <Tabs className={classes.tabs} value={page} onChange={this.onClickTab}>
                  <Tab
                    icon={<MessagesIcon />}
                    label={
                      <Tooltip title="Send message to your adviser" placement="bottom">
                        <span>Messages</span>
                      </Tooltip>
                    }
                    value="messages"
                  />
                  {useAdviserCalendly() ? (
                    <Tab
                      icon={<InvitationIcon />}
                      label={
                        <Tooltip title="Book appointment" placement="bottom">
                          <span>{adviser.greetingName}</span>
                        </Tooltip>
                      }
                      value="calendly-appointments"
                    />
                  ) : (
                    <Tab
                      icon={<InvitationIcon />}
                      label={
                        <Tooltip title="Book appointment" placement="bottom">
                          <span>{adviser.greetingName}</span>
                        </Tooltip>
                      }
                      value="appointments"
                    />
                  )}
                  {hasTempAdviser() && useTempAdviserCalendly() && (
                    <Tab
                      icon={<InvitationIcon />}
                      label={
                        <Tooltip title="Book appointment" placement="bottom">
                          <span>{temporaryAdviser.greetingName}</span>
                        </Tooltip>
                      }
                      value="calendly-appointments-temp-adviser"
                    />
                  )}
                  {hasTempAdviser() && !useTempAdviserCalendly() && (
                    <Tab
                      icon={<InvitationIcon />}
                      label={
                        <Tooltip title="Book appointment" placement="bottom">
                          <span>{temporaryAdviser.greetingName}</span>
                        </Tooltip>
                      }
                      value="appointments-temp-adviser"
                    />
                  )}
                </Tabs>
              </Toolbar>
            </AppBar>
          </div>
          <div className={classes.container}>
            {page === 'messages' && <SharedCommentsAsStudentContainer studentId={student.id} />}
            {page === 'appointments' && <StudentBookAppointment adviser={adviser} />}
            {page === 'calendly-appointments' && <CalendlyAppointmentWidget url={adviser.calendlyUrl} student={student} />}
            {page === 'calendly-appointments-temp-adviser' && <CalendlyAppointmentWidget url={temporaryAdviser.calendlyUrl} student={student} />}
            {page === 'appointments-temp-adviser' && <StudentBookAppointment adviser={temporaryAdviser} />}
          </div>
        </div>
      </div>
    );
  }
}

StudentDashboardPage.propTypes = {
  fetchCurrentFromServer: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  student: PropTypes.object,
  match: PropTypes.object.isRequired,
  navigateTo: PropTypes.func.isRequired,
  classes: object.isRequired,
};

StudentDashboardPage.defaultProps = {
  student: null,
};

const styles = (theme) => ({
  header: {
    paddingTop: 30,
    backgroundColor: '#FFF',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.15)',
  },
  toolbar: {
    minHeight: 0,
  },
  tabs: {
    minHeight: 0,
    '& button': {
      height: 40,
    },
  },
  tabBar: {
    backgroundColor: 'inherit',
  },
  container: {
    width: '100%',
    padding: theme.spacing(4),
  },
  invitationIcon: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing(1),
  },
});

export default connect(
  (state) => ({
    student: getCurrentStudentDetails(state),
    loading: getCurrentStudentDetailsLoading(state),
  }),
  {
    fetchCurrentFromServer: fetchCurrent,
    navigateTo: push,
  },
)(withStyles(styles)(StudentDashboardPage));
