import { withStyles } from '@material-ui/core';
import { object } from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import StudentsTable from '../../components/students/StudentsTable/StudentsTable';
import * as model from '../../model';
import { getLoggedInUser } from '../../reducers';

const AdviserMyStudentsPage = ({ classes, adviser }) => (
  <div className={classes.root}>
    <StudentsTable title="My students" adviser={adviser} />
  </div>
);

AdviserMyStudentsPage.propTypes = {
  adviser: model.user.isRequired,
  classes: object.isRequired,
};

const styles = (theme) => ({
  root: {
    margin: theme.spacing(4),
  },
});

export default compose(
  connect((state) => ({
    adviser: getLoggedInUser(state),
  })),
  withStyles(styles),
)(AdviserMyStudentsPage);
