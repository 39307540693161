import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

const DatePicker = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

  const handleChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <div>
      <input type="date" value={selectedDate} onChange={handleChange} />
    </div>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
