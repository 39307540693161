import find from 'lodash/find';

const advisers = (state = [], action) => {
  switch (action.type) {
    case 'ADVISERS_FETCH_ALL_SUCCESS':
      return action.response;
    case 'ADVISERS_START_LEAVE_SUCCESS':
    case 'ADVISERS_END_LEAVE_SUCCESS':
      const newState = [...state];
      const changedAdviser = find(newState, { id: action.response.id });
      if (changedAdviser) changedAdviser.onLeave = action.response.onLeave;
      return newState;
    default:
      return state;
  }
};

export const getAll = (state) => [...state];

export default advisers;
