import { blue, grey, red } from '@material-ui/core/colors';
import { FormControl, InputLabel, makeStyles, TextField } from '@material-ui/core';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './ReactDatesOverrides.less';

const CustomTextField = ({ onClick, value }) => <TextField onClick={onClick} value={value} />;
CustomTextField.propTypes = { onClick: PropTypes.func.isRequired, value: PropTypes.string.isRequired };

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 'initial', // Fix to make datepicker look normal
    display: 'inline-block',
    marginTop: 18,
    marginRight: theme.spacing(),
    zIndex: 'inherit',
    '& .SingleDatePickerInput': {
      background: 'none',
    },
    '& .DateInput_input::placeholder': {
      color: grey[400],
    },
    '& .DateInput': {
      background: 'none',
    },
    '& .DateInput_fang': {
      marginTop: -14, // offset by 1px to _picker because point did not meet main picker. Revert difference if this works in future versions.
    },
    '& .SingleDatePicker_picker': {
      marginTop: -15,
    },
    '& .DateInput_input': {
      fontWeight: 400,
      paddingLeft: 0,
      borderBottomColor: '#0000006b',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      background: 'none',
    },
    '& .DateInput_input:hover': {
      borderBottomColor: grey[900],
      borderBottomWidth: 2,
    },
    '& .DateInput_input__focused, & .DateInput_input:active': {
      borderBottomColor: blue[500],
      borderBottomWidth: 2,
    },
  },
  inputLabel: {
    top: -15,
  },
  error: {
    color: red[500],
    marginTop: 5,
  },
}));

const DatePickerReduxComponent = ({ meta, input, className, label, placeholder, ...rest }) => {
  const [isFocused, setFocused] = useState(false);
  const classes = useStyles();
  const fieldPlaceholder = placeholder || label;

  const onChangeCustom = (momentDate) => {
    input.onChange(momentDate ? momentDate.format('YYYY-MM-DD') : null);
  };

  return (
    <FormControl className={cx(classes.root, className, { 'form-error': meta.touched && meta.invalid })}>
      {label && (
        <InputLabel className={classes.inputLabel} shrink={isFocused || !!input.value} focused={isFocused}>
          {label}
        </InputLabel>
      )}
      <SingleDatePicker
        displayFormat="DD/MM/YYYY"
        isOutsideRange={() => false}
        date={input.value ? moment(input.value) : null} // momentPropTypes.momentObj or null
        onDateChange={onChangeCustom} // PropTypes.func.isRequired
        focused={isFocused} // PropTypes.bool
        onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
        placeholder={isFocused ? fieldPlaceholder : ''}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        noBorder
        {...rest}
      />
      {meta.touched && meta.invalid && meta.error && <div className={classes.error}>{meta.error}</div>}
    </FormControl>
  );
};

DatePickerReduxComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

DatePickerReduxComponent.defaultProps = {
  className: undefined,
  label: undefined,
  placeholder: undefined,
};

export default DatePickerReduxComponent;
