import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Alert from 'react-s-alert';
import api from '../../../services/api';
import CompleteAlertsContent from './CompleteAlertsContent';
import ActionDialog from '../../common/ActionDialog';

const CompleteAlerts = ({
  allStudentsMatchingSearchSelected,
  students,
  adviser,
  onActionCompleted,
  studentTotalMatchCount,
  searchCriteria,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { alertTypes } = searchCriteria;
  const [cutOffDate, setCutOffDate] = useState(new Date().toISOString().slice(0, 10));

  const stopSubmittingWithError = (error) => {
    setSubmitting(false);
    setErrorMessages(error.messages);
  };

  const toggleDialogState = () => setDialogOpen(!dialogOpen);

  const handleCompleteAlerts = () => {
    setSubmitting(true);
    const promise = allStudentsMatchingSearchSelected ?
      api.alerts.bulkCompleteAlertsSearch(adviser.id, searchCriteria, alertTypes, cutOffDate) :
      api.alerts.bulkCompleteAlerts(students.map((student) => student.id), alertTypes, cutOffDate);

    return promise
      .then(() => {
        setSubmitting(false);
        Alert.success('Alerts have been queued for completion');
        toggleDialogState();
        onActionCompleted();
      })
      .catch((error) => stopSubmittingWithError(error));
  };

  const alertTypeCount = alertTypes ? alertTypes.length : 0;

  return (
    <>
      <Button variant="contained" color="primary" onClick={toggleDialogState} disabled={!enabled}>
        Complete alerts
      </Button>

      <ActionDialog
        title={
          allStudentsMatchingSearchSelected
            ? `Completing ${alertTypeCount} alert types for all ${studentTotalMatchCount} students`
            : `Completing ${alertTypeCount} alert types for ${students.length} students`
        }
        submitButtonText="Complete Alerts"
        open={dialogOpen}
        onCancel={toggleDialogState}
        onSubmit={handleCompleteAlerts}
      >
        <CompleteAlertsContent
          students={students}
          allStudentsMatchingSearchSelected={allStudentsMatchingSearchSelected}
          submitting={submitting}
          errorMessages={errorMessages}
          searchCriteria={searchCriteria}
          acceptDate={setCutOffDate}
        />
      </ActionDialog>
    </>
  );
};

CompleteAlerts.propTypes = {
  students: PropTypes.array.isRequired,
  searchCriteria: PropTypes.object.isRequired,
  adviser: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  allStudentsMatchingSearchSelected: PropTypes.bool.isRequired,
  studentTotalMatchCount: PropTypes.number.isRequired,
  onActionCompleted: PropTypes.func.isRequired,
};

export default CompleteAlerts;
