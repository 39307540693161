import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Link, makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { string } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  csvFile: {
    backgroundColor: grey[200],
    padding: theme.spacing(1, 2),
    borderRadius: 3,
    fontFamily: 'monospace',
    fontSize: '1rem',
    color: grey[800],
    margin: theme.spacing(2),
  },
}));

const CustomAlertCsvHelp = ({ className }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <Tooltip title="CSV format" className={className}>
        <IconButton key="help" aria-label="Help" color="inherit" onClick={() => setDialogOpen(true)}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog title="Custom alert CSV format" maxWidth="md" open={dialogOpen} onConfirm={() => setDialogOpen(false)}>
        <div>
          <div>
            <p>
              The format of the CSV for custom alerts is <code>CLASSIFICATION,ALERT_TEXT,TASKS,STUDENT_IDS</code>.
            </p>
            <p>You can even have a CSV with more columns, as long as it contains these 4 column names.</p>
            <p>
              <ul>
                <li>
                  <code>CLASSIFICATION</code>: One of <code>RESPONSE</code>, <code>ACTION</code>, <code>INFORMATION</code>, <code>CELEBRATION</code>.
                </li>
                <li>
                  <code>ALERT_TEXT</code>: Display text for the alert.
                </li>
                <li>
                  <code>TASKS</code>: Pipe-separated list of tasks for the alert. One or more.
                </li>
                <li>
                  <code>STUDENT_IDS</code>: Pipe-separated list of student IDs. One or more.
                </li>
              </ul>
            </p>
            <p>See example rows below.</p>
          </div>
          <div className={classes.csvFile}>
            <ul className="unstyled-list">
              <li>CLASSIFICATION,ALERT_TEXT,TASKS,STUDENT_IDS</li>
              <li>RESPONSE,Custom response alert,Task 1|Task 2,12345678</li>
              <li>ACTION,Custom action alert,Task 1|Task 2,11223344|87654321</li>
            </ul>
          </div>
          <div>
            <p>
              To start quickly you can download a{' '}
              <Link className={classes.downloadLink} href="/api/storage?name=csv/examples/example-custom-alerts.csv" rel="noopener noreferrer">
                sample CSV
              </Link>
              .
            </p>
          </div>
        </div>
      </ConfirmationDialog>
    </>
  );
};

CustomAlertCsvHelp.propTypes = {
  className: string,
};

CustomAlertCsvHelp.defaultProps = {
  className: '',
};

export default CustomAlertCsvHelp;
