/* eslint-disable no-undef,react/no-unused-prop-types */
import { withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import cx from 'classnames';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import AutoLinks from 'quill-auto-links';
import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import isEmpty from 'lodash/isEmpty';
import 'react-quill/dist/quill.snow.css';
import './quill-extension.less';

Quill.register('modules/autoLinks', AutoLinks);

// not ideal, but the quill callback is not a child of this component, so it cannot access this.props
// to get at quickLinks, therefore add them to this constant during construction
const quickLinksReferenceForQuillCallback = {};

class RichTextReduxComponent extends Component {
  constructor(props) {
    super(props);
    props.quickLinks.forEach((ql) => {
      quickLinksReferenceForQuillCallback[ql.name] = ql.url;
    });
  }

  state = {
    text: '',
  };

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [
          { list: 'bullet' },
          'link',
          {
            // putting these in here does not actually render them. We also need to update
            // the htmlContent of the elements. Reference: https://jsfiddle.net/q7jferw3/
            // sourced from https://github.com/quilljs/quill/issues/1817
            placeholder: [
              ...(isEmpty(this.props.quickLinks)
                ? ['No quick links configured']
                : sortBy(this.props.quickLinks, (ql) => ql.name.toLowerCase()).map((ql) => ql.name)),
            ],
          },
        ],
      ],
      handlers: {
        placeholder: function placeholderHandler(value) {
          if (value) {
            const link = quickLinksReferenceForQuillCallback[value];
            if (link) {
              const cursorPosition = this.quill.getSelection().index;
              this.quill.clipboard.dangerouslyPasteHTML(cursorPosition, `<a href="${link}">${value}</a> `);
              this.quill.setSelection(cursorPosition + value.length);
            }
          }
        },
      },
    },
    autoLinks: true,
  };

  formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

  componentDidMount() {
    const { viewingAsStudent } = this.props;
    this.setState({ text: this.props.input.value });
    // We need to manually supply the HTML content of our custom dropdown based on the items declared
    // in the 'placeholder' above.
    const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));

    placeholderPickerItems.forEach((item) => {
      item.textContent = item.dataset.value; // eslint-disable-line no-param-reassign
    });

    const element = document.querySelector('.ql-placeholder .ql-picker-label');
    element.innerHTML = `Quick links ${element.innerHTML}`;

    if (viewingAsStudent) {
      const quickLinksContainer = document.getElementsByClassName('ql-placeholder ql-picker');
      Object.values(quickLinksContainer).forEach((e) => e.classList.add('ql-hidden'));
    }
  }

  componentWillUnmount() {
    Object.keys(quickLinksReferenceForQuillCallback).forEach((key) => delete quickLinksReferenceForQuillCallback[key]);
  }

  onChange = (newValue, delta, source) => {
    const { input } = this.props;
    if (source === 'user' || source === 'api') {
      const value = newValue === '<p><br></p>' ? '' : newValue;
      this.setState({ text: value });
      input.onChange(value);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.state.text) {
      this.setState({ text: nextProps.input.value });
    }
  }

  render() {
    const { label, classes, meta, disabled } = this.props;
    return (
      <>
        <div className={cx(classes.editorWrapper, { 'form-error': meta.touched && meta.invalid })}>
          <ReactQuill
            readOnly={disabled}
            onChange={this.onChange}
            modules={this.modules}
            formats={this.formats}
            value={this.state.text}
            placeholder={label}
          />
        </div>

        {meta.touched && meta.invalid && meta.error && <div className={classes.error}>{meta.error}</div>}
      </>
    );
  }
}

const styles = {
  editorWrapper: {
    backgroundColor: '#fff',
    '& .ql-editor': {
      minHeight: (props) => (props.maxHeight && props.maxHeight < 150 && undefined) || 150,
      maxHeight: (props) => props.maxHeight || undefined,
      overflowY: (props) => (props.maxHeight && 'scroll') || undefined,
    },
    '& .ql-snow .ql-tooltip': {
      zIndex: 10000,
    },
  },
  error: {
    color: red[500],
    marginTop: 5,
  },
};

RichTextReduxComponent.propTypes = {
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  maxHeight: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  viewingAsStudent: PropTypes.bool,
  quickLinks: PropTypes.array,
};

RichTextReduxComponent.defaultProps = {
  label: 'Write some text',
  disabled: false,
  maxHeight: undefined,
  viewingAsStudent: false,
  quickLinks: [],
};

export default withStyles(styles)(RichTextReduxComponent);
