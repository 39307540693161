import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  Edit as EditIcon,
  BeachAccess as BeachAccessIcon,
  ExpandMore as ExpandMoreIcon,
  Launch as LaunchIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
} from '@material-ui/icons';
import cx from 'classnames';
import ArrowCollapseIcon from 'mdi-material-ui/ArrowCollapse';
import ArrowExpandIcon from 'mdi-material-ui/ArrowExpand';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStudentDetails } from '../../actions/studentDetails';
import { getReferenceDataDescription } from '../../reducers';
import AdviserResourcesButton from '../common/AdviserResourcesButton';
import CopyToClipboardButton from '../common/CopyToClipboardButton';
import JuvoAvatar from '../common/JuvoAvatar';
import SimpleTable from '../common/SimpleTable';
import CourseMap from './CourseMap';
import UpdateStudentDetailsForm from './UpdateStudentDetailsForm';
import StudentFlags from './StudentFlags';
import Adviser from './Adviser';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 5,
    position: 'inherit',
  },
  expansionPanelSummary: {
    display: 'flex',
    marginTop: '0',
  },
  expandIcon: {
    right: '50%',
    top: '-30px',
  },
  expansionPanels: {
    boxShadow: 'none',
    '&::before': {
      height: '0',
    },
  },
  expansionPanelDetails: {
    borderTop: `1px solid ${grey[300]}`,
    borderBottom: `1px solid ${grey[300]}`,
    backgroundColor: grey[100],
  },
  avatarWrapper: {
    float: 'left',
    position: 'relative',
  },
  expandAvatarButton: {
    bottom: theme.spacing(2),
    left: -21,
    '& svg': {
      fontSize: 15,
    },
  },
  studentDetailsWrapper: {
    marginLeft: 15,
    marginTop: 3,
    flexGrow: 1,
  },
  title: {
    fontSize: '2em',
    fontWeight: 700,
    lineHeight: 1,
  },
  subtitle: {
    display: 'inline-block',
    color: grey[800],
    fontWeight: 300,
    marginTop: 3,
  },
  subSubtitle: {
    color: grey[800],
    fontWeight: 300,
  },
  phoneIcon: {
    color: grey[700],
    fontWeight: 300,
    fontSize: 18,
    position: 'relative',
    top: '2px',
    verticalAlign: 'top',
    marginLeft: theme.spacing(1),
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  buttonExternal: {
    color: grey[800],
    fontWeight: 300,
    '& svg': {
      fontSize: 15,
      position: 'relative',
      top: -1,
      left: 4,
    },
  },
  updateStudentDetailsButton: {
    color: grey[700],
    verticalAlign: 'sub',
    marginLeft: theme.spacing(1),
  },
  updateStudentDetailsIcon: {
    fontSize: 18,
  },
  flags: {
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonSmall: {
    padding: theme.spacing(),
    minHeight: 20,
  },
  muted: {
    fontWeight: 300,
  },
  value: {
    marginLeft: theme.spacing(),
    fontWeight: 500,
  },
}));

const StudentHeader = ({ student }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const gender = useSelector((state) => getReferenceDataDescription(state, 'Gender', student.gender));
  const citizenship = useSelector((state) => getReferenceDataDescription(state, 'CitizenshipType', student.citizenshipType));
  const dispatchUpdateStudentDetails = (studentId, updatedDetails) => dispatch(updateStudentDetails(studentId, updatedDetails));

  const [avatarExpanded, setAvatarExpanded] = useState(false);
  const [updateStudentDetailsDialogOpen, setUpdateStudentDetailsDialogOpen] = useState(false);
  const [updatingStudentDetails, setUpdatingStudentDetails] = useState(false);
  const [updateStudentDetailsErrorMessage, setUpdateStudentDetailsErrorMessage] = useState('');

  if (!student) return <div>No data</div>;
  const phone = student.mobilePhone || student.homePhone || student.businessPhone;
  const firstTable = [
    { label: 'Legal name', value: `${student.surname.toUpperCase()}, ${student.givenNames} - ${student.personId}`, copyable: true },
    { label: 'Email', value: student.email, copyable: true },
    { label: 'Phone', value: student.mobilePhone },
  ];

  const secondTable = [
    { label: 'Preferred name', value: student.knownAsName },
    { label: 'Given names', value: student.givenNames },
    { label: 'Surname', value: student.surname },
    { label: 'Gender', value: gender },
  ];

  return (
    <>
      <UpdateStudentDetailsForm
        onSubmit={(values) => {
          setUpdatingStudentDetails(true);
          dispatchUpdateStudentDetails(student.personId, { preferredName: values.preferredName, courseMap: values.courseMap })
            .then(() => setUpdateStudentDetailsDialogOpen(false))
            .catch((error) => setUpdateStudentDetailsErrorMessage(error.message))
            .finally(() => setUpdatingStudentDetails(false));
        }}
        initialValues={{ preferredName: student.preferredName, courseMap: student.courseMap, studentId: student.studentId }}
        submitting={updatingStudentDetails}
        open={updateStudentDetailsDialogOpen}
        errorMessage={updateStudentDetailsErrorMessage}
        onCancel={() => {
          setUpdateStudentDetailsDialogOpen(false);
          setUpdateStudentDetailsErrorMessage('');
        }}
      />
      <ExpansionPanel elevation={0} className={classes.root}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.expansionPanelSummary, expandIcon: classes.expandIcon }}>
          <div className={cx(classes.avatarWrapper, { [classes.avatarWrapperExpanded]: avatarExpanded })}>
            <JuvoAvatar image={student.image} size={avatarExpanded ? 'lg' : 'md'} />

            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAvatarExpanded(!avatarExpanded);
              }}
              className={classes.expandAvatarButton}
            >
              {avatarExpanded ? <ArrowCollapseIcon /> : <ArrowExpandIcon />}
            </IconButton>
          </div>

          <div className={classes.studentDetailsWrapper}>
            <Grid container>
              <Grid item sm={12} md={6}>
                <Typography variant="h6" className={classes.title}>
                  {student.name}
                  <CopyToClipboardButton text={student.name} />
                </Typography>

                <Typography variant="body2">
                  <span className={classes.muted}>Juvo Nickname:</span>
                  <span className={classes.value}>{student.preferredName}</span>
                  <Tooltip title="Update student details" placement="right-end">
                    <Button
                      className={classes.updateStudentDetailsButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        setUpdateStudentDetailsDialogOpen(true);
                      }}
                      variant="text"
                      size="small"
                    >
                      <EditIcon className={classes.updateStudentDetailsIcon} />
                    </Button>
                  </Tooltip>
                </Typography>
                <CourseMap courseMap={student.courseMap} />

                <div className={classes.subtitle}>
                  ID: {student.personId}
                  <CopyToClipboardButton text={student.personId} />
                  {phone} {phone && <PhoneIcon className={classes.phoneIcon} />}
                </div>

                <div className={classes.subSubtitle}>
                  {citizenship}
                  <Adviser adviser={student.adviser} icon={PersonIcon} muted={!!student.temporaryAdviser} />
                  <Adviser adviser={student.temporaryAdviser} icon={BeachAccessIcon} />
                </div>

                <StudentFlags student={student} />
              </Grid>

              <Grid item sm={12} md={6} className={classes.rightSide}>
                <div className={classes.links}>
                  <AdviserResourcesButton />
                  <Button
                    className={classes.buttonExternal}
                    size="small"
                    target="_blank"
                    href="https://my-timetable.monash.edu/even/admin/adminLogin.do"
                  >
                    Allocate + <LaunchIcon />
                  </Button>
                  <Button
                    className={classes.buttonExternal}
                    size="small"
                    target="_blank"
                    href={`https://monplan.apps.monash.edu/students/${student.personId}`}
                  >
                    MonPlan <LaunchIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <Grid container>
            <Grid item sm={6}>
              <SimpleTable rows={firstTable} />
            </Grid>
            <Grid item sm={6}>
              <SimpleTable rows={secondTable} />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

StudentHeader.propTypes = {
  student: PropTypes.object.isRequired,
};

export default StudentHeader;
