const studentDetails = (state = { student: null, loading: false }, action) => {
  switch (action.type) {
    case 'SET_SNOOZED_SUCCESS':
    case 'SET_PREFERRED_NAME_SUCCESS':
    case 'FETCH_STUDENT_DETAILS_SUCCESS':
      return {
        [action.response.id]: action.response,
        loading: false,
      };
    case 'FETCH_STUDENT_DETAILS_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_STUDENT_DETAILS_FAILED':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getById = (state, id) => state[id];
export const getLoading = (state) => state.loading;

export default studentDetails;
