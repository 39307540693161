import { Button, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Checkbox, TextField } from '../wrappers/material';
import DatePickerReduxComponent from './DatePickerReduxComponent';
import FormError from './FormError';

const useStyles = makeStyles(() => ({
  dateFieldContainer: {},
  dateField: {
    width: 200,
  },
  textAreaField: {
    marginTop: 20,
    maxWidth: 1000,
  },
}));

const SystemSettingsForm = ({ handleSubmit, submitting, error, divertEmailsEnabled }) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormError value={error} />

      <FormGroup>
        <FormControlLabel control={<Field name="allowWelcomeEmail" component={Checkbox} />} label={<span>Allow welcome email</span>} />
        <FormControlLabel control={<Field name="fetchNewStudentImage" component={Checkbox} />} label={<span>Fetch image for new student</span>} />
        <FormControlLabel
          control={<Field name="processMoodleAssignmentCsvFiles" component={Checkbox} />}
          label={<span>Process incoming Moodle assignment CSV files</span>}
        />
        <FormControlLabel
          control={<Field name="processMoodleEngagementCsvFiles" component={Checkbox} />}
          label={<span>Process incoming Moodle engagement CSV files</span>}
        />

        <div className={classes.dateFieldContainer}>
          <Field
            name="reEnrolmentEndDate"
            className={classes.dateField}
            label="Re-enrolment end date"
            placeholder="dd/MM/yyyy"
            component={DatePickerReduxComponent}
          />
        </div>

        {divertEmailsEnabled && (
          <Field
            className={classes.textAreaField}
            name="diversionEmails"
            label="Diversion emails (comma-separated)"
            component={TextField}
            rows="3"
            multiline
          />
        )}
      </FormGroup>

      <div className="actions actions-right">
        <Button variant="contained" color="primary" type="submit" disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </form>
  );
};

SystemSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  divertEmailsEnabled: PropTypes.bool,
  error: PropTypes.any,
};

SystemSettingsForm.defaultProps = {
  error: undefined,
  divertEmailsEnabled: false,
};

export default reduxForm({ form: 'systemSettingsForm' })(SystemSettingsForm);
