import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import MessageIcon from '@material-ui/icons/Message';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import CoreLayout from '../../components/layouts/CoreLayout';
import { isAdmin as isAdminReducer } from '../../reducers';

const AdviserLayout = ({ children, isAdmin }) => {
  const menuItems = [
    {
      path: '/adviser/home',
      title: 'Home',
      exact: false,
      Icon: HomeIcon,
    },
    {
      path: '/adviser/students',
      title: 'My students',
      exact: false,
      Icon: PeopleIcon,
    },
    {
      path: '/adviser/announcements',
      title: 'Announcements',
      Icon: AnnouncementIcon,
    },
    {
      path: '/adviser/group-message',
      title: 'Group message students',
      Icon: MessageIcon,
    },
    {
      path: '/adviser/bulk-internal-notes',
      title: 'Bulk create notes',
      Icon: AssignmentIcon,
    },
  ];

  if (isAdmin) {
    menuItems.push({
      path: '/admin',
      title: 'Admin view',
      Icon: ArrowUpwardIcon,
    });
  }

  return <CoreLayout menuItems={menuItems}>{children}</CoreLayout>;
};

AdviserLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isAdmin: isAdminReducer(state),
}))(AdviserLayout);
