import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArchiveIcon from '@material-ui/icons/Archive';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FlipMove from 'react-flip-move';
import { useDispatch, useSelector } from 'react-redux';
import { moveAlertToCompleted } from '../../actions/alerts';
import { getReferenceDataOrdinal } from '../../reducers';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Alert from './Alert';

const useStyles = makeStyles((theme) => ({
  completeAllOpenAlertsButton: {
    marginBottom: theme.spacing(1),
  },
  completeAllAlertsIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const AlertList = (props) => {
  const [showConfirmationDialog, updateShowConfirmationDialog] = useState(false);
  const [closingAllAlerts, updateClosingAllAlerts] = useState(false);
  const dispatch = useDispatch();
  const getAlertClassificationOrdinal = useSelector((state) => (alert) => getReferenceDataOrdinal(state, 'JuvoClassification', alert.classification));
  const classes = useStyles();
  const { alerts, hideArchiveButtons, studentId } = props;

  const closeAllAlerts = () => {
    updateClosingAllAlerts(true);
    api.alerts
      .closeOpenAlertsForStudent(studentId)
      .then(() => {
        updateShowConfirmationDialog(false);
        setTimeout(() => sortBy(alerts, getAlertClassificationOrdinal).forEach((alert) => dispatch(moveAlertToCompleted(studentId, alert.id))), 100);
      })
      .finally(() => updateClosingAllAlerts(false));
  };

  return (
    <>
      <ConfirmationDialog
        title="Archive all open alerts for student?"
        content="Confirmation of this will close all open alerts against this student"
        submitting={closingAllAlerts}
        open={showConfirmationDialog}
        onCancel={() => updateShowConfirmationDialog(false)}
        onConfirm={closeAllAlerts}
      />
      {!isEmpty(alerts) && (
        <Button
          className={classes.completeAllOpenAlertsButton}
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => updateShowConfirmationDialog(true)}
        >
          Archive all alerts
          <ArchiveIcon className={classes.completeAllAlertsIcon} />
        </Button>
      )}
      <FlipMove maintainContainerHeight>
        {sortBy(alerts, getAlertClassificationOrdinal).map((alert) => (
          <Alert key={alert.id} alert={alert} isOpenView={!hideArchiveButtons} studentId={studentId} />
        ))}
      </FlipMove>
    </>
  );
};

AlertList.propTypes = {
  alerts: PropTypes.array,
  hideArchiveButtons: PropTypes.bool,
  studentId: PropTypes.string.isRequired,
};

AlertList.defaultProps = {
  alerts: [],
  hideArchiveButtons: false,
};

export default AlertList;
