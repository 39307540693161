/* eslint-disable react/no-array-index-key */
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import cx from 'classnames';
import { fetchApplicationSettings, updateApplicationSettings } from '../actions/application-settings';
import ErrorMessages from '../components/forms/ErrorMessages';
import SystemSettingsForm from '../components/forms/SystemSettingsForm';
import { getApplicationSettings } from '../reducers';
import AdminReports from '../components/reports/AdminReports';
import CustomAlerts from '../components/alerts/custom/CustomAlerts';
import QuickLinksPage from './QuickLinksPage';

class SystemSettingsPage extends Component {
  static propTypes = {
    fetchApplicationSettingsFromServer: PropTypes.func.isRequired,
    updateApplicationSettingsOnServer: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.fetchApplicationSettingsFromServer();
  }

  handleSubmit = (formValues) => {
    this.props
      .updateApplicationSettingsOnServer(formValues)
      .then(() => {
        Alert.success('Settings updated');
      })
      .catch((error) => {
        Alert.error(<ErrorMessages error={error} />);
        throw new SubmissionError({ _error: error });
      });
  };

  render() {
    const { classes, settings } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={cx(classes.padded, classes.firstRowFullPage)}>
              <div className={classes.header}>
                <Typography variant="h5">System settings</Typography>
              </div>
              <div>
                <SystemSettingsForm onSubmit={this.handleSubmit} initialValues={settings} divertEmailsEnabled={settings.divertEmail} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={cx(classes.padded, classes.firstRowHalfPage)}>
                  <AdminReports />
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper className={cx(classes.padded, classes.firstRowHalfPage)}>
                <CustomAlerts />
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.padded}>
              <QuickLinksPage />
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: getApplicationSettings(state),
});

const mapDispatchToProps = {
  fetchApplicationSettingsFromServer: fetchApplicationSettings,
  updateApplicationSettingsOnServer: updateApplicationSettings,
};

const styles = (theme) => ({
  iconLeft: {
    marginRight: 5,
  },
  header: {
    minHeight: theme.spacing(10),
  },
  firstRowFullPage: {
    minHeight: 520,
  },
  firstRowHalfPage: {
    minHeight: 248, // removing 12 from half of 600 for the margin of 24 between the two of them stacked vertically
  },
  padded: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SystemSettingsPage));
