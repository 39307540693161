import { Link, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import { FormattedDate } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  courseMapContainer: {
    marginBottom: theme.spacing(),
  },
  linkIcon: {
    height: 14,
    verticalAlign: 'middle',
  },
  explanatoryText: {
    marginLeft: theme.spacing(0.5),
    color: grey[700],
    fontWeight: 300,
    fontSize: '0.75rem',
  },
}));

const CourseMap = ({ courseMap }) => {
  const classes = useStyles();
  if (!courseMap) {
    return null;
  }

  return (
    <div className={classes.courseMapContainer}>
      <Typography variant="body2">
        <Link title={courseMap.filename} href={courseMap.requestLink} target="_blank" rel="noopener noreferrer">
          Course map
          <LaunchIcon className={classes.linkIcon} />
        </Link>
        <span className={classes.explanatoryText}>
          (updated <FormattedDate value={courseMap.created} />)
        </span>
      </Typography>
    </div>
  );
};

CourseMap.propTypes = {
  courseMap: PropTypes.object,
};

CourseMap.defaultProps = {
  courseMap: null,
};

export default CourseMap;
