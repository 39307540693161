import { Link, makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getReferenceDataDescription } from '../../reducers';
import { green, grey, red } from '@material-ui/core/colors';
import cx from 'classnames';
import { date, prettyInt } from '../../util/formatters';
import DateTime from '../common/DateTime';

const useStyles = makeStyles(() => ({
  row: {
    borderLeft: '4px solid',
  },
  rowSUCCESS: {
    borderLeftColor: green[500],
  },
  rowFAILED: {
    borderLeftColor: red[500],
  },
  rowSKIPPED: {
    borderLeftColor: grey[500],
  },
  dateCol: {
    minWidth: '12em',
  },
  numericCol: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
  numericZero: {
    color: grey[500],
  },
  label: {
    fontWeight: 'normal',
    fontSize: 12,
  },
  messageCol: {
    fontSize: 12,
    wordBreak: 'break-word',
  },
  lighter: {
    color: grey[500],
    fontWeight: 'bold',
  },
}));

export const MoodleIngestionRowHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Processed</TableCell>
      <TableCell>File</TableCell>
      <TableCell>Students</TableCell>
      <TableCell>Alerts</TableCell>
      <TableCell>Message</TableCell>
      <TableCell />
    </TableRow>
  </TableHead>
);

const MoodleIngestionRow = ({ row }) => {
  const classes = useStyles();
  const result = useSelector((state) => getReferenceDataDescription(state, 'MoodleIngestionResult', row.result));

  return (
    <TableRow>
      <TableCell className={cx(classes.dateCol, classes.row, classes[`row${row.result}`])}>
        <ul className="unstyled-list">
          <li>
            <DateTime value={row.created} />
          </li>
          <li className={classes.lighter}>{result}</li>
        </ul>
      </TableCell>
      <TableCell>
        <ul className="unstyled-list">
          <li>
            <Link href={row.file.requestLink}>{row.file.filename}</Link>
          </li>
          {!!row.fileDate && (
            <li className={classes.lighter}>
              <span className={classes.label}>Date: </span>
              {date(row.fileDate)}
            </li>
          )}
        </ul>
      </TableCell>
      <TableCell className={cx(classes.numericCol, { [classes.numericZero]: !row.studentCount })}>{prettyInt(row.studentCount)}</TableCell>
      <TableCell className={cx(classes.numericCol, { [classes.numericZero]: !row.alertCount })}>{prettyInt(row.alertCount)}</TableCell>
      <TableCell className={classes.messageCol}>{row.message}</TableCell>
    </TableRow>
  );
};

MoodleIngestionRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default MoodleIngestionRow;
