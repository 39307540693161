export default (state = { loading: false }, action) => {
  if (action.type === 'FETCH_ADVISING_APPOINTMENT_COUNT_SUCCESS') {
    return {
      ...state,
      [action.params.studentId]: action.response.advisingAppointmentCount,
    };
  }

  return state;
};

export const getAdvisingAppointmentCount = (state, studentId) => state[studentId] || 0;
