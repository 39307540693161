import * as PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core';
import { required } from 'redux-form-validators';
import FormError from './FormError';
import StudentFlagsField from './StudentFlagsField';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 220,
  },
}));

const SelectStudentFlagForm = ({ flags, error, handleSubmit }) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <FormError value={error} />
        <StudentFlagsField
          name="flagIds"
          selectProps={{ maxMenuHeight: 150 }}
          label={flags.length ? 'Add one or more flags' : 'Loading...'}
          flags={flags}
          validate={[required({ msg: 'Please add one or more flags' })]}
        />
      </div>
    </form>
  );
};

SelectStudentFlagForm.propTypes = {
  flags: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.any,
};

SelectStudentFlagForm.defaultProps = {
  error: undefined,
};

export default reduxForm({ form: 'selectStudentFlags' })(SelectStudentFlagForm);
