import { requestJSON, uploadFile } from './http';

const listForStudent = (id) => requestJSON(`/students/${id}/alerts`);

const closeOpenAlertsForStudent = (id) => requestJSON(`/students/${id}/alerts`, 'PUT', {});

const listCompletedForStudent = (id) => requestJSON(`/students/${id}/alerts/completed`);

const updateAlertTaskStatus = (alertId, taskId, completedStatus) =>
  requestJSON(`/alerts/${alertId}/tasks/${taskId}`, 'PUT', { completed: completedStatus });

const uploadCustomAlertsCsv = (file) => uploadFile('/alerts/upload-custom-alerts', 'POST', file);

const bulkCompleteAlerts = (studentIds, alertTypes, cutOffDate) =>
  requestJSON('/alerts/complete', 'POST', {
    studentIds,
    operation: { alertTypes, cutOffDate },
  });

const bulkCompleteAlertsSearch = (adviserId, studentSearchRequest, alertTypes, cutOffDate) =>
  requestJSON('/alerts/complete-by-search', 'POST', {
    adviserId,
    studentSearchRequest,
    operation: { alertTypes, cutOffDate },
  });

export default {
  listForStudent,
  closeOpenAlertsForStudent,
  updateAlertTaskStatus,
  listCompletedForStudent,
  uploadCustomAlertsCsv,
  bulkCompleteAlerts,
  bulkCompleteAlertsSearch,
};
