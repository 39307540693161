const currentStudentDetails = (state = { student: null, loading: false }, action) => {
  switch (action.type) {
    case 'FETCH_CURRENT_STUDENT_DETAILS_SUCCESS':
      return {
        student: action.response,
        loading: false,
      };
    case 'FETCH_CURRENT_STUDENT_DETAILS_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_CURRENT_STUDENT_DETAILS_FAILED':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const get = (state) => state.student;
export const getLoading = (state) => state.loading;

export default currentStudentDetails;
