import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Collapse, withStyles } from '@material-ui/core';
import EnrolmentRecord from '../EnrolmentRecord/index';
import CreditPointsProgress from './CreditPointsProgress';
import CourseHeader from './CourseHeader';
import Intermissions from './Intermissions';

class CourseCard extends React.Component {
  state = {
    expanded: false,
  };

  componentDidMount() {
    if (this.props.course.enrolmentStatus === 'ENROLLED') {
      this.setState({ expanded: true });
    }
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { course, classes, studentId } = this.props;

    const { expanded } = this.state;

    return (
      <Card className={classes.root}>
        <CourseHeader course={course} expanded={expanded} handleExpandClick={this.handleExpandClick} />

        <Collapse in={expanded}>
          <CardContent className={classes.content}>
            {course.allIntermissions && course.allIntermissions.length > 0 && (
              <div className={classes.pointsWrapper}>
                <Intermissions intermissions={course.allIntermissions} />
              </div>
            )}
            <div className={classes.pointsWrapper}>
              <CreditPointsProgress course={course} />
            </div>
            <div className={classes.courseInfoWrapper}>
              <EnrolmentRecord course={course} studentId={studentId} />
            </div>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

const styles = {
  root: {
    marginBottom: 15,
  },
  content: {
    padding: 0,
    position: 'relative',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  pointsWrapper: {
    padding: '16px 24px 0 24px',
  },
  courseInfoWrapper: {
    marginTop: 25,
  },
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
};

export default withStyles(styles)(CourseCard);
