import React, { Component } from 'react';
import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createInternalComment, fetchInternalComments } from '../actions/comments';
import Comments from '../components/comments/Comments';
import { getComments, getCommentsLoading } from '../reducers';
import AddCommentForm from '../components/forms/AddCommentForm';
import storage from '../services/api/storage';

class InternalCommentsAsAdviserContainer extends Component {
  componentDidMount() {
    const { student, fetchInternalCommentsFromServer } = this.props;
    fetchInternalCommentsFromServer(student.id);
  }

  onSubmitComment = (formValues) => {
    const { student, createInternalCommentOnServer } = this.props;
    return createInternalCommentOnServer(student.id, formValues);
  };

  uploadFile = (file) => {
    const { student } = this.props;
    return storage.uploadInternalAttachmentForStudentComment(file, student.id);
  }

  render() {
    const { comments, loading, student } = this.props;

    if (loading) return <CircularProgress />;

    return (
      <Grid container spacing={4}>
        <Grid item lg={5} md={6} xs={12}>
          <AddCommentForm
            onSubmit={this.onSubmitComment}
            studentId={student.id}
            supportAdvisingAppointment
            form={`NewInternalCommentAsAdviserForm${student.id}`}
            label={`Write a note about ${student.greetingName}`}
            preventNewMessages={!student.active}
            buttonColor="secondary"
            uploadFile={this.uploadFile}
          />
        </Grid>
        <Grid item lg={7} md={6} xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Comments
              comments={comments}
              studentId={student.id}
              forwardable
              searchable
              searchLabel="Search internal notes"
              formName={`NewInternalCommentAsAdviserForm${student.id}`}
              preventNewMessages={!student.active}
              noCommentsMessage={`No internal notes have been created for ${student.name} yet.`}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

InternalCommentsAsAdviserContainer.propTypes = {
  comments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  student: PropTypes.object.isRequired,
  fetchInternalCommentsFromServer: PropTypes.func.isRequired,
  createInternalCommentOnServer: PropTypes.func.isRequired,
};

const styles = {
  noNotesMessage: {
    marginTop: 20,
  },
};

const mapStateToProps = (state, { student }) => ({
  comments: getComments(state, student.id),
  loading: getCommentsLoading(state),
});
const mapDispatchToProps = {
  fetchInternalCommentsFromServer: fetchInternalComments,
  createInternalCommentOnServer: createInternalComment,
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InternalCommentsAsAdviserContainer));
