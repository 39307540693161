import { Checkbox, FormControlLabel, ListItem, withStyles } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { updateAlertTaskStatus } from '../../actions/alerts';

class Task extends React.Component {
  state = {
    checked: false,
  };

  componentDidMount() {
    const { task } = this.props;
    this.setState({ checked: task.completed });
  }

  onCheckTask = () => {
    const { task, onCheckTask } = this.props;
    const { checked } = this.state;
    this.setState({ checked: !checked });
    onCheckTask(task.id, !task.completed, () => {
      this.setState({ checked });
    });
  };

  render() {
    const { task, classes, activeStudent, loading } = this.props;

    const { checked } = this.state;

    return (
      <ListItem button className={cx(classes.listItem, { [classes.listItemChecked]: checked })}>
        <FormControlLabel
          control={<Checkbox onClick={this.onCheckTask} disabled={!activeStudent} checked={checked} className={classes.checkbox} />}
          label={task.text}
        />

        {loading && (
          <span className={classes.loading}>
            <LoadingIndicator size={15} />
          </span>
        )}
      </ListItem>
    );
  }
}

Task.propTypes = {
  onCheckTask: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  activeStudent: PropTypes.bool,
  loading: PropTypes.bool,
};

Task.defaultProps = {
  activeStudent: false,
  loading: false,
};

const styles = () => ({
  checkbox: {
    height: 36,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemChecked: {
    textDecoration: 'line-through',
  },
  loading: {
    position: 'absolute',
    left: 11,
    top: 25,
  },
});

const mapDispatchToProps = {
  updateTaskOnServer: updateAlertTaskStatus,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Task));
