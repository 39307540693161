import React, { Component } from 'react';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ContentCopy from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import cx from 'classnames';

class CopyToClipboardButton extends Component {
  state = {
    copied: false,
  };

  render() {
    const { text, classes, small, buttonColor } = this.props;
    const { copied } = this.state;

    return (
      <Tooltip id="copy" title={copied ? 'Copied!' : 'Copy to clipboard'}>
        <CopyToClipboard
          text={text}
          onCopy={() => {
            this.setState({ copied: true });
            setTimeout(() => this.setState({ copied: false }), 2000);
          }}
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            size="small"
            variant="text"
            className={cx(classes.button, { [classes.buttonSmall]: small })}
            style={buttonColor && { color: buttonColor }}
          >
            <ContentCopy className={classes.icon} />
          </Button>
        </CopyToClipboard>
      </Tooltip>
    );
  }
}

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  small: PropTypes.bool,
  buttonColor: PropTypes.string,
};

CopyToClipboardButton.defaultProps = {
  small: false,
  buttonColor: null,
};

const styles = {
  button: {
    minWidth: 'inherit',
    color: grey[700],
  },
  buttonSmall: {
    padding: '3px 7px',
    minHeight: 20,
  },
  icon: {
    fontSize: '18px',
  },
};

export default withStyles(styles)(CopyToClipboardButton);
