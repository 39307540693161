import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Slide, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const IntermissionCommentsDialog = ({ classes, intermission, onClose }) =>
  intermission ? (
    <Dialog onClose={onClose} aria-labelledby="special-considerations-dialog" open={intermission} TransitionComponent={Transition} maxWidth="md">
      <DialogTitle id="special-considerations-dialog-title">Intermission comments</DialogTitle>
      <DialogContent>
        <p className={classes.comments}>{intermission.comments}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

IntermissionCommentsDialog.propTypes = {
  intermission: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

IntermissionCommentsDialog.defaultProps = {
  intermission: null,
};

const styles = () => ({
  comments: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
});

export default withStyles(styles)(IntermissionCommentsDialog);
