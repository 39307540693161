import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchLockedJobs } from '../../actions/jobs';
import AdviserHeader from '../../components/advisers/AdviserHeader';
import { getLoggedInUser } from '../../reducers';
import ActivityLogWidget from './ActivityLogWidget';
import AdviserCalendlyWidget from './AdviserCalendlyWidget';
import AlertSummary from './AlertSummary';
import AnnouncementsWidget from './AnnouncementsWidget';

class AdviserHomePage extends Component {
  componentDidMount() {
    const { fetchAllLockedJobs } = this.props;
    fetchAllLockedJobs();
  }

  render() {
    const { classes, adviser } = this.props;
    return (
      <div className={classes.root}>
        <AdviserHeader adviser={adviser} />

        <div className={classes.content}>
          <AlertSummary adviser={adviser} />
          <AnnouncementsWidget />
          <Grid container spacing={2}>
            <Grid item xs={adviser.calendlyUrl ? 6 : 12}>
              <ActivityLogWidget adviserId={adviser.id} fixHeight={!!adviser.calendlyUrl} />
            </Grid>
            {adviser.calendlyUrl && (
              <Grid item xs={6}>
                <AdviserCalendlyWidget adviser={adviser} />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

AdviserHomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  adviser: PropTypes.object.isRequired,
  fetchAllLockedJobs: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  content: {
    margin: theme.spacing(4),
  },
});

const mapStateToProps = (state) => ({
  adviser: getLoggedInUser(state),
});

const mapDispatchToProps = {
  fetchAllLockedJobs: fetchLockedJobs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AdviserHomePage));
