import api from '../services/api';
import { asyncAction } from './actions';

export const fetchApplicationSettings = () => asyncAction(
  'APPLICATION_SETTINGS_FETCH',
  api.applicationSettings.getApplicationSettings(),
);

export const updateApplicationSettings = (settings) => asyncAction(
  'APPLICATION_SETTINGS_UPDATE',
  api.applicationSettings.updateApplicationSettings(settings),
);
