import advisers from './advisers';
import alerts from './alerts';
import announcements from './announcements';
import auditLogs from './audit-logs';
import comments from './comments';
import errors from './errors';
import referenceData from './referenceData';
import storage from './storage';
import students from './students';
import users from './users';
import messages from './messages';
import messageTemplates from './message-templates';
import applicationSettings from './application-settings';
import jobs from './jobs';
import flags from './flags';
import quickLinks from './quick-links';

export default {
  announcements,
  users,
  errors,
  referenceData,
  students,
  advisers,
  alerts,
  comments,
  auditLogs,
  messages,
  messageTemplates,
  quickLinks,
  storage,
  applicationSettings,
  jobs,
  flags,
};
