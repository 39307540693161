import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Alert from 'react-s-alert';
import { reset } from 'redux-form';
import api from '../../../services/api';
import BulkMessageStudentsForm from './BulkMessageStudentsForm';

const bulkMessageStudentsFormName = 'bulkMessageStudentsForm';

const BulkMessageStudents = ({
  allStudentsMatchingSearchSelected,
  students,
  adviser,
  onActionCompleted,
  studentTotalMatchCount,
  searchCriteria,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const stopSubmittingWithError = (error) => {
    setSubmitting(false);
    setErrorMessages(error.messages);
  };

  const toggleDialogState = () => setDialogOpen(!dialogOpen);

  const handleBulkMessage = (values) => {
    setSubmitting(true);
    const { content, attachments } = values;
    const studentIds = students.map((student) => student.id);
    let promise = null;
    if (allStudentsMatchingSearchSelected) {
      promise = api.comments.createBulkSharedSearch(adviser.id, searchCriteria, content, attachments);
    } else {
      promise = api.comments.createBulkShared(studentIds, content, attachments);
    }
    return promise
      .then(() => {
        setSubmitting(false);
        Alert.success('Student messages have been queued for processing');
        dispatch(reset(bulkMessageStudentsFormName));
        toggleDialogState();
        onActionCompleted();
      })
      .catch((error) => stopSubmittingWithError(error));
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={toggleDialogState} disabled={!enabled}>
        Bulk message
      </Button>

      <BulkMessageStudentsForm
        title={
          allStudentsMatchingSearchSelected
            ? `Emailing all ${studentTotalMatchCount} students that match the search criteria`
            : `Emailing ${students.length} students`
        }
        formName={bulkMessageStudentsFormName}
        open={dialogOpen}
        onCancel={toggleDialogState}
        onSubmit={handleBulkMessage}
        students={students}
        allStudentsMatchingSearchSelected={allStudentsMatchingSearchSelected}
        submitting={submitting}
        errorMessages={errorMessages}
        maxWidth="lg"
        searchCriteria={searchCriteria}
      />
    </>
  );
};

BulkMessageStudents.propTypes = {
  students: PropTypes.array.isRequired,
  searchCriteria: PropTypes.object.isRequired,
  adviser: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  allStudentsMatchingSearchSelected: PropTypes.bool.isRequired,
  studentTotalMatchCount: PropTypes.number.isRequired,
  onActionCompleted: PropTypes.func.isRequired,
};

export default BulkMessageStudents;
