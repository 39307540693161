import _ from 'lodash';

const initialState = {};
let newState;

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_ENROLMENT_UNIT':
      const selectedUnits = _.cloneDeep(_.get(state, `${action.params.studentId}.${action.params.courseCode}.selectedUnits`, {}));
      if (action.params.selected) {
        selectedUnits[action.params.unitId] = true;
      } else {
        delete selectedUnits[action.params.unitId];
      }
      newState = { ...state };
      _.set(newState, `${action.params.studentId}.${action.params.courseCode}.selectedUnits`, selectedUnits);
      return newState;
    case 'UNSELECT_ALL_ENROLMENT_UNITS':
      newState = { ...state };
      _.set(newState, `${action.params.studentId}.${action.params.courseCode}.selectedUnits`, {});
      return newState;
    default:
      return state;
  }
};

export const getSelectedEnrolmentUnits = (state, studentId, courseCode) => _.get(state, `${studentId}.${courseCode}.selectedUnits`, {});
