import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const LabelledCheckbox = ({ input, label }) => (
  <FormControlLabel control={<Checkbox checked={!!input.value} onChange={input.onChange} />} label={label} />
);

LabelledCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default LabelledCheckbox;
