import { schema } from 'normalizr';

const user = new schema.Entity('users');
const arrayOfUsers = new schema.Array(user);

const student = new schema.Entity('students');
const arrayOfStudents = new schema.Array(student);

const alert = new schema.Entity('alerts');
const arrayOfAlerts = new schema.Array(alert);

const announcement = new schema.Entity('announcements');
const arrayOfAnnouncements = new schema.Array(announcement);

const messageTemplate = new schema.Entity('messageTemplates');
const arrayOfMessageTemplates = new schema.Array(messageTemplate);

export default {
  user,
  arrayOfUsers,
  student,
  arrayOfStudents,
  alert,
  arrayOfAlerts,
  announcement,
  arrayOfAnnouncements,
  messageTemplate,
  arrayOfMessageTemplates,
};
