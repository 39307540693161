import * as PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { makeStyles, MenuItem } from '@material-ui/core';
import { length, required } from 'redux-form-validators';
import { useSelector } from 'react-redux';
import FormError from './FormError';
import { TextField } from '../wrappers/material';
import { getReferenceData } from '../../reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 220,
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(),
    },
  },
}));

const CreateFlagForm = ({ serverError, handleSubmit }) => {
  const classes = useStyles();
  const classifications = useSelector((state) => getReferenceData(state, 'JuvoClassification'));
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <FormError value={serverError} />
        <Field
          name="text"
          margin="normal"
          component={TextField}
          placeholder="Flag text"
          label="Flag text"
          validate={[required(), length({ max: 50 })]}
          fullWidth
        />
        <Field name="classification" component={TextField} select label="Classification" validate={[required()]} fullWidth>
          {classifications.map((classification) => (
            <MenuItem key={classification.id} value={classification.id}>
              {classification.description}
            </MenuItem>
          ))}
        </Field>
      </div>
    </form>
  );
};

CreateFlagForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  serverError: PropTypes.any,
};

CreateFlagForm.defaultProps = {
  serverError: undefined,
};

export default reduxForm({ form: 'createFlag' })(CreateFlagForm);
