import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUser } from '../../actions/users';
import StudentsTable from '../../components/students/StudentsTable/StudentsTable';
import * as model from '../../model';
import { getUser } from '../../reducers';
import LeaveStatus from '../adviser/LeaveStatus';

class AdviserStudentsPage extends Component {
  componentDidMount() {
    this.props.fetchAdviser();
  }

  render() {
    const { adviser } = this.props;
    return (
      <div>
        {adviser ? (
          <>
            <LeaveStatus adviser={adviser} />
            <StudentsTable title={`Students for ${adviser.name}`} adviser={adviser} />
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

AdviserStudentsPage.propTypes = {
  adviser: model.user,
  fetchAdviser: PropTypes.func.isRequired,
};

AdviserStudentsPage.defaultProps = {
  adviser: undefined,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { adviserId },
    },
  },
) => ({
  adviser: getUser(state, adviserId),
});
const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { adviserId },
    },
  },
) => ({
  fetchAdviser: () => dispatch(fetchUser(adviserId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdviserStudentsPage);
