import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Table,
  TableCell,
  TableHead,
  Button,
  TableBody,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const SpecialConsiderationsDialog = ({ unitEnrolment, open, onClose }) => {
  if (unitEnrolment) {
    return (
      <Dialog onClose={onClose} aria-labelledby="special-considerations-dialog" open={open} TransitionComponent={Transition} maxWidth="md">
        <DialogTitle id="special-considerations-dialog-title">Special considerations for {unitEnrolment && unitEnrolment.code}</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Outcome</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unitEnrolment.specialConsiderations.map((sc) => (
                <TableRow key={sc.category}>
                  <TableCell>{sc.category}</TableCell>
                  <TableCell>{sc.outcome}</TableCell>
                  <TableCell>{sc.comments}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

SpecialConsiderationsDialog.propTypes = {
  unitEnrolment: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SpecialConsiderationsDialog.defaultProps = {
  unitEnrolment: null,
};

export default SpecialConsiderationsDialog;
