import { uploadFile } from './http';

// const getUploadUrl = (meta) => requestJSON('/storage/generate-upload-url', 'POST', meta);
// const getUploadUrlForCsv = (meta) => requestJSON('/storage/generate-upload-url/csv', 'POST', meta);
// may need to delete @PostMapping(path = "/storage/generate-upload-url/csv") in java land too

// const upload = (uploadUrl, file) => uploadToStorage(uploadUrl, file)
//   .then((a) => ({
//     filename: a.name.substring(a.name.lastIndexOf('/') + 1, a.name.length),
//     ...a,
//   }));

const uploadStudentIdentifier = (file) => uploadFile('/students/student-identifiers', 'POST', file);

const uploadCourseMapAttachmentForStudent = (file, studentId) => uploadFile(`/students/${studentId}/course-map`, 'POST', file);

const uploadInternalAttachmentForStudents = (file) => uploadFile('/students/internal/attachments', 'POST', file);

const uploadStaffProfileImage = (file, staffId) => uploadFile(`/staff/${staffId}/profile/image`, 'POST', file);

const uploadSharedAttachmentForStudentComment = (file, studentId) => uploadFile(`/students/${studentId}/comments/shared/attachments`, 'POST', file);

const uploadInternalAttachmentForStudentComment = (file, studentId) => uploadFile(`/students/${studentId}/comments/internal/attachments`, 'POST', file);

const uploadStudentGroupMessageAttachment = (file) => uploadFile('/students/bulk-message/attachments', 'POST', file);

export default {
  // getUploadUrl,
  // getUploadUrlForCsv,
  // upload,
  uploadStudentIdentifier,
  uploadCourseMapAttachmentForStudent,
  uploadInternalAttachmentForStudents,
  uploadStaffProfileImage,
  uploadSharedAttachmentForStudentComment,
  uploadInternalAttachmentForStudentComment,
  uploadStudentGroupMessageAttachment,
};
