import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Link, makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { string } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  csvFile: {
    backgroundColor: grey[200],
    padding: theme.spacing(1, 2),
    borderRadius: 3,
    fontFamily: 'monospace',
    fontSize: '1rem',
    color: grey[800],
    margin: theme.spacing(2),
  },
}));

const FlagCsvHelp = ({ className }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <Tooltip title="CSV format" className={className}>
        <IconButton key="help" aria-label="Help" color="inherit" onClick={() => setDialogOpen(true)}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog title="Flag CSV format" maxWidth="md" open={dialogOpen} onConfirm={() => setDialogOpen(false)}>
        <div>
          <div>
            <p>
              The format of the CSV for flags is <code>FLAG_TEXT,CLASSIFICATION,STUDENT_ID</code>. Each row represents a{' '}
              <strong>single student</strong> and flag combination.
            </p>
            <p>You can even have a CSV with more columns, as long as it contains these 3 column names.</p>
            <p>
              <ul>
                <li>
                  <code>FLAG_TEXT</code>: Display text for the flag.
                </li>
                <li>
                  <code>CLASSIFICATION</code>: One of <code>RESPONSE</code>, <code>ACTION</code>, <code>INFORMATION</code>, <code>CELEBRATION</code>.
                </li>
                <li>
                  <code>STUDENT_ID</code>: Single student ID.
                </li>
              </ul>
            </p>
            <p>See example rows below.</p>
          </div>
          <div className={classes.csvFile}>
            <ul className="unstyled-list">
              <li>FLAG_TEXT,CLASSIFICATION,STUDENT_ID</li>
              <li>Sample action flag,ACTION,12345678</li>
              <li>Sample action flag,ACTION,87654321</li>
              <li>Sample response flag,RESPONSE,11223344</li>
            </ul>
          </div>
          <div>
            <p>
              To start quickly you can download a{' '}
              <Link className={classes.downloadLink} href="/api/storage?name=csv/examples/example-student-flags.csv" rel="noopener noreferrer">
                sample CSV
              </Link>
              .
            </p>
          </div>
        </div>
      </ConfirmationDialog>
    </>
  );
};

FlagCsvHelp.propTypes = {
  className: string,
};

FlagCsvHelp.defaultProps = {
  className: '',
};

export default FlagCsvHelp;
