import React from 'react';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core';
import { BeachAccess as BeachAccessIcon, LocationOn as LocationIcon, People as StudentsIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LeaveStatus from '../../pages/adviser/LeaveStatus';
import JuvoTooltip from '../common/JuvoTooltip';
import AdviserAlertBreakdown from './AdviserAlertBreakdown';

const AdviserStats = ({ adviser, classes, link }) => (
  <div>
    <JuvoTooltip title="Current student allocation" placement="bottom">
      <Link to={link}>
        <div className={classes.count}>
          <StudentsIcon className={classes.icon} />
          <strong className={classes.studentCount}>{adviser.advisingStudentCount}</strong> {adviser.studentCap && ` / ${adviser.studentCap}`}
        </div>
      </Link>
    </JuvoTooltip>

    {adviser.tempAdvisingStudentCount > 0 && (
      <JuvoTooltip title="Current temp student allocation" placement="bottom">
        <div className={classes.count}>
          <BeachAccessIcon className={classes.icon} />
          {adviser.tempAdvisingStudentCount}
        </div>
      </JuvoTooltip>
    )}

    <div className={classes.count}>
      <LocationIcon className={classes.icon} />
      {adviser.campusLocation}
    </div>
    <div className={classes.leaveWrapper}>
      <LeaveStatus adviser={adviser} />
      <AdviserAlertBreakdown adviserId={adviser.id} link={link} />
    </div>
  </div>
);

AdviserStats.propTypes = {
  adviser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  link: PropTypes.string,
};

AdviserStats.defaultProps = {
  link: '/adviser/students?resetFilters=true',
};

const styles = {
  count: {
    display: 'inline-block',
    color: grey[800],
    fontSize: '1.2em',
    fontWeight: 300,
    paddingBottom: 10,
    marginRight: 20,
  },
  studentCount: {
    color: grey[900],
    fontSize: '1.5em',
    position: 'relative',
    top: 3,
  },
  icon: {
    marginBottom: -6,
    marginRight: 6,
    color: grey[600],
  },
  leaveWrapper: {
    position: 'relative',
    display: 'flex',

    left: -14,
  },
};

export default withStyles(styles)(AdviserStats);
