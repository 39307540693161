import { Button, makeStyles, Tooltip } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import bytes from 'bytes';
import { upperCase } from 'lodash';

const useStyles = makeStyles((theme) => ({
  buttonPadded: {
    margin: theme.spacing(),
  },
  iconRIGHT: {
    marginLeft: theme.spacing(),
  },
  iconLEFT: {
    marginRight: theme.spacing(),
  },
}));

const Upload = ({ handleUpload, accept, label, uploading, padded, multiple, maxSize, tooltip, disabled, color, variant, icon, iconPlacement }) => {
  const classes = useStyles();

  const buttonIcon = (placement) => {
    if (iconPlacement !== placement) {
      return undefined;
    }
    if (icon) return icon;
    return <AttachFileIcon className={classes[`icon${upperCase(placement)}`]} />;
  };

  const button = (
    <Button variant={variant} color={color} className={padded ? classes.buttonPadded : undefined} disabled={uploading}>
      {buttonIcon('left')}
      {uploading ? 'Uploading...' : label}
      {buttonIcon('right')}
    </Button>
  );

  const [attachError, updateAttachError] = useState(false);

  return (
    <div style={{ display: 'inline' }}>
      <Dropzone
        accept={accept}
        multiple={multiple}
        style={{ display: 'inline' }}
        onDrop={(files) => {
          updateAttachError(false);
          return handleUpload(files);
        }}
        disabled={disabled || uploading}
        maxSize={maxSize}
        onDropRejected={() => updateAttachError(true)}
      >
        {tooltip ? (
          <Tooltip title={tooltip} disableFocusListener disableTouchListener disableHoverListener={disabled}>
            {button}
          </Tooltip>
        ) : (
          button
        )}
      </Dropzone>
      {attachError && <FormHelperText error>Failed to attach file[s], max size permitted: {bytes(maxSize)}</FormHelperText>}
    </div>
  );
};

Upload.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  uploading: PropTypes.bool,
  multiple: PropTypes.bool,
  padded: PropTypes.bool,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.object,
  iconPlacement: PropTypes.string,
};

Upload.defaultProps = {
  uploading: false,
  multiple: false,
  padded: false,
  tooltip: undefined,
  maxSize: undefined,
  label: 'Upload',
  disabled: false,
  color: 'default',
  variant: 'contained',
  icon: null,
  iconPlacement: 'right',
};

export default Upload;
