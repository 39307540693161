import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isAdmin as isAdminReducer, isAdviser as isAdviserReducer, isStaff as isStaffReducer } from '../reducers';

const RoleRedirect = ({ isAdmin, isAdviser, isStaff }) => {
  if (isAdviser) return <Redirect to="/adviser" />;
  if (isAdmin) return <Redirect to="/admin" />;
  if (isStaff) return <Redirect to="/referrals" />;
  return <Redirect to="/student" />;
};

RoleRedirect.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isAdviser: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isAdmin: isAdminReducer(state),
  isAdviser: isAdviserReducer(state),
  isStaff: isStaffReducer(state),
}))(RoleRedirect);
