import { normalize } from 'normalizr';
import schemas from '../schemas';
import api from '../services/api';
import { asyncAction } from './actions';

export const fetchMessageTemplateSummaries = () => asyncAction(
  'MESSAGE_TEMPLATE_SUMMARIES_FETCH',
  api.messageTemplates.listSummaries(),
);

// ADMIN only below
export const fetchMessageTemplate = (id) => asyncAction(
  'MESSAGE_TEMPLATE_FETCH',
  api.messageTemplates.get(id),
  {
    responseTransformer: (messageTemplates) => normalize(messageTemplates, schemas.messageTemplate),
  },
);
export const createMessageTemplate = (messageTemplate) => asyncAction(
  'MESSAGE_TEMPLATE_CREATE',
  api.messageTemplates.create(messageTemplate),
  {
    responseTransformer: (messageTemplates) => normalize(messageTemplates, schemas.messageTemplate),
  },
);
export const updateMessageTemplate = (id, messageTemplate) => asyncAction(
  'MESSAGE_TEMPLATE_UPDATE',
  api.messageTemplates.update(id, messageTemplate),
  {
    responseTransformer: (messageTemplates) => normalize(messageTemplates, schemas.messageTemplate),
  },
);
export const fetchMessageTemplates = () => asyncAction(
  'MESSAGE_TEMPLATES_FETCH',
  api.messageTemplates.listAll(),
  {
    responseTransformer: (messageTemplates) => normalize(messageTemplates, schemas.arrayOfMessageTemplates),
  },
);
export const deleteMessageTemplate = (id) => asyncAction(
  'MESSAGE_TEMPLATE_DELETE',
  api.messageTemplates.deleteItem(id),
  {
    params: { id },
  },
);
