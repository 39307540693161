import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Equalizer as ReportIcon, TableChart } from '@material-ui/icons';
import React, { useState } from 'react';
import FormDialog from '../forms/FormDialog';
import AlertCountForm from './AlertCountForm';
import Alert from 'react-s-alert';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    margin: theme.spacing(1),
  },
  header: {
    minHeight: theme.spacing(10),
  },
}));

const AdminReports = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const downloadAlertCounts = ({ fromDate, toDate }) => {
    setDialogOpen(false);
    window.open(`/api/reports/alert-counts/${fromDate}/${toDate}`);
    Alert.success('Alert count csv downloading');
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h5">Reports</Typography>
      </div>
      <Grid container justify="flex-end">
        <Button className={classes.downloadButton} component="a" startIcon={<TableChart />} variant="outlined" onClick={() => setDialogOpen(true)}>
          Alert counts
        </Button>
        <Button
          className={classes.downloadButton}
          component="a"
          startIcon={<ReportIcon />}
          variant="outlined"
          href="/api/storage?name=reports/student-adviser-report.csv"
          rel="noopener noreferrer"
          download
        >
          Students per adviser
        </Button>
      </Grid>

      <FormDialog
        title="Select date range for alert counts"
        submitButtonText="Download"
        formComponent={AlertCountForm}
        formName="alertCountForm"
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onSubmit={downloadAlertCounts}
      />
    </div>
  );
};

export default AdminReports;
