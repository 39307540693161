import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import * as model from '../../model';

const CalendlyAppointmentWidget = ({ url, student }) => {
  useEffect(() => {
    const fullUrl = student ? `${url}?name=${student.name}&email=${student.email}` : `${url}`;
    /* eslint-disable no-undef */
    Calendly.initInlineWidget({
      url: fullUrl,
      parentElement: document.querySelector('.calendly-inline-widget'),
    });
  });

  return (
    <div>
      <div className="calendly-inline-widget" data-auto-load="false" style={{ minWidth: 320, height: 810 }} />
    </div>
  );
};

CalendlyAppointmentWidget.propTypes = {
  url: PropTypes.string.isRequired,
  student: model.user,
};

CalendlyAppointmentWidget.defaultProps = {
  student: null,
};

export default CalendlyAppointmentWidget;
