import { Grid, withStyles } from '@material-ui/core';
import { keyBy } from 'lodash/collection';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAllAdvisers } from '../../actions/advisers';
import { fetchLockedJobs } from '../../actions/jobs';
import AdviserCard from '../../components/advisers/AdviserCard';
import AdviserResourcesButton from '../../components/common/AdviserResourcesButton';
import { getAllAdvisers } from '../../reducers';
import api from '../../services/api';

class AdvisersPage extends Component {
  static propTypes = {
    fetchAdvisers: PropTypes.func.isRequired,
    fetchAllLockedJobs: PropTypes.func.isRequired,
    advisers: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    alertSummaries: {},
  };

  componentDidMount() {
    const { fetchAdvisers, fetchAllLockedJobs } = this.props;
    fetchAdvisers();
    fetchAllLockedJobs();
    api.advisers.alertSummaries().then((result) => this.setState({ alertSummaries: keyBy(result, 'id') }));
  }

  render = () => {
    const { advisers, classes } = this.props;
    const { alertSummaries } = this.state;
    return (
      <>
        <div className={classes.links}>
          <AdviserResourcesButton />
        </div>

        <Grid container spacing={1}>
          {advisers.map((adviser) => (
            <Grid item md={12} lg={6} key={adviser.id} className={classes.gridItem}>
              <AdviserCard adviser={adviser} alertSummary={alertSummaries[adviser.id]} />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };
}

const styles = {
  gridItem: {
    width: '100%',
    '@media (min-width: 1720px)': {
      flexGrow: 0,
      maxWidth: '33.3333%',
      flexBasis: '33.3333%',
    },
  },
  links: {
    float: 'right',
  },
};

const mapStateToProps = (state) => ({
  advisers: getAllAdvisers(state),
});

const mapDispatchToProps = {
  fetchAdvisers: fetchAllAdvisers,
  fetchAllLockedJobs: fetchLockedJobs,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdvisersPage),
);
