import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { filter } from 'lodash';
import FlipMove from 'react-flip-move';

const StackedProgressBar = ({ classes, bars, markerPercentage, markerText }) => (
  <div className={classes.root}>
    <FlipMove maintainContainerHeight appearAnimation="accordionHorizontal">
      {filter(bars, (bar) => bar.percentage > 0).map((bar) => (
        <span
          key={bar.title}
          className={classes.progress}
          style={{
            width: `${bar.percentage}%`,
            backgroundColor: bar.color,
          }}
        >
          {bar.label}
        </span>
      ))}
    </FlipMove>

    {markerPercentage > 0 && markerPercentage < 100 && (
      <span style={{ left: `${markerPercentage}%` }} className={classes.markerPercentage}>
        <span>{markerText}</span>
      </span>
    )}
  </div>
);

StackedProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  bars: PropTypes.array.isRequired,
  markerPercentage: PropTypes.number,
  markerText: PropTypes.string,
};

StackedProgressBar.defaultProps = {
  markerPercentage: null,
  markerText: 'Required',
};

const styles = {
  root: {
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '11px',
    height: '18px',
    lineHeight: '1.182',
    margin: '4px 0',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    boxShadow: 'inset 0 1px 1px rgba(100, 100, 100, 0.1)',
    boxSizing: 'initial',
    '& span:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '& span:last-child': {
      borderRadius: '0 4px 4px 0',
    },
  },
  markerPercentage: {
    position: 'absolute',
    left: 10,
    height: 27,
    width: 2,
    backgroundColor: '#000',
    '& span': {
      position: 'relative',
      top: 30,
      color: '#000',
      display: 'inline-block',
      width: 500,
      textAlign: 'left',
    },
  },
  progress: {
    backgroundColor: '#2bc253',
    display: 'inline-block',
    float: 'left',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: 'auto',
    fontWeight: 'bold',
    color: '#fff',
    paddingTop: 4,
    paddingBottom: 4,
  },
};

export default withStyles(styles)(StackedProgressBar);
