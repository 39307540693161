import PropTypes from 'prop-types';
import React from 'react';
import CoreLayout from '../../components/layouts/CoreLayout';

const StudentLayout = ({ children }) => (
  <CoreLayout>
    { children }
  </CoreLayout>
);

StudentLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default StudentLayout;
