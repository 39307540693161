import { Button, Dialog, DialogActions, DialogContent, makeStyles, Slide } from '@material-ui/core';
import PropTypes, { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';
import cx from 'classnames';
import DialogTitleWithClose from './DialogTitleWithClose';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="left" {...props} />);

const useStyles = makeStyles({
  allowOverflow: {
    overflowY: 'visible',
  },
});

const ActionDialog = ({ title, open, cancelButtonText, submitButtonText, onCancel, onSubmit, submitting, maxWidth, allowOverflow, children }) => {
  const classes = useStyles();
  const actions = (
    <>
      <Button variant="text" onClick={onCancel}>
        {cancelButtonText}
      </Button>
      <Button variant="text" color="primary" disabled={submitting} onClick={onSubmit}>
        {submitButtonText}
      </Button>
    </>
  );

  return (
    <Dialog
      classes={{ paper: allowOverflow ? classes.allowOverflow : '' }}
      title={title}
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={Transition}
    >
      <DialogTitleWithClose title={title} onClose={onCancel} />
      <DialogContent className={cx({ [classes.allowOverflow]: allowOverflow })}>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

ActionDialog.propTypes = {
  title: PropTypes.string.isRequired,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  maxWidth: PropTypes.string,
  submitting: PropTypes.bool,
  allowOverflow: PropTypes.bool,
};

ActionDialog.defaultProps = {
  open: false,
  cancelButtonText: 'Cancel',
  submitButtonText: 'Submit',
  maxWidth: 'sm',
  submitting: false,
  allowOverflow: false,
};

export default ActionDialog;
