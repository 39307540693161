import * as PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required, url } from 'redux-form-validators';
import { TextField } from '../wrappers/material';

const QuickLinkForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="name"
      margin="normal"
      component={TextField}
      placeholder="E.g, University Handbook"
      label="Name"
      validate={required()}
      style={{ marginBottom: 20 }}
      fullWidth
    />
    <Field
      name="url"
      margin="normal"
      component={TextField}
      placeholder="E.g, https://handbook.monash.edu/"
      label="URL"
      validate={(required(), url())}
      style={{ marginBottom: 20 }}
      fullWidth
    />
  </form>
);

QuickLinkForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'quickLinkForm' })(QuickLinkForm);
