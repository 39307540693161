import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdviserHomePage from '../pages/adviser/AdviserHomePage';
import AdviserMyStudentsPage from '../pages/adviser/AdviserMyStudentsPage';
import AdviserStudentPage from '../pages/adviser/AdviserStudentPage';
import AdviserLayout from '../pages/adviser/AdviserLayout';
import AnnouncementsPage from '../pages/AnnouncementsPage';
import AsyncComponent from './AsyncComponent';
import { hasAnyRole, loadRefData, loginRequired } from './hooks';
import GroupMessagePage from '../pages/GroupMessagePage';
import BulkInternalNotesPage from '../pages/BulkInternalNotesPage';

export default () => (
  <AsyncComponent asyncActions={[loginRequired, hasAnyRole('ADVISER', 'ADMIN'), loadRefData]}>
    <AdviserLayout>
      <Switch>
        <Route exact path="/adviser/home" component={AdviserHomePage} />
        <Route exact path="/adviser/students" component={AdviserMyStudentsPage} />
        <Route exact path="/adviser/students/:id" component={AdviserStudentPage} />
        <Route exact path="/adviser/students/:id/:page" component={AdviserStudentPage} />
        <Route exact path="/adviser/announcements" render={() => <AnnouncementsPage padded />} />
        <Route exact path="/adviser/group-message" render={() => <GroupMessagePage />} />
        <Route exact path="/adviser/bulk-internal-notes" render={() => <BulkInternalNotesPage />} />
        <Redirect exact from="/adviser" to="/adviser/home" />
      </Switch>
    </AdviserLayout>
  </AsyncComponent>
);
