import moment from 'moment';
import React from 'react';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import ChatIcon from '@material-ui/icons/Chat';
import IntermissionCommentsDialog from './IntermissionCommentDialog';

const compareByDateOnly = (dateOnlyString) => new Date(dateOnlyString).getMilliseconds();

class Intermissions extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    intermissions: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedIntermission: null,
    };
  }

  render() {
    const { classes, intermissions } = this.props;
    return (
      <div className={classes.container}>
        <Typography className={classes.title}>Intermission</Typography>
        <ul className={classes.intermissionList}>
          {orderBy(intermissions, [compareByDateOnly], ['desc']).map((intermission) => (
            <li key={`${intermission.startDate}-${intermission.endDate}`}>
              <IconButton
                className={classes.commentsButton}
                color="primary"
                onClick={() => this.setState({ selectedIntermission: intermission })}
                disabled={!intermission.comments}
              >
                <ChatIcon />
              </IconButton>
              {`${moment(intermission.startDate).format('D MMM YYYY')} - ${moment(intermission.endDate).format('D MMM YYYY')}`}
            </li>
          ))}
        </ul>
        <IntermissionCommentsDialog intermission={this.state.selectedIntermission} onClose={() => this.setState({ selectedIntermission: null })} />
      </div>
    );
  }
}

const styles = () => ({
  title: {
    color: '#424242',
    fontWeight: 700,
    paddingRight: 10,
    marginBottom: 10,
  },
  container: {
    display: 'flex',
  },
  intermissionList: {
    marginTop: 0,
    listStyle: 'none',
  },
  commentsButton: {
    padding: 0,
    marginRight: 5,
  },
});

export default withStyles(styles)(Intermissions);
