import React, { useState } from 'react';
import Alert from 'react-s-alert';
import capitalize from 'lodash/capitalize';
import { Chip, makeStyles } from '@material-ui/core';
import { Flag as FlagIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import flagsApi from '../../services/api/flags';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { classificationColour } from '../common/Classifications';

const useStyles = makeStyles((theme) => ({
  studentFlag: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    '& .MuiChip-deleteIcon': {
      zIndex: 1,
    },
  },
  studentFlagResponse: {
    backgroundColor: classificationColour('RESPONSE'),
    '&:focus': {
      backgroundColor: classificationColour('RESPONSE', true),
    },
  },
  studentFlagAction: {
    backgroundColor: classificationColour('ACTION'),
    '&:focus': {
      backgroundColor: classificationColour('ACTION', true),
    },
  },
  studentFlagInformation: {
    backgroundColor: classificationColour('INFORMATION'),
    '&:focus': {
      backgroundColor: classificationColour('INFORMATION', true),
    },
  },
  studentFlagCelebration: {
    backgroundColor: classificationColour('CELEBRATION'),
    '&:focus': {
      backgroundColor: classificationColour('CELEBRATION', true),
    },
  },
}));

const Flag = ({ flag, studentId, afterDelete }) => {
  const classes = useStyles();

  const [deleted, setDeleted] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation();
    setDeleted(true);
    setDialogOpen(false);

    flagsApi
      .deleteFlagFromStudent(studentId, flag.id)
      .then(() => {
        Alert.success('Flag deleted');
        afterDelete(flag);
      })
      .catch(() => {
        Alert.error('Flag was not deleted. Please try again or contact support');
        setDeleted(false);
      });
  };

  const onClickDelete = (e) => {
    e.stopPropagation();
    setDialogOpen(true);
  };

  const onCancel = (e) => {
    e.stopPropagation();
    setDialogOpen(false);
  };

  return (
    <>
      {!deleted && (
        <Chip
          key={flag.id}
          label={flag.text}
          icon={<FlagIcon />}
          color="primary"
          className={classes.studentFlag}
          onDelete={onClickDelete}
          classes={{ colorPrimary: classes[`studentFlag${capitalize(flag.classification)}`] }}
        />
      )}

      <ConfirmationDialog content="Are you sure you would like to delete this flag?" open={dialogOpen} onCancel={onCancel} onConfirm={handleDelete} />
    </>
  );
};

Flag.propTypes = {
  flag: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  afterDelete: PropTypes.func,
};

Flag.defaultProps = {
  afterDelete: () => {},
};

export default Flag;
