/* eslint-disable react/no-array-index-key */
import React from 'react';
import Alert from 'react-s-alert';
import commentsApi from '../services/api/comments';
import GroupComments from '../components/comments/GroupComments';

const BulkInternalNotesPage = () => {
  const submitBulkInternalNotes = ({ studentCsv, content, attachments }) =>
    commentsApi.createBulkInternalByCsv(studentCsv, content, attachments).then(() => Alert.success('Internal notes queued'));

  return (
    <GroupComments
      onSubmit={submitBulkInternalNotes}
      countMessageSuffix="will have internal notes added"
      title="Bulk create internal notes"
      internal
    />
  );
};

export default BulkInternalNotesPage;
