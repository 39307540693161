import { Avatar, CircularProgress, Grid, IconButton, Link, Snackbar, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Close as CloseIcon, Help as AskMonashIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSharedCommentAsStudent, fetchSharedCommentsAsStudent } from '../actions/comments';
import Comments from '../components/comments/Comments';
import AddCommentForm from '../components/forms/AddCommentForm';
import { getComments, getCommentsLoading, getLoggedInUser } from '../reducers';
import storage from '../services/api/storage';

class SharedCommentsAsStudentContainer extends Component {
  state = {
    showAfterSendDialog: false,
  };

  componentDidMount() {
    const { fetchSharedCommentsAsStudentFromServer } = this.props;
    fetchSharedCommentsAsStudentFromServer();
  }

  onSubmitComment = (formValues) => {
    const { createSharedCommentAsStudentOnServer } = this.props;

    return createSharedCommentAsStudentOnServer(formValues).then(() => {
      this.setState({ showAfterSendDialog: true });
    });
  };

  uploadFile = (file) => {
    const { studentUser } = this.props;
    return storage.uploadSharedAttachmentForStudentComment(file, studentUser.personId);
  }

  onCloseSnackbar = () => this.setState({ showAfterSendDialog: false });

  render() {
    const { comments, loading, studentUser, classes } = this.props;
    const { showAfterSendDialog } = this.state;

    if (loading) return <CircularProgress />;

    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={5} md={6} xs={12}>
            <AddCommentForm
              onSubmit={this.onSubmitComment}
              studentId={studentUser.id}
              form={`NewSharedCommentAsStudentForm${studentUser.id}`}
              label="Write a message to your adviser"
              promptForMissingAttachments
              viewingAsStudent
              uploadFile={this.uploadFile}
            />

            <div className={classes.askMonashContainer}>
              <Avatar className={classes.askMonashIcon}>
                <AskMonashIcon />
              </Avatar>
              <div className={classes.askMonashText}>
                Got a question about your fees, timetable, parking, visa or graduation?{' '}
                <Link className={classes.askMonashLink} href="https://www.monash.edu/connect/contact-us" target="_blank">
                  Monash Connect
                </Link>{' '}
                are experts in all this and more. You can search ask.monash for answers, make an enquiry or call them directly.
              </div>
            </div>
          </Grid>
          <Grid item lg={7} md={6} xs={12}>
            <Comments
              comments={comments}
              studentId={studentUser.personId} // Value passed directly into CommentsList - requires student number rather than the student user id.
              noCommentsMessage="You do not have any messages yet."
              viewingAsStudent
            />
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showAfterSendDialog}
          autoHideDuration={6000}
          onClose={this.onCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Your Education Success Adviser should be in contact with you within two business days.</span>}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.onCloseSnackbar}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}

SharedCommentsAsStudentContainer.propTypes = {
  comments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchSharedCommentsAsStudentFromServer: PropTypes.func.isRequired,
  createSharedCommentAsStudentOnServer: PropTypes.func.isRequired,
  studentUser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  askMonashContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  askMonashIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  askMonashText: {
    paddingLeft: theme.spacing(),
    marginTop: 3,
  },
  askMonashLink: {
    fontWeight: 700,
  },
  feedbackContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  feedbackText: {
    paddingLeft: theme.spacing(),
    color: grey[800],
    fontWeight: 300,
    marginTop: 3,
  },
});

export default connect(
  (state) => ({
    comments: getComments(state),
    loading: getCommentsLoading(state),
    studentUser: getLoggedInUser(state),
  }),
  {
    fetchSharedCommentsAsStudentFromServer: fetchSharedCommentsAsStudent,
    createSharedCommentAsStudentOnServer: createSharedCommentAsStudent,
  },
)(withStyles(styles)(SharedCommentsAsStudentContainer));
