import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import cx from 'classnames';
import { array, bool, object, string } from 'prop-types';
import React from 'react';
import Authorised from '../common/Authorised';
import AnnouncementRow from './AnnouncementRow';

const AnnouncementList = ({ announcements, classes, concise, noAnnouncementsMessage }) => {
  if (!announcements.length) {
    return <div className={classes.noData}>{noAnnouncementsMessage}</div>;
  }

  return (
    <>
      <Table className={cx({ [classes.concise]: concise })}>
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>Details</TableCell>
            {!concise && (
              <Authorised roles="ADMIN">
                <TableCell />
              </Authorised>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {announcements.map((announcement) => (
            <AnnouncementRow key={announcement.id} announcement={announcement} concise={concise} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

AnnouncementList.propTypes = {
  announcements: array.isRequired,
  classes: object.isRequired,
  concise: bool,
  noAnnouncementsMessage: string,
};

AnnouncementList.defaultProps = {
  concise: false,
  noAnnouncementsMessage: 'No announcements exist yet. Stay tuned!',
};

const styles = (theme) => ({
  concise: {
    fontSize: 10,
    '& td,th': {
      paddingBottom: '5px !important',
      fontSize: '0.75rem',
    },
    '& thead > tr': {
      height: '38px !important',
    },
  },
  noData: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

export default withStyles(styles)(AnnouncementList);
