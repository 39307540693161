import ExitToApp from '@material-ui/icons/ExitToApp';
import PropTypes from 'prop-types';
import React from 'react';
import CoreLayout from '../../components/layouts/CoreLayout';

const StaffLayout = ({ children }) => {
  const menuItems = [
    {
      path: '/logout',
      title: 'Logout',
      Icon: ExitToApp,
    },
  ];

  return (
    <CoreLayout menuItems={menuItems} padded>
      { children }
    </CoreLayout>
  );
};

StaffLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default StaffLayout;
