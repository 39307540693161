import { MenuItem } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { TextField } from '../../wrappers/material';
import AdviserOnLeave from '../../advisers/AdviserOnLeave';
import ErrorMessage from '../../common/ErrorMessage';
import ChipDisplay from '../../common/ChipDisplay';

const ReallocateStudentsForm = ({ students, advisers, handleSubmit, errorMessages }) => (
  <>
    <ChipDisplay
      label="Students"
      entries={students.map((student) => ({ key: student.id, label: student.name }))}
    />

    <form onSubmit={handleSubmit}>
      <ErrorMessage errorMessages={errorMessages} />

      <Field name="newAdviserId" component={TextField} select label="New adviser" validate={[required('New adviser required')]} fullWidth>
        {advisers &&
          advisers.map((adviser) => (
            <MenuItem key={adviser.id} value={adviser.id} disabled={adviser.onLeave}>
              {adviser.name}
              <AdviserOnLeave adviser={adviser} />
            </MenuItem>
          ))}
      </Field>
    </form>
  </>
);

ReallocateStudentsForm.defaultProps = {
  errorMessages: [],
};

ReallocateStudentsForm.propTypes = {
  students: PropTypes.array.isRequired,
  advisers: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
};

export default reduxForm({ form: 'reallocateStudents' })(ReallocateStudentsForm);
