import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { string } from 'prop-types';
import ConfirmationDialog from '../../common/ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  csvFile: {
    backgroundColor: grey[200],
    padding: theme.spacing(1, 2),
    borderRadius: 3,
    fontFamily: 'monospace',
    fontSize: '1rem',
    color: grey[800],
    margin: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

const StudentCsvHelp = ({ className }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <Tooltip title="CSV format" className={className}>
        <IconButton key="help" aria-label="Help" color="inherit" onClick={() => setDialogOpen(true)}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog title="Student CSV format" open={dialogOpen} onConfirm={() => setDialogOpen(false)}>
        <div>
          <div>
            <p>
              The format of the CSV for student group messages is simple: a single column containing student id with a <code>STUDENT_ID</code> column
              heading.
            </p>
            <p>
              You can even have a CSV with more columns, as long as it contains one with <code>STUDENT_ID</code> heading. Example below.
            </p>
          </div>
          <div className={classes.csvFile}>
            <ul className="unstyled-list">
              <li>STUDENT_ID</li>
              <li>27883019</li>
              <li>28833058</li>
              <li>12125415</li>
              <li>27883019</li>
            </ul>
          </div>
        </div>
      </ConfirmationDialog>
    </>
  );
};

StudentCsvHelp.propTypes = {
  className: string,
};

StudentCsvHelp.defaultProps = {
  className: '',
};

export default StudentCsvHelp;
