/* eslint-disable no-param-reassign */

import { isUndefined } from 'lodash/lang';

export const prependToArrayField = (obj, field, element) => {
  const existingArray = obj[field] || [];
  return {
    ...obj,
    [field]: [element, ...existingArray],
  };
};

export const appendToArrayField = (obj, field, element) => {
  const existingArray = obj[field] || [];
  return {
    ...obj,
    [field]: [...existingArray, element],
  };
};

export const valueOrPlaceholder = (obj, field, placeholder = '...') =>
  (obj && !isUndefined(obj[field]) ? obj[field] : placeholder);
