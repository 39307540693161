import {
  Checkbox,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { push, replace } from 'connected-react-router';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { getFormValues, initialize } from 'redux-form';
import { fetchAdviserStudents } from '../../../actions/students';
import * as model from '../../../model';
import {
  getReferenceData,
  getStudentsForAdviser,
  getStudentsForAdviserLoading,
  getStudentsMatchCount,
  isAdmin as isAdminReducer,
} from '../../../reducers';
import SelectedStudentsHeader from './SelectedStudentsHeader';
import StudentRow from './StudentRow';
import StudentSearchForm from './StudentSearchForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  formAndActions: {
    position: 'relative',
  },
  formWrapper: {},
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 2),
    margin: 0,
  },
  table: {
    minWidth: 700,
    backgroundColor: '#fff',
  },
  progress: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 40,
  },
  tableInfo: {
    margin: '10px 0px 10px 32px',
    color: grey[500],
  },
}));

const StudentsTable = ({ title, adviser }) => {
  const location = useLocation();
  const classes = useStyles();

  const [searchCriteria, setSearchCriteria] = useState({});
  const [allAdvisers, setAllAdvisers] = useState(false);
  const [allStudentsMatchingSearchSelected, setAllStudentsMatchingSearchSelected] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const students = useSelector((state) => getStudentsForAdviser(state, adviser.id));
  const studentTotalMatchCount = useSelector((state) => getStudentsMatchCount(state, adviser.id));
  const loading = useSelector((state) => getStudentsForAdviserLoading(state));
  const isAdmin = useSelector((state) => isAdminReducer(state));
  const currentSearch = useSelector((state) => getFormValues('studentSearch')(state) || {});
  const alertTypes = useSelector((state) => getReferenceData(state, 'AlertType'));

  const dispatch = useDispatch();

  const clearSelectedStudents = () => {
    setSelectedStudents([]);
    setAllStudentsMatchingSearchSelected(false);
  };

  const selectAllStudentsMatchingSearch = () => {
    setSelectedStudents(students);
    setAllStudentsMatchingSearchSelected(true);
  };

  const fetchStudents = (search) => {
    clearSelectedStudents();
    setAllAdvisers(!!search.all);
    setSearchCriteria(search);
    dispatch(fetchAdviserStudents(adviser.id, search));
  };

  const search = useCallback(debounce(fetchStudents, 300), []);

  const onClickSelectAll = (event, checked) => {
    if (checked) {
      setSelectedStudents(students);
      setAllStudentsMatchingSearchSelected(false);
      return;
    }
    clearSelectedStudents();
  };

  const onClickCheckbox = (event, student) => {
    const selectedStudentIdsIndex = selectedStudents.indexOf(student);
    let newSelected = [];
    if (selectedStudentIdsIndex > -1) {
      newSelected = selectedStudents.filter((e) => e.id !== student.id);
    } else {
      newSelected = [...selectedStudents, student];
    }
    setSelectedStudents(newSelected);
    setAllStudentsMatchingSearchSelected(false);
  };

  const onClickRow = (e, studentId) => {
    const userWantsToNavigateToStudent = e.target.closest('td').querySelector('input') === null;
    if (userWantsToNavigateToStudent) dispatch(push(`/adviser/students/${studentId}`));
  };

  const isSelected = (id) => selectedStudents.map((student) => student.id).indexOf(id) !== -1;

  const onBulkActionCompleted = () => {
    clearSelectedStudents();
    fetchStudents(currentSearch);
  };

  const resetStudentSearchForm = (initialValues) => dispatch(initialize('studentSearch', initialValues));

  const replacePath = (path) => dispatch(replace(path));

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (isEmpty(queryParams)) {
      return search(currentSearch);
    }

    const classification = get(queryParams, 'classification');
    const passedAlertTypes = get(queryParams, 'alertTypes');
    const resetFilters = get(queryParams, 'resetFilters');

    if (passedAlertTypes) {
      const criteria = { alertTypes: passedAlertTypes.split(',') };
      resetStudentSearchForm(criteria);
      replacePath(location.pathname);
      return search(criteria);
    }

    if (classification) {
      const filteredAlertTypes = filter(alertTypes, (at) => get(at, 'props.classification') === classification);
      const criteria = { alertTypes: filteredAlertTypes.map((at) => at.id) };
      resetStudentSearchForm(criteria);
      replacePath(location.pathname);
      return search(criteria);
    }

    if (resetFilters) {
      resetStudentSearchForm({});
      replacePath(location.pathname);
      return search({});
    }

    return null;
  }, []);

  const reachedStudentLimit = students.length === 100;
  const allowBulkMessage = isAdmin || !allAdvisers;
  return (
    <>
      <Paper className={classes.root}>
        <SelectedStudentsHeader
          title={title}
          selectedStudents={selectedStudents}
          fetchStudents={fetchStudents}
          searchCriteria={searchCriteria}
          adviser={adviser}
          studentTotalMatchCount={studentTotalMatchCount}
          selectAllStudentsMatchingSearch={selectAllStudentsMatchingSearch}
          allStudentsMatchingSearchSelected={allStudentsMatchingSearchSelected}
          allowBulkMessage={allowBulkMessage}
          onActionCompleted={onBulkActionCompleted}
        />
        <div className={classes.formAndActions}>
          <div className={classes.formWrapper}>
            <StudentSearchForm onChange={search} />

            {reachedStudentLimit && <div className={classes.tableInfo}>Showing first 100 of {studentTotalMatchCount} total matching students</div>}
          </div>
          <div className={classes.actions}>
            <div>
              <Tooltip title="Refresh search results" aria-label="Refresh search results">
                <IconButton onClick={() => fetchStudents(searchCriteria)} disabled={loading}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classes.progress}>
            {' '}
            <CircularProgress />
          </div>
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 48, padding: 0 }}>
                  <Checkbox
                    indeterminate={selectedStudents.length > 0 && selectedStudents.length < students.length}
                    checked={students && selectedStudents.length === students.length}
                    onChange={onClickSelectAll}
                    color="primary"
                  />
                </TableCell>
                <TableCell style={{ padding: '4px 56px 4px 0px' }}>Student</TableCell>
                <TableCell>Campus</TableCell>
                <TableCell>Course</TableCell>
                {allAdvisers && <TableCell>Adviser</TableCell>}
                <TableCell style={{ width: 20, padding: 0 }} />
                <TableCell style={{ padding: '0px 10px' }}>Alerts</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {students.length ? (
                students.map((student) => (
                  <StudentRow
                    key={student.id}
                    student={student}
                    isSelected={isSelected(student.id)}
                    onClickCheckbox={onClickCheckbox}
                    onClickRow={onClickRow}
                    currentAdviser={adviser}
                    showAdviser={allAdvisers}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <i>No students match your search</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Paper>

      {reachedStudentLimit && <div className={classes.tableInfo}>Showing first 100 of {studentTotalMatchCount} total matching students</div>}
    </>
  );
};

StudentsTable.propTypes = {
  adviser: model.user.isRequired,
  title: PropTypes.string,
};

StudentsTable.defaultProps = {
  title: 'Students',
};

export default StudentsTable;
