import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    fontWeight: 'bold',
    padding: '5px',
    borderRadius: '50%',
    display: 'inline-block',
    minWidth: 30,
    textAlign: 'center',
  },
}));

const Count = ({ count, color }) => {
  const classes = useStyles();
  return (
    <span className={classes.root} style={{ backgroundColor: color }}>
      {count}
    </span>
  );
};

Count.propTypes = {
  count: PropTypes.number.isRequired,
  color: PropTypes.string,
};

Count.defaultProps = {
  color: grey[900],
};

export default Count;
