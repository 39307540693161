import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Flag as FlagIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { includes } from 'lodash';
import Alert from 'react-s-alert';
import Flag from './Flag';
import flagsApi from '../../services/api/flags';
import FormDialog from '../forms/FormDialog';
import SelectStudentFlagForm from '../forms/SelectStudentFlagForm';

const useStyles = makeStyles((theme) => ({
  flags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  noFlags: {
    marginLeft: theme.spacing(-1.5),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: -2,
  },
  buttonSmall: {
    padding: theme.spacing(),
    minHeight: 20,
  },
}));

const StudentFlags = ({ student }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [allFlags, setAllFlags] = useState([]);

  const filterFlags = () => {
    const existingFlagIds = student.flags.map((f) => f.id);
    return allFlags.filter((flag) => !includes(existingFlagIds, flag.id));
  };

  const refreshFlags = (cancelObject) =>
    flagsApi.list().then((res) => {
      if (!cancelObject || !cancelObject.cancelled) {
        setAllFlags(res);
      }
    });

  useEffect(() => {
    // Enable promise to be cancelled so we don't get warnings about setting state after unmount
    const cancelObject = { cancelled: false };
    refreshFlags(cancelObject);
    return () => {
      cancelObject.cancelled = true;
    };
  }, []);

  const updateStudent = (updatedStudent) =>
    dispatch({
      type: 'FETCH_STUDENT_DETAILS_SUCCESS',
      response: updatedStudent,
    });

  const afterDeleteFlag = (deletedFlag) => {
    const updatedStudent = cloneDeep(student);
    remove(updatedStudent.flags, (flag) => flag.id === deletedFlag.id);
    updateStudent(updatedStudent);
  };

  const addFlags = ({ flagIds }) => {
    flagsApi
      .addFlags(student.id, flagIds)
      .then((res) => updateStudent(res))
      .then(() => setDialogOpen(false))
      .then(() => Alert.success('Flag added'))
      .catch((error) => {
        setDialogOpen(false);
        refreshFlags();
        return Alert.error(error.message);
      });
  };

  const flags = filterFlags();
  return (
    <div className={classes.flags}>
      {student.flags.map((flag) => (
        <Flag studentId={student.id} key={flag.id} flag={flag} afterDelete={afterDeleteFlag} />
      ))}
      <div className={classes.buttonContainer}>
        <Tooltip title={flags.length ? 'Add flag' : 'No new flags to add'}>
          <div>
            <IconButton
              className={cx(classes.buttonSmall, { [classes.noFlags]: !student.flags.length })}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setDialogOpen(true);
              }}
              disabled={!flags.length}
            >
              <FlagIcon />
            </IconButton>
          </div>
        </Tooltip>
        {/* eslint-disable-next-line */}
        <div onKeyPress={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
          <div>
            <FormDialog
              title="Add flags"
              submitButtonText="Add flags"
              formComponent={SelectStudentFlagForm}
              formName="selectStudentFlags"
              open={dialogOpen}
              onCancel={() => setDialogOpen(false)}
              flags={flags}
              onSubmit={addFlags}
              student={student}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StudentFlags.propTypes = {
  student: PropTypes.object.isRequired,
};

export default StudentFlags;
