import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StaffLayout from '../pages/admin/StaffLayout';
import AsyncComponent from './AsyncComponent';
import { hasAnyRole, loadRefData, loginRequired } from './hooks';
import MyProfilePage from '../pages/MyProfilePage';

export default () => (
  <AsyncComponent asyncActions={[loginRequired, hasAnyRole('ADVISER', 'ADMIN'), loadRefData]}>
    <StaffLayout>
      <Switch>
        <Route exact path="/my-profile" component={MyProfilePage} />
      </Switch>
    </StaffLayout>
  </AsyncComponent>
);
