import { flatMap } from 'lodash/collection';
import { join } from 'lodash/array';

export const msOffice = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'];
export const pdf = ['pdf'];
export const image = ['gif', 'jpe', 'jpeg', 'jpg', 'tif', 'tiff', 'ico', 'png', 'bmp'];

export const extensionsString = (...arrays) => {
  const extensions = flatMap(arrays)
    .map((ext) => `.${ext}`);
  return join(extensions, ',');
};
