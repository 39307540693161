import { CircularProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import get from 'lodash/get';
import unset from 'lodash/unset';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import { createSharedComment, fetchSharedComments } from '../actions/comments';
import Comments from '../components/comments/Comments';
import { getComments, getCommentsLoading, getForwardedComment, getLoggedInUserId } from '../reducers';
import AddCommentForm from '../components/forms/AddCommentForm';
import { loadUserState, removeState, saveUserState } from '../util/localStorage';
import storage from '../services/api/storage';

const commentFormName = (student) => `NewSharedCommentAsAdviserForm${student.id}`;

class SharedCommentsAsAdviserContainer extends Component {
  state = {
    savedDraft: null,
  };

  componentDidMount() {
    const { student, fetchSharedCommentsFromServer, forwardedComment, removeForwardedComment } = this.props;
    fetchSharedCommentsFromServer(student.id);

    const formName = commentFormName(student);
    if (forwardedComment) {
      this.copyToForm(formName, forwardedComment);
      removeForwardedComment();
    }

    this.loadDraftFromLocalStorage();
  }

  copyToForm = (formName, source) => {
    const { changeForm } = this.props;
    if (source) {
      changeForm(formName, 'content', source.content);
      changeForm(formName, 'attachments', source.attachments);
      changeForm(formName, 'additionalRecipient', source.additionalRecipient);
      changeForm(formName, 'advisingAppointment', source.advisingAppointment);
    }
  };

  loadDraftFromLocalStorage = () => {
    const { student, forwardedComment, currentUserId, formValues } = this.props;
    const formName = commentFormName(student);
    if (!forwardedComment && !formValues.content) {
      const localStorageState = loadUserState(currentUserId);

      if (localStorageState) {
        const savedDraft = get(localStorageState, `forms.${formName}`);
        if (savedDraft) {
          this.copyToForm(formName, savedDraft);
          this.setState({ savedDraft });
        }
      } else {
        removeState();
      }
    }
  };

  removeDraftFromLocalStorage = () => {
    const { student, currentUserId } = this.props;
    const formName = commentFormName(student);
    const localStorageState = loadUserState(currentUserId);
    unset(localStorageState, `forms.${formName}`);
    saveUserState(currentUserId, localStorageState);
  };

  onSaveDraft = () => {
    const { currentUserId, formValues, student } = this.props;
    const localStorageState = loadUserState(currentUserId) || {};
    const formName = commentFormName(student);
    set(localStorageState, `forms.${formName}`, formValues);
    saveUserState(currentUserId, localStorageState);
    this.setState({ savedDraft: formValues });
  };

  onSubmitComment = (formValues) => {
    const { student, createSharedCommentOnServer } = this.props;
    return createSharedCommentOnServer(student.id, formValues).then(() => {
      this.removeDraftFromLocalStorage();
    });
  };

  uploadFile = (file) => {
    const { student } = this.props;
    return storage.uploadSharedAttachmentForStudentComment(file, student.id);
  }

  render() {
    const { comments, loading, student, formValues } = this.props;
    const { savedDraft } = this.state;

    return (
      <Grid container spacing={4}>
        <Grid item lg={5} md={6} xs={12}>
          <AddCommentForm
            onSubmit={this.onSubmitComment}
            supportAdditionalRecipient
            supportAdvisingAppointment
            studentId={student.id}
            form={commentFormName(student)}
            label={`Write a message to ${student.greetingName}`}
            validateTemplateVariablesReplaced
            preventNewMessages={!student.active}
            allowTemplates
            promptForMissingAttachments
            onSaveDraft={this.onSaveDraft}
            onClear={this.removeDraftFromLocalStorage}
            draftSaved={isEqual(savedDraft, formValues)}
            uploadFile={this.uploadFile}
          />
        </Grid>
        <Grid item lg={7} md={6} xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Comments
              comments={comments}
              studentId={student.id}
              searchable
              searchLabel="Search messages"
              preventNewMessages={!student.active}
              noCommentsMessage={`No messages have been created for ${student.name} yet.`}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

SharedCommentsAsAdviserContainer.propTypes = {
  comments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchSharedCommentsFromServer: PropTypes.func.isRequired,
  createSharedCommentOnServer: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  forwardedComment: PropTypes.object,
  removeForwardedComment: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  formValues: PropTypes.object,
};

SharedCommentsAsAdviserContainer.defaultProps = {
  forwardedComment: null,
  formValues: {},
};

const mapStateToProps = (state, { student }) => ({
  comments: getComments(state, student.id),
  loading: getCommentsLoading(state),
  forwardedComment: getForwardedComment(state),
  currentUserId: getLoggedInUserId(state),
  formValues: getFormValues(commentFormName(student))(state),
});

const mapDispatchToProps = {
  fetchSharedCommentsFromServer: fetchSharedComments,
  createSharedCommentOnServer: createSharedComment,
  changeForm: change,
  removeForwardedComment: () => ({ type: 'REMOVE_FORWARDED_COMMENT' }),
};
export default connect(mapStateToProps, mapDispatchToProps)(SharedCommentsAsAdviserContainer);
