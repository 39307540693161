import { func } from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../actions/auth';
import { isLocalEnv } from '../util/env';

class LogoutPage extends Component {
  static propTypes = {
    dispatch: func.isRequired,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(logout()).then(() => {
      if (isLocalEnv()) {
        window.location.href = '/system/fakelogin';
      } else {
        window.location.href = 'https://my.monash';
      }
    }).catch((err) => {
      console.error('logout error:', err);
    });
  }

  render() { return null; }
}

export default connect()(withRouter(LogoutPage));
