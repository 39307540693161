import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { blue, green, grey, indigo, pink } from '@material-ui/core/colors';
import StackedProgressBar from '../../common/StackedProgressBar';
import ColorIndicator from '../../common/ColorIndicator';

const CreditPointsProgress = ({ classes, course }) => {
  const cpTotal = course.cpAdvancedStanding + course.cpPassed + course.cpCurrentEnrolled + course.cpFutureEnrolled;
  const cpRemaining = course.cpRequired - cpTotal;
  const requiredPerc = (course.cpRequired / cpTotal) * 100;
  const progressBarTotal = cpTotal > course.cpRequired ? cpTotal : course.cpRequired;

  const bars = [
    {
      percentage: (course.cpAdvancedStanding / progressBarTotal) * 100,
      color: pink[300],
      label: course.cpAdvancedStanding,
      title: 'Adv standing',
    },
    {
      percentage: (course.cpPassed / progressBarTotal) * 100,
      color: green[300],
      label: course.cpPassed,
      title: 'Passed',
    },
    {
      percentage: (course.cpCurrentEnrolled / progressBarTotal) * 100,
      color: blue[300],
      label: course.cpCurrentEnrolled,
      title: 'Current enr',
    },
    {
      percentage: (course.cpFutureEnrolled / progressBarTotal) * 100,
      color: indigo[300],
      label: course.cpFutureEnrolled,
      title: 'Future enr',
    },
    {
      percentage: cpRemaining < 0 ? 0 : (cpRemaining / progressBarTotal) * 100,
      color: grey[300],
      label: cpRemaining,
      title: 'Remaining',
    },
  ];

  return (
    <>
      <div className={classes.title}>Credit points</div>

      <div className={classes.keysWrapper}>
        {bars.map((bar) => (
          <span className={classes.key} key={bar.title}>
            <ColorIndicator color={bar.color} /> {bar.title}: {bar.label}
          </span>
        ))}
      </div>

      <div className={classes.progressBarWrapper}>
        <span className={classes.startNumber}>0</span>
        <StackedProgressBar bars={bars} markerPercentage={requiredPerc} markerText={`${course.cpRequired} points required`} />
        <span className={classes.endNumber}>{cpTotal}</span>
      </div>
    </>
  );
};

CreditPointsProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
};

const styles = {
  title: {
    color: '#424242',
    fontWeight: 700,
    paddingRight: 10,
    marginBottom: 10,
  },
  progressBarWrapper: {
    display: 'flex',
  },
  keysWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  key: {
    marginRight: '5px',
  },
  startNumber: {
    marginRight: 5,
    marginTop: 5,
    fontWeight: 300,
    fontSize: 12,
  },
  endNumber: {
    marginLeft: 5,
    marginTop: 5,
    fontWeight: 300,
    fontSize: 12,
  },
};

export default withStyles(styles)(CreditPointsProgress);
