import React from 'react';
import cx from 'classnames';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import { Table, TableHead, TableRow, TableCell, TableBody, createStyles, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    tableHead: {
      '& th': {
        padding: 0,
      },
      '& th:first-child': {
        paddingLeft: 10,
      },
    },
    tableHeadRow: {
      height: 45,
    },
    tableBody: {
      '& td': {
        padding: 0,
      },
      '& td:first-child': {
        paddingLeft: 10,
      },
    },
    noData: {
      textAlign: 'center',
      backgroundColor: grey[200],
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  }),
);

const CourseInformationTable = ({ headings, label, children, padded }) => {
  const classes = useStyles();
  const concise = !padded;

  return children.length === 0 ? (
    <div className={classes.noData}>No {label}</div>
  ) : (
    <Table>
      <TableHead className={cx({ [classes.tableHead]: concise })}>
        <TableRow className={classes.tableHeadRow}>
          {headings.map((heading) => (
            <TableCell key={heading}>{heading}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={cx({ [classes.tableBody]: concise })}>{children}</TableBody>
    </Table>
  );
};

CourseInformationTable.propTypes = {
  headings: arrayOf(string).isRequired,
  label: string.isRequired,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  padded: bool,
};

CourseInformationTable.defaultProps = {
  padded: false,
};

export default CourseInformationTable;
