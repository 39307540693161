import { CircularProgress, IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { DeleteForever as DeleteFlagIcon, RemoveCircleOutline as RemoveflagFromStudentsIcon } from '@material-ui/icons';
import cx from 'classnames';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Alert from 'react-s-alert';
import flagApi from '../../services/api/flags';
import { classificationColour } from '../common/Classifications';
import ConfirmationDialog from '../common/ConfirmationDialog';
import ErrorMessages from '../forms/ErrorMessages';
import FlagStudentCount from './FlagStudentCount';

const useStyles = makeStyles((theme) => ({
  flag: {
    borderLeft: '4px solid',
  },
  actions: {
    width: theme.spacing(17),
  },
  flagACTION: {
    borderLeftColor: classificationColour('ACTION'),
  },
  flagRESPONSE: {
    borderLeftColor: classificationColour('RESPONSE'),
  },
  flagINFORMATION: {
    borderLeftColor: classificationColour('INFORMATION'),
  },
  flagCELEBRATION: {
    borderLeftColor: classificationColour('CELEBRATION'),
  },
}));

const FlagRow = ({ flag, onUpdate }) => {
  const classes = useStyles();
  const [count, setCount] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [clearDialogOpen, setClearDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const updateActionFailed = (error, dialogOpenStateFn) => {
    onUpdate();
    dialogOpenStateFn(false);
    return Alert.error(<ErrorMessages error={error} />);
  };

  const clearFromStudents = () =>
    flagApi
      .deleteFlagFromAllStudents(flag.id)
      .then((resp) => Alert.success(`Flag cleared from ${resp.value} ${pluralize('student', resp.value)}`))
      .then(onUpdate)
      .catch((error) => updateActionFailed(error, setClearDialogOpen));

  const deleteFlag = () =>
    flagApi
      .deleteFlag(flag.id)
      .then(() => Alert.success('Flag successfully deleted'))
      .then(onUpdate)
      .catch((error) => updateActionFailed(error, setDeleteDialogOpen));

  useEffect(() => {
    flagApi.countForFlag(flag.id).then((resp) => {
      setCount(resp.value);
      setLoading(false);
    });
  }, []);

  return (
    <TableRow>
      <TableCell className={cx(classes.flag, classes[`flag${flag.classification}`])}>{flag.text}</TableCell>
      <TableCell>{flag.classification}</TableCell>
      <TableCell>{loading ? <CircularProgress size={25} /> : <FlagStudentCount count={count} />}</TableCell>
      <TableCell className={classes.actions}>
        <Tooltip title="Clear from students" aria-label="Clear from students">
          <span>
            <IconButton onClick={() => setClearDialogOpen(true)} disabled={loading || !count}>
              <RemoveflagFromStudentsIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Delete flag" aria-label="Delete flag">
          <span>
            <IconButton onClick={() => setDeleteDialogOpen(true)} disabled={loading || count > 0}>
              <DeleteFlagIcon />
            </IconButton>
          </span>
        </Tooltip>
      </TableCell>
      <ConfirmationDialog
        content={`Are you sure you would like to clear the flag "${flag.text}" from all students?`}
        open={clearDialogOpen}
        onCancel={() => setClearDialogOpen(false)}
        onConfirm={clearFromStudents}
      />
      <ConfirmationDialog
        content={`Are you sure you would like to delete "${flag.text}"?`}
        open={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={deleteFlag}
      />
    </TableRow>
  );
};

FlagRow.propTypes = {
  flag: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default FlagRow;
