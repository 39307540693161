import { makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getReferenceDataDescription, getReferenceDataOrdinal } from '../../../reducers';

const baseLevelHeight = 5;

const ALL_LEVELS = [
  { level: 'UNKNOWN', shouldRender: false },
  { level: 'NONE', shouldRender: true },
  { level: 'LOW', shouldRender: true },
  { level: 'MED', shouldRender: true },
  { level: 'HIGH', shouldRender: true },
];
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  bar: {
    width: 5,
    backgroundColor: grey[500],
    marginRight: 2,
  },
  small: {
    height: baseLevelHeight,
  },
  medium: {
    height: baseLevelHeight * 2,
  },
  high: {
    height: baseLevelHeight * 3,
  },
  on: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const LevelOfEngagement = ({ level }) => {
  if (
    !level ||
    !ALL_LEVELS.filter((loe) => loe.shouldRender)
      .map((loe) => loe.level)
      .includes(level)
  ) {
    return null;
  }
  const classes = useStyles();
  const levelOfEngagement = useSelector((state) => getReferenceDataDescription(state, 'LevelOfEngagement', level));
  const actualLevelOfEngagementOrdinal = useSelector((state) => getReferenceDataOrdinal(state, 'LevelOfEngagement', level));
  const lowLevelOfEngagementOrdinal = useSelector((state) => getReferenceDataOrdinal(state, 'LevelOfEngagement', 'LOW'));
  const medLevelOfEngagementOrdinal = useSelector((state) => getReferenceDataOrdinal(state, 'LevelOfEngagement', 'MED'));
  const highLevelOfEngagementOrdinal = useSelector((state) => getReferenceDataOrdinal(state, 'LevelOfEngagement', 'HIGH'));

  const deriveTooltip = () => `Moodle engagement indicator: ${levelOfEngagement}`;

  return (
    <Tooltip title={deriveTooltip()} placement="top">
      <div className={classes.container}>
        <div className={cx(classes.bar, classes.small, { [classes.on]: actualLevelOfEngagementOrdinal >= lowLevelOfEngagementOrdinal })} />
        <div className={cx(classes.bar, classes.medium, { [classes.on]: actualLevelOfEngagementOrdinal >= medLevelOfEngagementOrdinal })} />
        <div className={cx(classes.bar, classes.high, { [classes.on]: actualLevelOfEngagementOrdinal >= highLevelOfEngagementOrdinal })} />
      </div>
    </Tooltip>
  );
};

LevelOfEngagement.propTypes = {
  level: PropTypes.oneOf(ALL_LEVELS.map((loe) => loe.level)),
};

LevelOfEngagement.defaultProps = {
  level: null,
};

export default LevelOfEngagement;
