import { object } from 'prop-types';
import { parse } from 'query-string';
import React, { Component } from 'react';
import { isLocalEnv } from '../util/env';
import './LoginPage.less';

class LoginPage extends Component {
  static propTypes = {
    location: object.isRequired,
  };

  componentDidMount() {
    const { location } = this.props;
    const query = parse(location.search);
    const next = query.next || '/';
    if (isLocalEnv()) {
      // include host and port etc otherwise it will default to 8080 (on server) instead of 3000
      window.location.href = `/system/fakelogin?relayState=${window.location.protocol}//${window.location.hostname}:${window.location.port}${next}`;
    } else {
      window.location.href = `/saml2/authenticate/one?relayState=${next}`;
    }
  }

  render() {
    return (
      <span/>
    );
  }
}

export default LoginPage;
