import React from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '200px',
    textAlign: 'center',
    color: grey[600],
  },
  mainText: {
    fontSize: 24,
    fontWeight: 500,
  },
  explainerText: {
    fontSize: 16,
    fontWeight: 400,
  },
}));

const NoStudentData = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.mainText}>No student data found</p>
      <p className={classes.explainerText}>
        Access to Juvo is for currently enrolled Monash Education students only. To contact Monash, please go to the{' '}
        <Link href="https://www.monash.edu/people/contact">Contact Monash</Link> page.
      </p>
    </div>
  );
};

export default NoStudentData;
