import { List, ListItem, ListItemAvatar, Badge, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { blue, lightGreen, blueGrey } from '@material-ui/core/colors';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import JuvoAvatar from '../common/JuvoAvatar';

const useStyles = makeStyles((theme) => ({
  messageAdvisingAppointment: {
    borderLeft: `5px solid ${blue[500]}`,
    backgroundColor: blue[50],
  },
  noteAdvisingAppointment: {
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    backgroundColor: lightGreen[50],
  },
  advisingAppointmentCount: {
    padding: '0 15px',
  },
  customBadge: {
    backgroundColor: blueGrey[300],
    color: '#fff',
  },
  noEntries: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const AdvisingAppointments = ({ studentId }) => {
  const [advisingAppointments, updateAdvisingAppointments] = useState([]);
  useEffect(() => {
    api.comments.listAdvisingAppointments(studentId).then((response) => {
      updateAdvisingAppointments(response);
    });
  }, []);
  const classes = useStyles();
  return (
    <>
      {advisingAppointments.length === 0 && (
        <div className={classes.noEntries}>
          <Typography variant="subtitle1">No advising appointments</Typography>
        </div>
      )}

      {advisingAppointments.length > 0 && (
        <>
          <Badge classes={{ badge: classes.customBadge }} className={classes.advisingAppointmentCount} badgeContent={advisingAppointments.length}>
            <Typography variant="subtitle1">Advising appointments</Typography>
          </Badge>
          <List>
            {advisingAppointments.map((appointment) => (
              <ListItem
                key={appointment.id}
                className={cx({
                  [classes.messageAdvisingAppointment]: !appointment.internal,
                  [classes.noteAdvisingAppointment]: appointment.internal,
                })}
              >
                <ListItemAvatar>
                  <JuvoAvatar image={appointment.author.image} size="xs" />
                </ListItemAvatar>
                <ListItemText
                  primary={moment(appointment.created).format('MMMM Do YYYY')}
                  secondary={<Typography variant="body2">{appointment.author.greetingName}</Typography>}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
};

AdvisingAppointments.propTypes = {
  studentId: PropTypes.string.isRequired,
};

export default AdvisingAppointments;
