import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, createStyles, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CourseInformationTable from './CourseInformationTable';

const useStyles = makeStyles((theme) =>
  createStyles({
    tableRow: {
      minHeight: 35,
    },
    notes: {
      color: grey[700],
      whiteSpace: 'pre-wrap',
      padding: theme.spacing(3),
    },
  }),
);

const FacultyNotes = ({ facultyNotes }) => {
  const classes = useStyles();

  return (
    <CourseInformationTable
      headings={['This information is drawn from the Faculty Notes section in Callista at a course level. To update, amend in Callista directly']}
      label="faculty notes"
      padded
    >
      {facultyNotes.map((facultyNote) => (
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.notes}>{facultyNote}</TableCell>
        </TableRow>
      ))}
    </CourseInformationTable>
  );
};

FacultyNotes.propTypes = {
  facultyNotes: PropTypes.arrayOf(PropTypes.string),
};

FacultyNotes.defaultProps = {
  facultyNotes: [],
};

export default FacultyNotes;
