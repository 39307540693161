import { Announcement, Flag as FlagIcon } from '@material-ui/icons';
import Archive from '@material-ui/icons/Archive';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import People from '@material-ui/icons/People';
import School from '@material-ui/icons/School';
import Settings from '@material-ui/icons/Settings';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import CoreLayout from '../../components/layouts/CoreLayout';
import { isAdviser as isAdviserReducer } from '../../reducers';
import moodleLogo from '../../../images/moodle-icon-11.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  iconImage: {
    width: 24,
  },
}));

const AdminLayout = ({ children }) => {
  const isAdviser = useSelector((state) => isAdviserReducer(state));
  const classes = useStyles();

  const menuItems = [
    {
      path: '/admin/advisers',
      title: 'Advisers',
      Icon: School,
    },
    {
      path: '/admin/announcements',
      title: 'Announcements',
      Icon: Announcement,
    },
    {
      path: '/admin/message-templates',
      title: 'Templates',
      Icon: ViewHeadline,
    },
    {
      path: '/admin/flags',
      title: 'Flags',
      Icon: FlagIcon,
    },
    {
      path: '/admin/users',
      title: 'Users',
      Icon: People,
    },
    {
      path: '/admin/moodle-ingestions',
      title: 'Moodle file ingestions',
      Icon: () => <img src={moodleLogo} className={classes.iconImage} alt="Moodle icon" />,
    },
    {
      path: '/admin/archived-students',
      title: 'Archived Students',
      Icon: Archive,
    },
    {
      path: '/admin/settings',
      title: 'Settings, Reports & Custom alerts',
      Icon: Settings,
    },
  ];

  if (isAdviser) {
    menuItems.push({
      path: '/adviser',
      title: 'Adviser view',
      Icon: ArrowDownwardIcon,
    });
  }

  return (
    <CoreLayout menuItems={menuItems} padded isAdmin>
      {children}
    </CoreLayout>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default AdminLayout;
