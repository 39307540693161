import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import api from '../../../services/api';
import ReallocateStudentsForm from './ReallocateStudentsForm';
import FormDialog from '../../forms/FormDialog';

const ReallocateStudents = ({
  advisers,
  students,
  onActionCompleted,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const stopSubmittingWithError = (error) => {
    setSubmitting(false);
    setErrorMessages(error.messages);
  };

  const toggleDialogOpen = () => setDialogOpen(!dialogOpen);

  const handleReallocateStudents = (values) => {
    setSubmitting(true);
    return api.advisers
      .reallocateStudents(values.newAdviserId, students.map((student) => student.id))
      .then(() => {
        setSubmitting(false);
        toggleDialogOpen();
        onActionCompleted();
      })
      .catch((error) => {
        stopSubmittingWithError(error.messages);
      });
  };

  return !!advisers.length && (
    <>
      <Button variant="contained" color="primary" onClick={toggleDialogOpen} disabled={!enabled}>
        Change adviser
      </Button>
      <FormDialog
        title="Reallocate Students"
        submitButtonText="Reallocate"
        formComponent={ReallocateStudentsForm}
        formName="reallocateStudents"
        open={dialogOpen}
        onCancel={toggleDialogOpen}
        onSubmit={handleReallocateStudents}
        students={students}
        advisers={advisers}
        submitting={submitting}
        errorMessages={errorMessages}
      />
    </>

  );
};

ReallocateStudents.propTypes = {
  advisers: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  onActionCompleted: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default ReallocateStudents;
