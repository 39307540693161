import { makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import MoodleIngestionRow, { MoodleIngestionRowHeader } from './MoodleIngestionRow';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    backgroundColor: '#fff',
  },
  noData: {
    textAlign: 'center',
    color: grey[700],
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const MoodleIngestions = ({ logs }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <MoodleIngestionRowHeader />
      <TableBody>
        {logs.length ? (
          logs.map((log) => <MoodleIngestionRow key={log.id} row={log} />)
        ) : (
          <TableRow>
            <TableCell className={classes.noData} colSpan={4}>
              No Moodle ingestions logged yet
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

MoodleIngestions.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default MoodleIngestions;
