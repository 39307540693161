import { Checkbox, TableCell, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { BeachAccess as BeachAccessIcon, Chat as ChatIcon, Snooze as SnoozeIcon } from '@material-ui/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import * as model from '../../../model';
import { isAdmin as isAdminReducer } from '../../../reducers';
import JuvoAvatar from '../../common/JuvoAvatar';
import { classificationColour } from '../../common/Classifications';
import Count from '../../Count';

const StudentRow = ({ student, isSelected, classes, onClickCheckbox, onClickRow, currentAdviser, showAdviser, isAdmin }) => {
  const differentAdviser = showAdviser && student.adviser && student.adviser.id !== currentAdviser.id;

  return (
    <TableRow
      data-id={student.id}
      hover
      className={cx(['clickable', { [classes.differentAdviser]: differentAdviser || !student.adviser }])}
      onClick={(event) => onClickRow(event, student.id)}
    >
      <TableCell style={{ padding: 0 }}>
        <Checkbox checked={isSelected} onClick={(event) => onClickCheckbox(event, student)} value={student.id} color="primary" />
      </TableCell>
      <TableCell className={classes.nameCell}>
        <ul className="unstyled-list">
          <li>
            {student.name}{' '}
            {student.hasTemporaryAdviser && (
              <Tooltip title={`Temporary adviser: ${student.temporaryAdviser.givenNames} ${student.temporaryAdviser.surname}`}>
                {isAdmin ? (
                  <Link onClick={(e) => e.stopPropagation()} to={`/admin/advisers/${student.temporaryAdviser.id}`} className={classes.onLeaveIcon}>
                    <BeachAccessIcon style={{ fontSize: '1rem' }} />
                  </Link>
                ) : (
                  <BeachAccessIcon style={{ fontSize: '1rem' }} />
                )}
              </Tooltip>
            )}
          </li>
          <li>
            <Typography variant="caption">{student.personId}</Typography>
          </li>
        </ul>
      </TableCell>
      <TableCell>{student.campusLocation}</TableCell>
      <TableCell>{student.courseCodes.join(', ')}</TableCell>
      {showAdviser && (
        <TableCell>
          {differentAdviser && (
            <>
              <div className={classes.adviserAvatarContainer}>
                <JuvoAvatar className={classes.adviserAvatar} image={student.adviser.image} size="xs" />
                <Tooltip className={classes.adviserName} title={student.adviser.name}>
                  <span>{student.adviser.givenNames}</span>
                </Tooltip>
              </div>
            </>
          )}
        </TableCell>
      )}
      <TableCell style={{ width: 20, padding: '0px 10px' }}>
        {student.snoozed && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Snoozed">
              <SnoozeIcon style={{ fontSize: '25px', color: grey[700] }} />
            </Tooltip>
          </div>
        )}
      </TableCell>
      <TableCell style={{ padding: '0px 10px' }} className={classes.iconsCell}>
        {student.openResponseAlertCount > 0 && <Count count={student.openResponseAlertCount} color={classificationColour('RESPONSE')} />}
        {student.openActionAlertCount > 0 && <Count count={student.openActionAlertCount} color={classificationColour('ACTION')} />}
        {student.openInformationAlertCount > 0 && <Count count={student.openInformationAlertCount} color={classificationColour('INFORMATION')} />}
        {student.openCelebrationAlertCount > 0 && <Count count={student.openCelebrationAlertCount} color={classificationColour('CELEBRATION')} />}
        {student.hasMessages && (
          <span>
            <ChatIcon className={classes.studentMessages} />
          </span>
        )}
      </TableCell>
    </TableRow>
  );
};

StudentRow.propTypes = {
  classes: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  onClickCheckbox: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  currentAdviser: model.user.isRequired,
  showAdviser: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const styles = (theme) => ({
  differentAdviser: {
    backgroundColor: grey[100],
  },
  table: {
    minWidth: 700,
  },
  adviserAvatarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.2rem',
  },
  adviserName: {
    marginLeft: theme.spacing(1),
  },
  onLeaveIcon: {
    color: theme.palette.primary.main,
  },
  iconsCell: {
    '& > :nth-child(n+2)': {
      marginLeft: 5,
    },
  },
  studentMessages: {
    color: grey[500],
    verticalAlign: 'middle',
    marginLeft: 5,
  },
  nameCell: {
    padding: '4px 56px 4px 0px',
  },
});

const mapStateToProps = (state) => ({
  isAdmin: isAdminReducer(state),
});

export default withStyles(styles)(connect(mapStateToProps)(StudentRow));
