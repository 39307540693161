import api from '../services/api';
import { asyncAction } from './actions';

export const fetchStudentDetails = (id) => asyncAction('FETCH_STUDENT_DETAILS', api.students.get(id));

export const fetchCurrent = () => asyncAction('FETCH_CURRENT_STUDENT_DETAILS', api.students.getCurrent());

export const setSnoozed = (studentId, value) => asyncAction('SET_SNOOZED', api.students.setSnoozed(studentId, value));

export const updateStudentDetails = (studentId, updatedDetails) =>
  asyncAction('SET_PREFERRED_NAME', api.students.updateStudentDetails(studentId, updatedDetails));
