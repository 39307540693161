import { grey } from '@material-ui/core/colors';
import { IconButton, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { AttachmentOutlined as AttachmentIcon, DeleteForeverOutlined as CrossIcon } from '@material-ui/icons';

const AttachmentList = ({ classes, attachments, onRemove, className, concise }) => {
  if (attachments.length) {
    return (
      <ul className={`unstyled-list attachment-list ${className}`}>
        {attachments.map((attachment) => (
          <li key={attachment.id} className={classes.attachment}>
            {!concise && <AttachmentIcon className={classes.attachmentIcon} />}
            <a href={attachment.requestLink} target="_blank" rel="noopener noreferrer">
              {attachment.filename}
            </a>
            {onRemove && (
              <IconButton key="remove" aria-label="Remove" color="inherit" onClick={() => onRemove(attachment)}>
                <CrossIcon className={classes.removeButton} />
              </IconButton>
            )}
          </li>
        ))}
      </ul>
    );
  }
  return null;
};

AttachmentList.propTypes = {
  classes: PropTypes.object.isRequired,
  concise: PropTypes.bool,
  attachments: PropTypes.array,
  onRemove: PropTypes.func,
  className: PropTypes.string,
};

AttachmentList.defaultProps = {
  concise: false,
  onRemove: undefined,
  className: undefined,
  attachments: [],
};

const styles = {
  attachment: {
    display: 'flex',
    alignItems: 'center',
    color: grey[800],
    '& a': {
      textDecoration: 'none',
    },
  },
  attachmentIcon: {
    width: 22,
    marginRight: 5,
  },
  removeButton: {
    width: 20,
  },
};

export default withStyles(styles)(AttachmentList);
