import { Grid, makeStyles, Typography } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import JuvoAvatar from '../common/JuvoAvatar';
import JuvoTooltip from '../common/JuvoTooltip';
import AboutMeDialog from './AboutMeDialog';
import CourseMap from './CourseMap';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 5,
  },
  studentWrapper: {
    paddingLeft: 24,
    display: 'flex',
    marginBottom: 20,
  },
  adviserWrapper: {
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  studentAvatar: {
    height: 75,
    width: 75,
    fontSize: '1.7rem',
    backgroundColor: grey[400],
  },
  studentDetailsWrapper: {
    marginLeft: 25,
    marginTop: 3,
  },
  adviserDetailsWrapper: {
    marginRight: 22,
    marginTop: 12,
    textAlign: 'right',
    float: 'right',
    '& $title': {
      fontSize: '1.5em',
      fontWeight: 500,
    },
  },
  title: {
    fontSize: '2em',
    fontWeight: 700,
    lineHeight: 1,
  },
  subtitle: {
    color: grey[800],
    fontWeight: 300,
    marginTop: 3,
  },
  tempAdviser: {
    color: grey[800],
    fontWeight: 300,
    clear: 'both',
    marginTop: 10,
    padding: 10,
    background: grey[200],
    textAlign: 'left',
    maxWidth: 500,
    marginRight: theme.spacing(2),
  },
  bold: {
    fontWeight: 700,
    color: grey[700],
  },
  tempAdviserWrapper: {
    '& img': {
      opacity: 0.4,
    },
    '& $indicator': {
      backgroundColor: red[500],
    },
    marginBottom: theme.spacing(1),
  },
  indicator: {
    height: 10,
    width: 10,
    marginRight: 5,
    display: 'inline-block',
    borderRadius: 30,
    backgroundColor: green[500],
  },
  aboutMeLink: {
    padding: 0,
    cursor: 'pointer',
  },
}));

const StudentViewHeader = ({ student }) => {
  const classes = useStyles();
  if (!student) return <div>No data</div>;

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <div className={classes.studentWrapper}>
          <JuvoAvatar image={student.image} tooltip="Monash ID card (M-Pass) photo" />

          <div className={classes.studentDetailsWrapper}>
            <JuvoTooltip title="Juvo Nickname is managed by your adviser" placement="right-end">
              <Typography variant="h6" className={classes.title}>
                Welcome {student.greetingName}
              </Typography>
            </JuvoTooltip>
            <div className={classes.subtitle}>Name: {student.shortFullName}</div>
            <div className={classes.subtitle}>ID: {student.personId}</div>
            <CourseMap courseMap={student.courseMap} />
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <div className={cx(classes.adviserWrapper, { [classes.tempAdviserWrapper]: !!student.temporaryAdviser })}>
          {student.temporaryAdviser && (
            <div>
              <div className={classes.tempAdviser}>
                <span className={classes.bold}>Notice: </span> {student.adviser.greetingName} is currently on leave. Your acting adviser is{' '}
                <span className={classes.bold}>{student.temporaryAdviser.greetingName}</span>
              </div>
            </div>
          )}
          <div className={classes.adviserDetailsWrapper}>
            <Typography variant="h6" className={classes.title}>
              My adviser
            </Typography>

            <JuvoTooltip
              title={
                student.temporaryAdviser ? `${student.adviser.greetingName} is currently on leave` : `${student.adviser.greetingName} is available`
              }
            >
              <div className={classes.subtitle}>
                <span className={classes.indicator} /> {student.adviser.greetingName}
              </div>
            </JuvoTooltip>
            {student.adviser.aboutMe && (
              <AboutMeDialog
                adviserGreetingName={student.adviser.greetingName}
                adviserImage={student.adviser.image}
                adviserAboutMeText={student.adviser.aboutMe}
              />
            )}
          </div>
          <JuvoAvatar image={student.adviser.image} />
        </div>
      </Grid>
    </Grid>
  );
};

StudentViewHeader.propTypes = {
  student: PropTypes.object.isRequired,
};

export default StudentViewHeader;
