/* eslint-disable no-constant-condition */
const highlightTextInHtml = {
  highlight(container, highlightText) {
    const internalHighlighter = (options) => {
      const id = {
        container: 'container',
        tokens: 'tokens',
        all: 'all',
        token: 'token',
        className: 'className',
        sensitiveSearch: 'sensitiveSearch',
      };
      const tokens = options[id.tokens];
      const allClassName = options[id.all][id.className];
      const allSensitiveSearch = options[id.all][id.sensitiveSearch];

      function checkAndReplace(node, tokenArr, classNameAll, sensitiveSearchAll) {
        let nodeVal = node.nodeValue;
        const { parentNode } = node;
        let i; let j; let curToken; let myToken; let mySensitiveSearch;
        let finalSensitiveSearch;
        let foundIndex; let begin; let matched;
        let textNode; let span; let isFirst;
        for (i = 0, j = tokenArr.length; i < j; i += 1) {
          curToken = tokenArr[i];
          myToken = curToken[id.token];
          mySensitiveSearch = curToken[id.sensitiveSearch];
          finalSensitiveSearch = (typeof sensitiveSearchAll !== 'undefined' ? sensitiveSearchAll : mySensitiveSearch);

          isFirst = true;
          while (true) {
            if (finalSensitiveSearch) foundIndex = nodeVal.indexOf(myToken);
            else foundIndex = nodeVal.toLowerCase().indexOf(myToken.toLowerCase());

            if (foundIndex < 0) {
              if (isFirst) break;

              if (nodeVal) {
                textNode = document.createTextNode(nodeVal);
                parentNode.insertBefore(textNode, node);
              } // End if (nodeVal)

              parentNode.removeChild(node);
              break;
            } // End if (foundIndex < 0)

            isFirst = false;
            begin = nodeVal.substring(0, foundIndex);
            matched = nodeVal.substr(foundIndex, myToken.length);

            if (begin) {
              textNode = document.createTextNode(begin);
              parentNode.insertBefore(textNode, node);
            } // End if (begin)

            span = document.createElement('mark');
            span.appendChild(document.createTextNode(matched));
            parentNode.insertBefore(span, node);

            nodeVal = nodeVal.substring(foundIndex + myToken.length);
          } // Whend
        } // Next i
      } // End Function checkAndReplace

      function iterator(p) {
        if (p === null) return;

        const children = Array.prototype.slice.call(p.childNodes); let i; let
          cur;

        if (children.length) {
          for (i = 0; i < children.length; i += 1) {
            cur = children[i];
            if (cur.nodeType === 3) {
              checkAndReplace(cur, tokens, allClassName, allSensitiveSearch);
            } else if (cur.nodeType === 1) {
              iterator(cur);
            }
          }
        }
      } // End Function iterator

      const clone = options[id.container].cloneNode(true);
      iterator(clone);
      return clone;
    };


    return internalHighlighter(
      {
        container,
        all:
          {
            className: 'highlighter',
          },
        tokens: [
          {
            token: highlightText,
            className: 'highlight',
            sensitiveSearch: false,
          },
        ],
      },
    ); // End Call internalHighlighter
  }, // End Function highlight
};

export default highlightTextInHtml;
