import { grey } from '@material-ui/core/colors';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogContentText,
  Link,
  Button,
  Slide,
  makeStyles,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import JuvoAvatar from '../common/JuvoAvatar';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="left" {...props} />);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
  aboutMeText: {
    whiteSpace: 'pre-wrap',
  },
}));

const AboutMeDialog = ({ adviserGreetingName, adviserImage, adviserAboutMeText, openLinkText }) => {
  const [showDialog, updateShowDialog] = useState(false);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        className={classes.dialogContainer}
        open={showDialog}
        onClose={() => updateShowDialog(false)}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <JuvoAvatar image={adviserImage} />
            </Grid>
            <Grid item>
              <span>{`About ${adviserGreetingName}`}</span>
            </Grid>
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => updateShowDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.aboutMeText}>{adviserAboutMeText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={() => updateShowDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Link className="clickable" color="primary" onClick={() => updateShowDialog(true)}>
        {openLinkText || 'About me'}
      </Link>
    </div>
  );
};

AboutMeDialog.propTypes = {
  adviserGreetingName: PropTypes.string.isRequired,
  adviserImage: PropTypes.object,
  adviserAboutMeText: PropTypes.string,
  openLinkText: PropTypes.string,
};

AboutMeDialog.defaultProps = {
  adviserImage: null,
  adviserAboutMeText: null,
  openLinkText: null,
};

export default AboutMeDialog;
