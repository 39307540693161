import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CopyToClipboardButton from './CopyToClipboardButton';

const SimpleTable = ({ classes, rows }) => (
  <table className={classes.root}>
    <tbody>
      {rows.map((row) => (
        <tr key={row.label} className={classes.row}>
          <td className={classes.label}>{row.label}</td>
          <td className={classes.value}>
            {row.value} {row.copyable && <CopyToClipboardButton small text={row.value} />}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
};

const styles = {
  root: {
    padding: '5px 15px 0 0',
  },
  row: {
    lineHeight: '1.6rem',
  },
  label: {
    color: grey[800],
    paddingRight: 30,
    fontWeight: 300,
  },
  value: {
    fontWeight: 400,
  },
};

export default withStyles(styles)(SimpleTable);
