import { grey } from '@material-ui/core/colors';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ArchivedStudentRow from './ArchivedStudentRow';

function ArchivedStudentsTable(props) {
  const { students, classes } = props;

  const headers = ['Student', 'Email', 'Campus', 'Course', 'Archived date'];

  const reachedStudentLimit = students.length === 100;

  return (
    <>
      <Paper className={classes.root}>
        <Toolbar>
          <Typography variant="h6" id="archivedStudentsTableTitle">
            Archived students
          </Typography>
        </Toolbar>
        {reachedStudentLimit && <div className={classes.tableInfo}>Showing first 100 students</div>}
        <Table className={classes.table}>
          {headers.length && (
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {students && students.length > 0 ? (
              students.map((student) => <ArchivedStudentRow key={student.id} student={student} />)
            ) : (
              <TableRow>
                <TableCell>
                  <i>No students match your search</i>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

ArchivedStudentsTable.propTypes = {
  classes: PropTypes.object,
  students: PropTypes.array.isRequired,
};

ArchivedStudentsTable.defaultProps = {
  classes: {},
};

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  tableInfo: {
    margin: '10px 0px 10px 32px',
    color: grey[500],
  },
});

export default withStyles(styles)(ArchivedStudentsTable);
