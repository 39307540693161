import { deleteJSON, getJSON, postJSON, putJSON } from './http';

const listSummaries = () => getJSON('/message-templates');
const listAll = () => getJSON('/message-templates/full');
const create = (messageTemplate) => postJSON('/message-templates', messageTemplate);
const update = (id, messageTemplate) => putJSON(`/message-templates/${id}`, messageTemplate);
const deleteItem = (id) => deleteJSON(`/message-templates/${id}`);
const get = (id) => getJSON(`/message-templates/${id}`);

const getContent = (templateId, studentId) => getJSON(`/message-templates/${templateId}/students/${studentId || ''}`);
const preview = (studentId, content) => postJSON(`/message-templates/preview/students/${studentId}`, { value: content });

export default {
  listSummaries,
  listAll,
  getContent,
  deleteItem,
  create,
  update,
  get,
  preview,
};
