import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const ColorIndicator = ({ classes, color }) => <div className={classes.root} style={{ backgroundColor: color }} />;

ColorIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

const styles = {
  root: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
  },
};

export default withStyles(styles)(ColorIndicator);
