/* eslint-disable jsx-a11y/click-events-have-key-events */
import { blue, green, grey, orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, School as SchoolIcon } from '@material-ui/icons';
import cx from 'classnames';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getReferenceDataDescription } from '../../../reducers';
import dates from '../../../util/dates';
import CopyToClipboardButton from '../../common/CopyToClipboardButton';

const CourseHeader = ({ classes, course, getEnrolmentStatus, getAttendanceType, getAttendanceMode, expanded, handleExpandClick }) => {
  const hasActiveIntermission = () => course.enrolmentStatus === 'INTERMIT' && course.activeIntermission;
  const parseDate = (date) => moment(date, 'YYYYMMDD').format('D MMM YYYY');
  return (
    <div
      className={cx(classes.root, classes[`enrolmentStatus${capitalize(course.enrolmentStatus)}`])}
      onClick={handleExpandClick}
      role="button"
      tabIndex={-1}
    >
      <div className={classes.leftSide}>
        <SchoolIcon className={classes.icon} />
        <div className={classes.leftSideTextWrapper}>
          <div className={classes.title}>
            <span>{course.title}</span>
            <CopyToClipboardButton text={course.title} buttonColor="#fff" />
          </div>
          <div className={classes.subtext}>
            <span>{course.progressionStatus}</span>
            <span className={classes.separator}>|</span>
            <span>
              <span className={classes.lighterText}>Code/Version:</span>
              <a
                href={`http://www.monash.edu/pubs/handbooks/courses/${get(course.code.match(/[a-zA-Z]\d\d\d\d/), '[0]')}.html`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {course.code}/{course.version}
              </a>
            </span>
            <span className={classes.separator}>|</span>
            <span>
              <span className={classes.lighterText}>Location:</span> {course.location}
            </span>
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.titleRight}>
          <span className={classes.enrolmentStatus}>{getEnrolmentStatus(course.enrolmentStatus)}</span>
          <small className={cx(classes.enrolmentStatus, classes.enrolmentStatusSubtext)}>
            {hasActiveIntermission() && (
              <span>
                {parseDate(course.activeIntermission.startDate)} - {parseDate(course.activeIntermission.endDate)}
              </span>
            )}
            {!!course.futureDiscontinuationDate && (
              <span className={classes.discontinuingMessage}>Discontinuing {parseDate(course.futureDiscontinuationDate)}</span>
            )}
          </small>
          {getAttendanceMode(course.attendanceMode)}/{getAttendanceType(course.attendanceType)}
          <div className={classes.subtext}>
            <span>
              <span className={classes.lighterText}>Commenced:</span> {dates.formatDate(course.commencementDate)}
            </span>
            <span className={classes.separator}>|</span>
            <span>
              <span className={classes.lighterText}>Nominated completion year:</span> {course.nominatedCompletionYear}
            </span>
          </div>
        </div>

        <div className={classes.wamWrapper}>
          <div className={classes.wamLabel}>WAM</div>
          <div className={classes.wam}>{course.weightedAverageMark}</div>
        </div>

        <ExpandMoreIcon className={cx(classes.expand, { [classes.expandOpen]: expanded })} />
      </div>
    </div>
  );
};

CourseHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  getEnrolmentStatus: PropTypes.func.isRequired,
  getAttendanceType: PropTypes.func.isRequired,
  getAttendanceMode: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
};

const styles = {
  root: {
    minHeight: 73,
    backgroundColor: '#000',
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    color: '#fff',
    cursor: 'pointer',
  },
  enrolmentStatusCompleted: {
    '& $icon': {
      color: green[300],
    },
    '& $enrolmentStatus': {
      color: green[300],
    },
  },
  enrolmentStatusDiscontin: {
    '& $icon': {
      color: grey[500],
    },
    '& $enrolmentStatus': {
      color: grey[500],
    },
  },
  discontinuingMessage: {
    color: orange[300],
  },
  leftSide: {
    maxWidth: 'calc(100% - 510px)',
  },
  rightSide: {
    maxWidth: 650,
    display: 'flex',
  },
  icon: {
    float: 'left',
    color: blue[300],
    backgroundColor: '#000',
    fontSize: 35,
    margin: 19,
  },
  leftSideTextWrapper: {
    marginLeft: 73,
  },
  title: {
    marginTop: 17,
    fontWeight: 600,
    fontSize: 20,
  },
  subtext: {
    fontSize: 12,
    marginBottom: 15,
    '& a': {
      color: 'inherit',
    },
  },
  separator: {
    margin: '0 10px',
    fontWeight: 300,
  },
  lighterText: {
    fontWeight: 300,
  },
  wamWrapper: {
    margin: 20,
    paddingLeft: 15,
    textAlign: 'right',
    float: 'right',
    borderLeft: `1px solid ${grey[600]}`,
  },
  wamLabel: {
    fontSize: 13,
    fontWeight: 300,
  },
  wam: {
    fontSize: 20,
  },
  titleRight: {
    float: 'right',
    marginTop: 20,
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'right',
  },
  enrolmentStatus: {
    color: blue[300],
  },
  enrolmentStatusSubtext: {
    marginRight: 10,
    fontWeight: 'normal',
    '& > span': {
      marginLeft: 5,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    color: '#fff',
    position: 'absolute',
    bottom: 6,
    left: 'calc(50% - 12px)',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
};

export default connect((state) => ({
  getEnrolmentStatus: (status) => getReferenceDataDescription(state, 'CourseEnrolmentStatus', status),
  getCitizenship: (cit) => getReferenceDataDescription(state, 'CitizenshipType', cit),
  getAttendanceType: (type) => getReferenceDataDescription(state, 'AttendanceType', type),
  getAttendanceMode: (mode) => getReferenceDataDescription(state, 'AttendanceMode', mode),
}))(withStyles(styles)(CourseHeader));
