import { requestJSON } from './http';

const createInternal = (studentId, comment) => requestJSON(`/students/${studentId}/comments/internal`, 'POST', comment);

const listInternal = (studentId) => requestJSON(`/students/${studentId}/comments/internal`);

const createShared = (studentId, comment) => requestJSON(`/students/${studentId}/comments/shared`, 'POST', comment);
const listShared = (studentId) => requestJSON(`/students/${studentId}/comments/shared`);

const createSharedAsStudent = (comment) => requestJSON('/students/me/comments', 'POST', comment);

const listSharedAsStudent = () => requestJSON('/students/me/comments');

const createBulkShared = (studentIds, content, attachments) =>
  requestJSON('/comments/bulk/shared', 'POST', {
    studentIds,
    operation: { content, attachments },
  });

const createBulkSharedSearch = (adviserId, studentSearchRequest, content, attachments) =>
  requestJSON('/comments/bulk/shared/search', 'POST', {
    adviserId,
    studentSearchRequest,
    operation: { content, attachments },
  });

const createBulkSharedByCsv = (studentCsv, content, attachments) =>
  requestJSON('/comments/bulk/shared/csv', 'POST', { studentCsv, operation: { content, attachments } });

const createBulkInternalByCsv = (studentCsv, content, attachments) =>
  requestJSON('/comments/bulk/internal/csv', 'POST', { studentCsv, operation: { content, attachments } });

const countBulkStudents = (attachment) => requestJSON('/comments/bulk/student-count', 'POST', attachment);

const listAdvisingAppointments = (studentId) => requestJSON(`/students/${studentId}/comments/advising-appointments`);

const countAdvisingAppointments = (studentId) => requestJSON(`/students/${studentId}/comments/advising-appointments/count`);

export default {
  createInternal,
  listInternal,
  createShared,
  listShared,
  createSharedAsStudent,
  listSharedAsStudent,
  createBulkShared,
  createBulkSharedSearch,
  createBulkSharedByCsv,
  createBulkInternalByCsv,
  countBulkStudents,
  listAdvisingAppointments,
  countAdvisingAppointments,
};
