import { deleteJSON, getJSON, putJSON } from './http';

const list = () => getJSON('/students/flags');
const deleteFlagFromStudent = (studentId, flagId) => putJSON(`/students/${studentId}/flags/${flagId}`);
const addFlags = (studentId, flagIds) => putJSON(`/students/${studentId}?${new URLSearchParams({ flagIds })}`);
const create = (text, classification) => putJSON('/students/flags', { text, classification });
const countForFlag = (flagId) => getJSON(`/students/flags/${flagId}/count`);
const deleteFlagFromAllStudents = (flagId) => putJSON(`/students/flags/${flagId}`);
const deleteFlag = (flagId) => deleteJSON(`/students/flags/${flagId}`);

export default {
  list,
  create,
  deleteFlagFromStudent,
  addFlags,
  countForFlag,
  deleteFlagFromAllStudents,
  deleteFlag,
};
