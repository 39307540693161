import { Chip, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import { grey, indigo, pink, red } from '@material-ui/core/colors';
import {
  BeachAccess as BeachAccessIcon,
  CalendarToday as CalendarTodayIcon,
  EmailOutlined as PurpleIcon,
  ErrorOutline as RedIcon,
  Grade as GreenIcon,
  HelpOutline as AmberIcon,
} from '@material-ui/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { valueOrPlaceholder } from '../../util/objects';
import JuvoAvatar from '../common/JuvoAvatar';
import AdviserStats from './AdviserStats';
import { classificationColour } from '../common/Classifications';

const AdviserCard = ({ adviser, alertSummary, classes }) => {
  const pathToStudentList = `/admin/advisers/${adviser.id}?resetFilters=true`;
  const pathToEditAdviser = `/admin/users/${adviser.id}`;

  return (
    <div className={classes.root}>
      <div className={classes.leftContent}>
        <div className={classes.iconAndStatsWrapper}>
          <Link to={pathToEditAdviser} className={classes.avatarContainer}>
            <JuvoAvatar image={adviser.image} />
          </Link>

          <AdviserStats adviser={adviser} link={pathToStudentList} />
        </div>

        <div className={classes.adviserDetailsWrapper}>
          <Link to={pathToStudentList} className={classes.adviserNameLink}>
            <Typography className={cx(classes.title, { [classes.titleRed]: adviser.onLeave })} variant="h6">
              {adviser.name} {adviser.onLeave && <BeachAccessIcon />}
            </Typography>
          </Link>

          <div className={classes.email}>{adviser.email}</div>

          <div>
            {adviser.enabled && adviser.roles.map((role) => <Chip style={{ marginTop: 0 }} key={role} className={classes.chip} label={role} />)}
            <Tooltip title={adviser.calendlyUrl ? 'Open Calendly in new tab' : 'Adviser has no appointment URL set for Calendly'} placement="bottom">
              <span>
                <IconButton component="a" href={adviser.calendlyUrl} target="_blank" disabled={!adviser.calendlyUrl}>
                  <CalendarTodayIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={classes.alertCounts}>
        <Link to={`/admin/advisers/${adviser.id}?classification=RESPONSE`} className={cx(classes.alert, classes.responseAlert)}>
          <div className={classes.iconWrapper}>
            <PurpleIcon />
          </div>
          <div className={classes.textWrapper}>
            <span className={classes.alertCount}>{valueOrPlaceholder(alertSummary, 'responseAlertCount')}</span>
          </div>
        </Link>
        <Link to={`/admin/advisers/${adviser.id}?classification=ACTION`} className={cx(classes.alert, classes.actionAlert)}>
          <div className={classes.iconWrapper}>
            <RedIcon />
          </div>
          <div className={classes.textWrapper}>
            <span className={classes.alertCount}>{valueOrPlaceholder(alertSummary, 'actionAlertCount')}</span>
          </div>
        </Link>
        <Link to={`/admin/advisers/${adviser.id}?classification=INFORMATION`} className={cx(classes.alert, classes.informationAlert)}>
          <div className={classes.iconWrapper}>
            <AmberIcon />
          </div>
          <div className={classes.textWrapper}>
            <span className={classes.alertCount}>{valueOrPlaceholder(alertSummary, 'informationAlertCount')}</span>
          </div>
        </Link>
        <Link to={`/admin/advisers/${adviser.id}?classification=CELEBRATION`} className={cx(classes.alert, classes.celebrationAlert)}>
          <div className={classes.iconWrapper}>
            <GreenIcon />
          </div>
          <div className={classes.textWrapper}>
            <span className={classes.alertCount}>{valueOrPlaceholder(alertSummary, 'celebrationAlertCount')}</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

AdviserCard.propTypes = {
  adviser: PropTypes.object.isRequired,
  alertSummary: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

AdviserCard.defaultProps = {
  alertSummary: {},
};

const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: 10,
    display: 'flex',
    padding: '20px 20px 10px',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.15)',
  },
  leftContent: {
    flexGrow: 1,
  },
  iconAndStatsWrapper: {
    display: 'flex',
  },
  alertCounts: {
    flex: '0 0 100px',
  },
  alertCount: {
    fontSize: 16,
    fontWeight: 900,
    lineHeight: 2,
    marginRight: 5,
  },
  avatarContainer: {
    marginBottom: 13,
    marginRight: 15,
    borderRadius: 40,
  },
  adviserDetailsWrapper: {
    clear: 'both',
  },
  badge: {
    height: 15,
    width: 15,
    right: -20,
    top: -8,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1,
  },
  titleRed: {
    color: red[600],
  },
  alert: {
    padding: 5,
    marginBottom: 5,
    borderRadius: 3,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.25)',
    display: 'block',
    textDecoration: 'none',
    color: '#fff',
    transition: 'all .5s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      boxShadow: '0 5px 10px 2px rgba(0, 0, 0, 0.10)',
    },
  },
  iconWrapper: {
    float: 'left',
    marginTop: 4,
    marginLeft: 4,
    '& svg': {
      fontSize: 24,
    },
  },
  textWrapper: {
    marginLeft: 35,
    textAlign: 'right',
  },
  responseAlert: {
    backgroundColor: classificationColour('RESPONSE'),
    '&:hover': {
      backgroundColor: classificationColour('RESPONSE', true),
    },
  },
  actionAlert: {
    backgroundColor: classificationColour('ACTION'),
    '&:hover': {
      backgroundColor: classificationColour('ACTION', true),
    },
  },
  informationAlert: {
    backgroundColor: classificationColour('INFORMATION'),
    '&:hover': {
      backgroundColor: classificationColour('INFORMATION', true),
    },
  },
  celebrationAlert: {
    backgroundColor: classificationColour('CELEBRATION'),
    '&:hover': {
      backgroundColor: classificationColour('CELEBRATION', true),
    },
  },
  studentAlert: {
    backgroundColor: indigo[500],
    '&:hover': {
      backgroundColor: indigo[600],
    },
  },
  tempStudentAlert: {
    backgroundColor: pink[400],
    '&:hover': {
      backgroundColor: pink[500],
    },
  },
  description: {
    fontSize: 10,
    lineHeight: 1,
    marginTop: 3,
    fontWeight: 500,
  },
  chip: {
    height: 20,
    marginRight: 3,
    marginTop: 8,
  },
  email: {
    color: grey[500],
  },
  shortcuts: {
    marginTop: 5,
  },
  adviserNameLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
};

export default withStyles(styles)(AdviserCard);
