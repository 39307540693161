import { getLoggedInUserId, isAlertCompleted } from '../reducers';
import api from '../services/api';
import { asyncAction } from './actions';

const currentStudentIdParams = {
  params: (getState) => ({
    studentId: getLoggedInUserId(getState()),
  }),
};

export const fetchAdvisingAppointmentCount = (studentId) =>
  asyncAction('FETCH_ADVISING_APPOINTMENT_COUNT', api.comments.countAdvisingAppointments(studentId), { params: { studentId } });

const onCreateComment = (studentId) => (newComment, dispatch) => {
  if (newComment.advisingAppointment) {
    dispatch(fetchAdvisingAppointmentCount(studentId));
  }
  return newComment;
};

export const createInternalComment = (studentId, comment) =>
  asyncAction('CREATE_COMMENT', api.comments.createInternal(studentId, comment), {
    params: { studentId },
    onSuccess: onCreateComment(studentId),
  });

export const fetchInternalComments = (studentId) => asyncAction('FETCH_COMMENTS', api.comments.listInternal(studentId), { params: { studentId } });

export const createSharedComment = (studentId, comment) =>
  asyncAction('CREATE_COMMENT', api.comments.createShared(studentId, comment), { params: { studentId }, onSuccess: onCreateComment(studentId) });

export const fetchSharedComments = (studentId) => asyncAction('FETCH_COMMENTS', api.comments.listShared(studentId), { params: { studentId } });

export const createSharedCommentAsStudent = (comment) =>
  asyncAction('CREATE_COMMENT', api.comments.createSharedAsStudent(comment), currentStudentIdParams);

export const fetchSharedCommentsAsStudent = () => asyncAction('FETCH_COMMENTS', api.comments.listSharedAsStudent(), currentStudentIdParams);

export const moveAlert = (studentId, alertId) => (dispatch, getState) => {
  const state = getState();
  const alertCompleted = isAlertCompleted(state, alertId);
  return dispatch({
    type: 'MOVE_ALERT',
    studentId,
    alertId,
    alertCompleted,
  });
};
