import React, { useState } from 'react';
import Alert from 'react-s-alert';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { concat, filter } from 'lodash';
import { red } from '@material-ui/core/colors';
import bytes from 'bytes';
import Upload from '../common/Upload';
import { extensionsString, image, msOffice, pdf } from '../../util/file-extensions';
import AttachmentList from '../comments/AttachmentList';

export const MAX_SIZE_BYTES = bytes('100MB');

const useStyles = makeStyles((theme) => ({
  attachments: {
    marginTop: theme.spacing(2),
    paddingLeft: 10,
  },
  error: {
    color: red[500],
    marginTop: 5,
  },
}));

const DropzoneField = ({
  meta,
  accept,
  disabled,
  input,
  icon,
  iconPlacement,
  padded,
  onUploadingChanged,
  helpComponent,
  onUpload,
  label,
  multiple,
  maxSize,
  color,
  variant,
  disableList,
  uploadFile,
}) => {
  const classes = useStyles();
  const [uploading, setUploadingState] = useState(false);

  const setUploading = (state) => {
    setUploadingState(state);
    onUploadingChanged(state);
  };

  const uploadAttachments = (files) => {
    setUploading(true);
    const { onChange, value } = input;
    const uploads = [];
    files.forEach((file) => {
      uploads.push(
        uploadFile(file)
          .then((attachment) => {
            console.log('Uploaded attachment: ', attachment);
            return attachment;
          })
          .then((attachment) => Promise.resolve(onUpload(attachment)).then(() => attachment))
          .catch((err) => {
            Alert.error(`Error uploading file: ${file.name}. ${err.message}`);
            console.error('Error uploading file %o: ', file, err);
          }),
      );
    });

    Promise.all(uploads).then((attachments) => {
      if (attachments && attachments.length > 0) {
        if (multiple) {
          onChange([...filter(attachments, (e) => e != null), ...value]);
        } else {
          onChange(attachments[0]);
        }
      }
      setUploading(false);
    });
  };

  const removeAttachment = (attachment) => {
    const { onChange, value } = input;
    if (multiple) {
      onChange(value.filter((a) => a.id !== attachment.id));
    } else {
      onChange(null);
    }
  };

  return (
    <>
      <Upload
        handleUpload={uploadAttachments}
        accept={accept}
        label={label}
        uploading={uploading}
        maxSize={maxSize}
        tooltip={`Attach files (each max ${bytes(maxSize)})`}
        multiple={multiple}
        padded={padded}
        icon={icon}
        iconPlacement={iconPlacement}
        disabled={disabled}
        variant={variant}
        color={color}
      />
      {helpComponent}
      {!disableList && (
        <AttachmentList className={classes.attachments} attachments={input.value ? concat([], input.value) : []} onRemove={removeAttachment} />
      )}
      {meta.touched && meta.invalid && meta.error && <div className={classes.error}>{meta.error}</div>}
    </>
  );
};

DropzoneField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  onUploadingChanged: PropTypes.func,
  onUpload: PropTypes.func,
  disabled: PropTypes.bool,
  padded: PropTypes.bool,
  multiple: PropTypes.bool,
  icon: PropTypes.object,
  accept: PropTypes.string,
  meta: PropTypes.object.isRequired,
  helpComponent: PropTypes.node,
  maxSize: PropTypes.number,
  color: PropTypes.string,
  variant: PropTypes.string,
  iconPlacement: PropTypes.string,
  disableList: PropTypes.bool,
  uploadFile: PropTypes.func.isRequired,
};

DropzoneField.defaultProps = {
  disabled: false,
  padded: false,
  multiple: false,
  disableList: false,
  icon: null,
  label: 'Attach files',
  accept: `image/*,${extensionsString(msOffice, pdf, image)}`,
  helpComponent: undefined,
  onUploadingChanged: () => {},
  onUpload: () => {},
  maxSize: MAX_SIZE_BYTES,
  color: 'default',
  variant: 'outlined',
  iconPlacement: 'left',
};

export default DropzoneField;
