import * as PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import ErrorMessage from '../../common/ErrorMessage';
import AddCommentForm from '../../forms/AddCommentForm';
import StudentSearchCriteriaDisplayOnly from './StudentSearchCriteriaDisplayOnly';
import ChipDisplay from '../../common/ChipDisplay';
import DialogTitleWithClose from '../../common/DialogTitleWithClose';
import storage from '../../../services/api/storage';

const BulkMessageStudentsForm = ({
  students,
  onSubmit,
  errorMessages,
  allStudentsMatchingSearchSelected,
  searchCriteria,
  title,
  open,
  onCancel,
  formName,
}) => (
  <Dialog open={open} onClose={onCancel} fullWidth>
    <DialogTitleWithClose title={title} onClose={onCancel} />
    <DialogContent>
      <ErrorMessage errorMessages={errorMessages} />

      {allStudentsMatchingSearchSelected ? (
        <StudentSearchCriteriaDisplayOnly criteria={searchCriteria} />
      ) : (
        <ChipDisplay label="Students" entries={students.map((student) => ({ key: student.id, label: student.name }))} />
      )}

      <AddCommentForm onSubmit={onSubmit} label="Message text..." allowTemplates form={formName} promptForMissingAttachments uploadFile={(file) => storage.uploadStudentGroupMessageAttachment(file)}/>
    </DialogContent>
  </Dialog>
);

BulkMessageStudentsForm.defaultProps = {
  errorMessages: [],
};

BulkMessageStudentsForm.propTypes = {
  students: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allStudentsMatchingSearchSelected: PropTypes.bool.isRequired,
  errorMessages: PropTypes.array,
  searchCriteria: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

export default BulkMessageStudentsForm;
