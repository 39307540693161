import { orderBy, without } from 'lodash';
import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  if (action.response && action.response.entities) {
    return {
      ...state,
      ...action.response.entities.messageTemplates,
    };
  }
  if (action.type === 'MESSAGE_TEMPLATE_DELETE_SUCCESS') {
    const { [action.params.id]: remove, ...rest } = state;
    return rest;
  }

  return state;
};

const listIds = (state = { messageTemplates: [], loading: false }, action) => {
  switch (action.type) {
    case 'MESSAGE_TEMPLATE_DELETE_SUCCESS':
      return {
        ...state,
        messageTemplates: without(state.messageTemplates, action.params.id),
      };
    case 'MESSAGE_TEMPLATES_FETCH_SUCCESS':
      return {
        loading: false,
        messageTemplates: [...action.response.result],
      };
    case 'MESSAGE_TEMPLATE_CREATE_SUCCESS':
      return {
        ...state,
        messageTemplates: [...state.messageTemplates, action.response.result],
      };
    case 'MESSAGE_TEMPLATES_FETCH_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

const summaries = (state = { templates: [], loading: false }, action) => {
  switch (action.type) {
    case 'MESSAGE_TEMPLATE_SUMMARIES_FETCH_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'MESSAGE_TEMPLATE_SUMMARIES_FETCH_SUCCESS':
      return {
        templates: action.response,
        loading: false,
      };
    default:
      return state;
  }
};

export const getAllIsLoading = (state) => state.listIds.loading;
export const getAll = (state) => {
  const templates = state.listIds.messageTemplates.map((id) => state.byId[id]);
  return orderBy(templates, (template) => template.title.toLowerCase());
};

export const getById = (state, id) => state.byId[id];

export const getAllSummaries = (state) => state.summaries.templates;

export default combineReducers({
  byId,
  listIds,
  summaries,
});
