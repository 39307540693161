import { get, orderBy, pull } from 'lodash';
import { combineReducers } from 'redux';
import { appendToArrayField } from '../../util/objects';

const byId = (state = {}, action) => {
  if (get(action, 'response.entities.alerts')) {
    return {
      ...state,
      ...action.response.entities.alerts,
    };
  }

  switch (action.type) {
    case 'UPDATE_ALERT_TASK_STATUS_INPROGRESS': {
      const newState = {
        ...state,
      };
      newState[action.params.alertId].loading = true;
      return newState;
    }
    case 'UPDATE_ALERT_TASK_STATUS_FAILED': {
      const newState = {
        ...state,
      };
      newState[action.params.alertId].loading = false;
      return newState;
    }
    case 'UPDATE_ALERT_TASK_STATUS_SUCCESS': {
      const newState = {
        ...state,
      };
      newState[action.response.id] = action.response;
      return newState;
    }
    default:
      return state;
  }
};

const remove = (selectorState, studentId, alertId) => {
  const newState = { ...selectorState };
  pull(newState[studentId], alertId);
  return newState;
};
const add = (selectorState, studentId, alertId) => appendToArrayField(selectorState, studentId, alertId);

const openByStudent = (state = { loading: true }, action) => {
  switch (action.type) {
    case 'FETCH_STUDENT_ALERTS_OPEN_SUCCESS':
      return {
        loading: false,
        [action.params.studentId]: [...action.response.result],
      };
    case 'FETCH_STUDENT_ALERTS_OPEN_INPROGRESS':
    case 'CLOSE_STUDENT_ALERTS_OPEN_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'MOVE_ALERT':
      return action.alertCompleted ? remove(state, action.studentId, action.alertId) : add(state, action.studentId, action.alertId);
    default:
      return state;
  }
};

const completedByStudent = (state = { loading: true }, action) => {
  switch (action.type) {
    case 'FETCH_STUDENT_ALERTS_COMPLETED_SUCCESS':
      return {
        loading: false,
        [action.params.studentId]: [...action.response.result],
      };
    case 'FETCH_STUDENT_ALERTS_COMPLETED_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'MOVE_ALERT':
      return action.alertCompleted ? add(state, action.studentId, action.alertId) : remove(state, action.studentId, action.alertId);
    default:
      return state;
  }
};

export const getForStudent = (state, alertIds) => alertIds.map((alertId) => state.byId[alertId]);

export const getOpenForStudent = (state, id) => getForStudent(state, state.openByStudent[id] || []);

export const getCompletedForStudent = (state, id) => orderBy(getForStudent(state, state.completedByStudent[id] || []), 'updated', 'desc');

export const getById = (state, id) => state.byId[id];
export const getAllForStudentLoading = (state) => state.openByStudent.loading;
export const isAllCompletedForStudentLoading = (state) => state.completedByStudent.loading;

export default combineReducers({
  byId,
  openByStudent,
  completedByStudent,
});
