/* eslint-disable no-undef,react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import api from '../../services/api';
import './quill-extension.less';
import RichTextReduxComponent from './RichTextReduxComponent';

const RichTextReduxComponentWithQuickLinks = ({ ...rest }) => {
  const [quickLinks, updateQuickLinks] = useState({});
  const [quickLinksHaveBeenFetched, updateQuickLinksHaveBeenFetched] = useState(false);

  useEffect(() => {
    let isMounted = true;

    api.quickLinks
      .list()
      .then((response) => {
        if (isMounted) {
          updateQuickLinks(response);
        }
      })
      .then(() => {
        if (isMounted) {
          updateQuickLinksHaveBeenFetched(true);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  // cannot render the Quill component inside RichTextReduxComponent
  // until we definitely have these values as it's only initialised once
  // we do not use the presence of the quick links in case there genuinely
  // are none currently configured in the system, hence the separate flag
  return quickLinksHaveBeenFetched ? <RichTextReduxComponent quickLinks={quickLinks} {...rest} /> : null;
};

export default RichTextReduxComponentWithQuickLinks;
