import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { EmailOutlined as PurpleIcon, ErrorOutline as RedIcon, Grade as GreenIcon, HelpOutline as AmberIcon } from '@material-ui/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { valueOrPlaceholder } from '../../util/objects';
import { classificationColour } from '../../components/common/Classifications';

class AlertSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertSummary: null,
    };
  }

  componentDidMount() {
    api.advisers.alertSummary(this.props.adviser.id).then((alertSummary) => this.setState({ alertSummary }));
  }

  render() {
    const { classes } = this.props;
    const { alertSummary } = this.state;

    return (
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Link to="/adviser/students?classification=RESPONSE" className={cx(classes.alert, classes.responseAlert)}>
            <div className={classes.iconWrapper}>
              <PurpleIcon />
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.count}>{valueOrPlaceholder(alertSummary, 'responseAlertCount')}</div>
              <div className={classes.description}>Response alerts</div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Link to="/adviser/students?classification=ACTION" className={cx(classes.alert, classes.actionAlert)}>
            <div className={classes.iconWrapper}>
              <RedIcon />
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.count}>{valueOrPlaceholder(alertSummary, 'actionAlertCount')}</div>
              <div className={classes.description}>Action alerts</div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Link to="/adviser/students?classification=INFORMATION" className={cx(classes.alert, classes.informationAlert)}>
            <div className={classes.iconWrapper}>
              <AmberIcon />
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.count}>{valueOrPlaceholder(alertSummary, 'informationAlertCount')}</div>
              <div className={classes.description}>Information alerts</div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Link to="/adviser/students?classification=CELEBRATION" className={cx(classes.alert, classes.celebrationAlert)}>
            <div className={classes.iconWrapper}>
              <GreenIcon />
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.count}>{valueOrPlaceholder(alertSummary, 'celebrationAlertCount')}</div>
              <div className={classes.description}>Celebration alerts</div>
            </div>
          </Link>
        </Grid>
      </Grid>
    );
  }
}

AlertSummary.propTypes = {
  adviser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    marginBottom: 15,
    '& >div a': {
      height: 97,
    },
  },
  alert: {
    padding: 20,
    borderRadius: 3,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.25)',
    // textAlign: 'center',
    display: 'block',
    textDecoration: 'none',
    color: '#fff',
    transition: 'all .5s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      boxShadow: '0 5px 10px 2px rgba(0, 0, 0, 0.10)',
    },

    [theme.breakpoints.down('md')]: {
      padding: '20px 10px',
    },
  },
  iconWrapper: {
    float: 'left',
    marginTop: 3,
    '& svg': {
      fontSize: 55,
    },
  },
  textWrapper: {
    marginLeft: 75,
  },
  responseAlert: {
    backgroundColor: classificationColour('RESPONSE'),
    '&:hover': {
      backgroundColor: classificationColour('RESPONSE', true),
    },
  },
  actionAlert: {
    backgroundColor: classificationColour('ACTION'),
    '&:hover': {
      backgroundColor: classificationColour('ACTION', true),
    },
  },
  celebrationAlert: {
    backgroundColor: classificationColour('CELEBRATION'),
    '&:hover': {
      backgroundColor: classificationColour('CELEBRATION', true),
    },
  },
  informationAlert: {
    backgroundColor: classificationColour('INFORMATION'),
    '&:hover': {
      backgroundColor: classificationColour('INFORMATION', true),
    },
  },
  count: {
    fontSize: 30,
    fontWeight: 900,
    lineHeight: '40px',
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '40px',
    },
  },
  description: {
    fontSize: 14,
    lineHeight: 1,
    marginTop: 3,
    fontWeight: 500,
  },
  alertWrapper: {
    position: 'relative',
  },
});

export default withStyles(styles)(AlertSummary);
