import { orderBy, without } from 'lodash';
import { combineReducers } from 'redux';
import moment from 'moment';

const byId = (state = {}, action) => {
  if (action.response && action.response.entities) {
    return {
      ...state,
      ...action.response.entities.announcements,
    };
  }
  if (action.type === 'ANNOUNCEMENT_DELETE_SUCCESS') {
    const { [action.params.id]: remove, ...rest } = state;
    return rest;
  }

  return state;
};

const listIds = (state = { announcements: [], loading: false }, action) => {
  switch (action.type) {
    case 'ANNOUNCEMENT_DELETE_SUCCESS':
      return {
        ...state,
        announcements: without(state.announcements, action.params.id),
      };
    case 'ANNOUNCEMENT_CREATE_SUCCESS':
      return {
        ...state,
        announcements: [action.response.result, ...state.announcements],
      };
    case 'ANNOUNCEMENTS_FETCH_SUCCESS':
      return {
        loading: false,
        announcements: [...action.response.result],
      };
    case 'ANNOUNCEMENTS_FETCH_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export const isLoading = (state) => state.listIds.loading;

export const getAll = (state) => {
  const announcements = state.listIds.announcements.map((id) => state.byId[id]);
  return orderBy(announcements, (announcement) => moment(announcement.created).toISOString(), 'desc');
};

export default combineReducers({
  byId,
  listIds,
});
