import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StudentDashboardPage from '../pages/student/StudentDashboardPage';
import AsyncComponent from './AsyncComponent';
import { loginRequired } from './hooks';
import StudentLayout from '../pages/student/StudentLayout';

export default () => (
  <AsyncComponent asyncActions={[loginRequired]}>
    <StudentLayout>
      <Switch>
        <Route exact path="/student" component={StudentDashboardPage} />
        <Route exact path="/student/:page" component={StudentDashboardPage} />
      </Switch>
    </StudentLayout>
  </AsyncComponent>
);
