/* eslint-disable react/no-array-index-key */
import React from 'react';
import Alert from 'react-s-alert';
import commentsApi from '../services/api/comments';
import GroupComments from '../components/comments/GroupComments';

const GroupMessagePage = () => {
  const submitGroupMessage = ({ studentCsv, content, attachments }) =>
    commentsApi.createBulkSharedByCsv(studentCsv, content, attachments).then(() => Alert.success('Group message submitted'));

  return <GroupComments onSubmit={submitGroupMessage} countMessageSuffix="will be messaged" title="Group message students" />;
};

export default GroupMessagePage;
