import { Chip, makeStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  fakeField: {
    maxHeight: 125,
    overflowY: 'auto',
    border: '1px solid #ccc',
    padding: 5,
    marginBottom: 10,
    borderRadius: 4,
  },
  label: {
    color: grey[600],
    fontSize: 12,
    marginTop: 15,
    marginBottom: 3,
  },
  chip: {
    margin: 3,
    height: 25,
  },
});

const ChipDisplay = ({
  label: fieldLabel,
  entries,
}) => {
  const classes = useStyles();
  return (
    <div>
      { fieldLabel && <div className={classes.label}>{ fieldLabel }</div> }
      <div className={classes.fakeField}>
        {entries.map((entry) => {
          const { key, label } = entry;
          return <Chip key={key || label} label={label} className={classes.chip}/>;
        })}
      </div>
    </div>
  );
};

ChipDisplay.propTypes = {
  entries: PropTypes.array.isRequired,
  label: PropTypes.string,
};

ChipDisplay.defaultProps = {
  label: null,
};

export default ChipDisplay;

export const ChipComponentDisplay = ({
  label: fieldLabel,
  children,
}) => {
  const classes = useStyles();
  return (
    <div>
      { fieldLabel && <div className={classes.label}>{ fieldLabel }</div> }
      <div className={classes.fakeField}>{children}</div>
    </div>
  );
};

ChipComponentDisplay.propTypes = {
  children: PropTypes.object.isRequired,
  label: PropTypes.string,
};

ChipComponentDisplay.defaultProps = {
  label: null,
};
