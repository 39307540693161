import { Paper, Typography, withStyles } from '@material-ui/core';
import { array, object } from 'prop-types';
/* eslint-disable react/no-array-index-key,react/jsx-indent,react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import ErrorMessages from '../../components/forms/ErrorMessages';
import UserProfileForm from '../../components/forms/UserProfileForm';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getReferenceDataGenders, getReferenceDataRoles } from '../../reducers';
import api from '../../services/api';
import NotFoundPage from '../NotFoundPage';

class UserProfilePage extends Component {
  static propTypes = {
    match: object.isRequired,
    roles: array.isRequired,
    genders: array.isRequired,
    classes: object.isRequired,
  };

  state = {
    isFetching: true,
    user: null,
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.fetchUser(params.userId);
  }

  fetchUser(userId) {
    this.setState({ isFetching: true });

    api.users
      .get(userId)
      .then((user) => {
        this.setState({ isFetching: false, user });
        api.students
          .get(userId)
          .then((student) => this.setState({ adviserEmail: student.adviser.email }))
          .catch(() => console.log('Error retrieving student details. Probably not a student.'));
      })
      .catch((error) => {
        this.setState({ isFetching: false });

        if (error.type !== 'NotFoundException') {
          Alert.error(error.message);
        }
      });
  }

  handleSubmit = (values) => {
    const payload = { ...values };
    if (payload && payload.image) {
      payload.image.public = true;
    }
    return api.users
      .save(values)
      .then(() => Alert.success('User updated'))
      .catch((error) => {
        Alert.error(<ErrorMessages error={error} />);
        throw new SubmissionError({ _error: error });
      });
  };

  render() {
    const { isFetching, user, adviserEmail } = this.state;
    const { roles, genders, classes } = this.props;

    if (isFetching) {
      return <LoadingIndicator size={60} text="Loading..." />;
    }

    if (!isFetching && !user) {
      return <NotFoundPage />;
    }

    return (
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5">User profile</Typography>
          <div className={classes.subHeader}>
            <span className={classes.muted}>Allocated students:</span> {user.advisingStudentCount}
          </div>
        </div>
        <div className={classes.formContainer}>
          <UserProfileForm
            initialValues={user}
            roles={roles}
            genders={genders}
            adviserEmail={adviserEmail}
            advisingStudentCount={user.advisingStudentCount}
            adviserGreetingName={user.greetingName}
            onSubmit={this.handleSubmit}
            previewAvatar
          />
        </div>
      </Paper>
    );
  }
}

const refDataToValueLabel = (orig) =>
  orig.map((input) => ({
    value: input.id,
    label: input.description,
  }));

const mapStateToProps = (state) => ({
  roles: refDataToValueLabel(getReferenceDataRoles(state)),
  genders: refDataToValueLabel(getReferenceDataGenders(state)),
});

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    minHeight: theme.spacing(10),
  },
  subHeader: {
    fontSize: '1.2rem',
    color: '#424242',
  },
  muted: {
    fontWeight: 200,
  },
  formContainer: {
    maxWidth: 600,
  },
});

export default withStyles(styles)(connect(mapStateToProps)(UserProfilePage));
