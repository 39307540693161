import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, List, ListItem, makeStyles, Slide, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Notifications as NotificationIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getReferenceDataDescription } from '../../reducers';
import api from '../../services/api';
import '../LoadingIndicator.less';
import DialogTitleWithClose from '../common/DialogTitleWithClose';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContainer: {
      maxHeight: 600,
      margin: 'auto',
    },
    showButtonIcon: {
      marginRight: theme.spacing(1),
      color: grey[600],
    },
    loadingIndicator: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
    alertCountRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    alertCountValue: {
      fontWeight: 800,
      width: 85,
      textAlign: 'right',
      marginRight: theme.spacing(2),
    },
    alertCountDescription: {
      alignSelf: 'flex-end',
    },
  }),
);

const NO_ALERTS = {};

const AdviserAlertBreakdown = ({ adviserId, link }) => {
  const [loading, updateLoading] = useState(false);
  const [dialogOpen, updateDialogOpen] = useState(false);
  const [alertCounts, updateAlertCounts] = useState(NO_ALERTS);
  const getAlertDescription = useSelector((state) => (id) => getReferenceDataDescription(state, 'AlertType', id));
  const classes = useStyles();
  const history = useHistory();

  const onClose = () => updateDialogOpen(false);

  useEffect(() => {
    updateLoading(true);
    api.advisers
      .listAlertFrequency(adviserId)
      .then((response) => updateAlertCounts(response))
      .finally(() => updateLoading(false));
  }, []);

  return (
    <>
      <Button onClick={() => updateDialogOpen(true)}>
        <NotificationIcon className={classes.showButtonIcon} />
        Show alert counts
      </Button>
      <Dialog open={dialogOpen} onClose={onClose} className={classes.dialogContainer} TransitionComponent={Slide} fullWidth>
        <DialogTitleWithClose title="Alert counts" onClose={onClose} />
        {loading ? (
          <div className={classes.loadingIndicator}>
            <CircularProgress className="spinner" />
          </div>
        ) : (
          <DialogContent>
            {Object.keys(alertCounts).length > 0 ? (
              <List>
                {Object.keys(alertCounts).map((alertCountKey) => (
                  <ListItem key={alertCountKey} button onClick={() => history.push(`${link}&alertTypes=${alertCountKey}`)}>
                    <div className={classes.alertCountRow}>
                      <Typography className={classes.alertCountValue} variant="h4">
                        {alertCounts[alertCountKey]}
                      </Typography>
                      <Typography variant="subtitle1" className={classes.alertCountDescription}>
                        {getAlertDescription(alertCountKey)}
                      </Typography>
                    </div>
                  </ListItem>
                ))}
              </List>
            ) : (
              <DialogContentText>No open alerts</DialogContentText>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AdviserAlertBreakdown.propTypes = {
  adviserId: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default AdviserAlertBreakdown;
