import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAdvisers } from '../../../actions/advisers';
import { getAllAdvisers } from '../../../reducers';
import BulkMessageStudents from './BulkMessageStudents';
import ReallocateStudents from './ReallocateStudents';
import CompleteAlerts from './CompleteAlerts';
import ArchiveStudents from './ArchiveStudents';
import _ from 'lodash';

const StudentActionsMenu = ({
  students,
  searchCriteria,
  adviser,
  allowBulkMessage,
  allStudentsMatchingSearchSelected,
  studentTotalMatchCount,
  onActionCompleted,
}) => {
  const allAdvisers = useSelector((state) => getAllAdvisers(state));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllAdvisers());
  }, []);

  return (
    <div>
      <CompleteAlerts
        allStudentsMatchingSearchSelected={allStudentsMatchingSearchSelected}
        enabled={!!searchCriteria.alertTypes && !!searchCriteria.alertTypes.length}
        adviser={adviser}
        studentTotalMatchCount={studentTotalMatchCount}
        students={students}
        searchCriteria={searchCriteria}
        onActionCompleted={onActionCompleted}
      />

      <ReallocateStudents
        students={students}
        advisers={allAdvisers}
        onActionCompleted={onActionCompleted}
        enabled={!allStudentsMatchingSearchSelected}
      />

      <BulkMessageStudents
        allStudentsMatchingSearchSelected={allStudentsMatchingSearchSelected}
        enabled={allowBulkMessage}
        adviser={adviser}
        studentTotalMatchCount={studentTotalMatchCount}
        students={students}
        searchCriteria={searchCriteria}
        onActionCompleted={onActionCompleted}
      />

      <ArchiveStudents
        enabled={_.includes(adviser.roles, 'ADMIN')}
        students={students}
        onActionCompleted={onActionCompleted}
      />
    </div>
  );
};

StudentActionsMenu.propTypes = {
  students: PropTypes.array.isRequired,
  searchCriteria: PropTypes.object.isRequired,
  adviser: PropTypes.object.isRequired,
  allowBulkMessage: PropTypes.bool.isRequired,
  allStudentsMatchingSearchSelected: PropTypes.bool.isRequired,
  studentTotalMatchCount: PropTypes.number.isRequired,
  onActionCompleted: PropTypes.func.isRequired,
};

export default StudentActionsMenu;
