import { Button, Card, CardActions, CardContent, CardHeader, withStyles } from '@material-ui/core';
import moment from 'moment';
import { array, bool, func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAllAnnouncements } from '../../actions/announcements';
import AnnouncementList from '../../components/announcements/AnnouncementList';
import Progress from '../../components/common/Progress';
import { getAllAnnouncements, isGetAnnouncementsLoading } from '../../reducers';

const styles = {
  root: {
    marginBottom: 27,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
};

class AnnouncementsWidgetInner extends Component {
  static propTypes = {
    announcements: array.isRequired,
    fetchAnnouncements: func.isRequired,
    classes: object.isRequired,
    loading: bool.isRequired,
  };

  componentDidMount() {
    this.props.fetchAnnouncements();
  }

  render() {
    const { announcements, loading, classes } = this.props;
    const sevenDaysAgo = moment().subtract({ day: 7 });
    const recentAnnouncements = announcements.filter((announcement) => moment(announcement.created).isAfter(sevenDaysAgo));
    return (
      <Card className={classes.root}>
        <CardHeader title="Recent announcements" />
        <CardContent className={classes.content}>
          {loading ? (
            <Progress />
          ) : (
            <AnnouncementList announcements={recentAnnouncements} noAnnouncementsMessage="No new announcements in the past 7 days" concise />
          )}
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" component={Link} to="/adviser/announcements">
            View older than 7 days
          </Button>
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  announcements: getAllAnnouncements(state),
  loading: isGetAnnouncementsLoading(state),
});

const mapDispatchToProps = {
  fetchAnnouncements: fetchAllAnnouncements,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AnnouncementsWidgetInner));
