import { CircularProgress, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCompletedStudentAlerts } from '../../actions/alerts';
import { getCompletedAlertsForStudent, isCompletedAlertsForStudentLoading } from '../../reducers';
import Alert from '../alerts/Alert';

const completedAlerts = (classes, alerts, studentId) => {
  if (!alerts.length) {
    return <div className={classes.noAlerts}>No completed alerts.</div>;
  }

  return (
    <div>
      {alerts.map((alert) => (
        <Alert key={alert.id} alert={alert} studentId={studentId} />
      ))}
    </div>
  );
};

class CompletedAlerts extends Component {
  componentDidMount() {
    const { studentId, fetchStudentAlertsFromServer } = this.props;
    fetchStudentAlertsFromServer(studentId);
  }

  render() {
    const { loading, alerts, studentId, classes } = this.props;

    return (
      <Paper className={classes.root} elevation={1}>
        {loading ? <CircularProgress /> : completedAlerts(classes, alerts, studentId)}
      </Paper>
    );
  }
}

CompletedAlerts.propTypes = {
  studentId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchStudentAlertsFromServer: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  alerts: PropTypes.array,
};

CompletedAlerts.defaultProps = {
  alerts: [],
};

const styles = (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 24,
    paddingBottom: 16,
  }),
  noAlerts: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = (state, { studentId }) => ({
  alerts: getCompletedAlertsForStudent(state, studentId),
  loading: isCompletedAlertsForStudentLoading(state),
});
const mapDispatchToProps = {
  fetchStudentAlertsFromServer: fetchCompletedStudentAlerts,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CompletedAlerts));
