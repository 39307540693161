/* eslint-disable react/no-array-index-key */
import { Button, Paper, Typography, withStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { push } from 'connected-react-router';
import { array, bool, func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteMessageTemplate, fetchMessageTemplates } from '../../actions/message-templates';
import Progress from '../../components/common/Progress';
import MessageTemplateList from '../../components/message-templates/MessageTemplateList';
import { getMessageTemplates, isMessageTemplatesLoading } from '../../reducers';

class MessageTemplatesPage extends Component {
  static propTypes = {
    messageTemplates: array.isRequired,
    fetchMessageTemplatesFromServer: func.isRequired,
    deleteItem: func.isRequired,
    create: func.isRequired,
    update: func.isRequired,
    loading: bool.isRequired,
    classes: object.isRequired,
  };

  componentDidMount() {
    this.props.fetchMessageTemplatesFromServer();
  }

  render() {
    const { messageTemplates, classes, loading, deleteItem, create, update } = this.props;

    return (
      <>
        <Paper>
          <div className={classes.header}>
            <Typography variant="h5">Message templates</Typography>
            <div className="user-actions">
              <Button variant="contained" color="primary" onClick={create}>
                <AddIcon className={classes.iconLeft} />
                Create
              </Button>
            </div>
          </div>
        </Paper>

        <div className={classes.content}>
          {loading ? <Progress /> : <MessageTemplateList messageTemplates={messageTemplates} updateItem={update} deleteItem={deleteItem} />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  messageTemplates: getMessageTemplates(state),
  loading: isMessageTemplatesLoading(state),
});

const mapDispatchToProps = {
  fetchMessageTemplatesFromServer: fetchMessageTemplates,
  deleteItem: deleteMessageTemplate,
  create: () => push('/admin/message-templates/new'),
  update: (template) => push(`/admin/message-templates/${template.id}`),
};

const styles = (theme) => ({
  header: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(13),
  },
  iconLeft: {
    marginRight: 5,
  },
  content: {
    marginTop: 20,
  },
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MessageTemplatesPage));
