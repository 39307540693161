/* eslint-disable react/no-array-index-key */
import { Button, IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Add as AddIcon, CloudUpload as UploadIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import Progress from '../../components/common/Progress';
import FlagList from '../../components/flags/FlagList';
import flagsApi from '../../services/api/flags';
import Upload from '../../components/common/Upload';
import { uploadStudentFlagsCsv } from '../../actions/students';
import ErrorMessages from '../../components/forms/ErrorMessages';
import FormDialog from '../../components/forms/FormDialog';
import CreateFlagForm from '../../components/forms/CreateFlagForm';
import FlagCsvHelp from '../../components/flags/FlagCsvHelp';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  header: {
    position: 'relative',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(16),
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
  link: {
    padding: theme.spacing(0, 1),
  },
  actions: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(),
    },
  },
  subActions: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: 0,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 2),
    margin: 0,
  },
}));

const FlagsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [flags, setFlags] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [createFlagError, setCreateFlagError] = useState(undefined);

  const loadFlags = () => {
    setFlags(undefined);
    return flagsApi.list().then((res) => setFlags(res));
  };

  const createFlag = ({ text, classification }) =>
    flagsApi
      .create(text, classification)
      .then(() => setDialogOpen(false))
      .then(() => setCreateFlagError(undefined))
      .then(() => Alert.success('Flag created'))
      .then(() => loadFlags())
      .catch((error) => setCreateFlagError(error));

  const uploadStudentFlags = (file) => {
    setUploading(false);
    dispatch(uploadStudentFlagsCsv(file))
      .then(() => {
        Alert.success('Student flags queued for creation');
      })
      .catch((error) => {
        Alert.error(<ErrorMessages error={error} />);
        throw new SubmissionError({ _error: error });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  useEffect(() => {
    loadFlags();
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">Flags</Typography>
        <div className={classes.actions}>
          <FlagCsvHelp className={classes.iconLeft} />
          <Upload
            handleUpload={([file]) => uploadStudentFlags(file)}
            accept="text/csv,.csv"
            label="CSV Upload"
            variant="outlined"
            uploading={uploading}
            icon={<UploadIcon className={classes.iconLeft} />}
            iconPlacement="left"
            padded
          />
          <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
            <AddIcon className={classes.iconLeft} />
            Create
          </Button>
        </div>
        <div className={classes.subActions}>
          <Tooltip title="Refresh" aria-label="Refresh results">
            <div>
              <IconButton onClick={loadFlags} disabled={!flags}>
                <RefreshIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
      {flags ? <FlagList flags={flags} onUpdate={loadFlags} /> : <Progress />}

      <FormDialog
        title="Create flag"
        submitButtonText="Create"
        formComponent={CreateFlagForm}
        formName="createFlag"
        open={dialogOpen}
        onCancel={() => {
          setDialogOpen(false);
          setCreateFlagError(undefined);
        }}
        onSubmit={createFlag}
        serverError={createFlagError}
      />
    </Paper>
  );
};

export default FlagsPage;
