import { Button, makeStyles, MenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { Face as FaceIcon } from '@material-ui/icons';
import bytes from 'bytes';
import { array, bool, func, number, object, string } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { email, format, length, numericality, required, url } from 'redux-form-validators';
import { ADVISER_CAMPUS_LOCATIONS } from '../../pages/admin/AdviserCampusLocations';
import AboutMeDialog from '../students/AboutMeDialog';
import { TextField } from '../wrappers/material';
import DropzoneField from './DropzoneField';
import MultiSelect from './MultiSelect';
import RichTextReduxComponentWithQuickLinks from './RichTextReduxComponentWithQuickLinks';
import storage from '../../services/api/storage';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  standardFields: {
    padding: theme.spacing(3),
  },
  note: {
    color: '#1E1E1E',
  },
  selectField: {
    marginTop: 20,
  },
  ssoFields: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.light,
    borderRadius: 3,
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: 75,
    height: 75,
  },
  avatarAssistiveText: {
    marginTop: theme.spacing(),
  },
  avatarIcon: {
    marginRight: theme.spacing(),
  },
  actions: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    '& :nth-child(n+2)': {
      marginRight: theme.spacing(),
    },
  },
  signature: {
    marginTop: theme.spacing(2),
  },
  signatureLabel: {
    marginBottom: theme.spacing(1),
  },
  aboutMeDialog: {
    marginTop: theme.spacing(1),
    textAlign: 'end',
  },
}));

const UserProfileForm = ({
  handleSubmit,
  onImageChange,
  onCancel,
  submitting,
  roles,
  genders,
  concise,
  adviserEmail,
  adviserGreetingName,
  advisingStudentCount,
  initialValues,
  previewAvatar,
}) => {
  const classes = useStyles();
  const [linkToAvatar, updateLinkToAvatar] = useState(initialValues.image ? initialValues.image.requestLink : null);
  const [attachmentUploading, updateAttachmentUploading] = useState(false);
  const formSelector = formValueSelector('userProfile');
  const aboutMeDraft = useSelector((state) => formSelector(state, 'aboutMe'));
  const onAvatarChange = (updatedAvatar) => {
    const requestLink = updatedAvatar ? updatedAvatar.requestLink : null;
    updateLinkToAvatar(requestLink);
    onImageChange({
      ...updatedAvatar,
      requestLink,
    });
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={classes.avatarContainer}>
        <div>
          <Field
            name="image"
            component={DropzoneField}
            padded
            icon={<FaceIcon className={classes.avatarIcon} />}
            label="Upload profile photo"
            maxSize={bytes('20KB')}
            multiple={false}
            accept="image/*"
            onUploadingChanged={(uploadingFlag) => updateAttachmentUploading(uploadingFlag)}
            onChange={onAvatarChange}
            disableList
            uploadFile={(file) => storage.uploadStaffProfileImage(file, initialValues.id)}
          />
        </div>
        {previewAvatar && linkToAvatar && (
          <div>
            <Avatar className={classes.avatar} alt="avatar" src={linkToAvatar} />
          </div>
        )}
      </div>
      <div className={classes.avatarAssistiveText}>
        <Typography variant="caption">
          For optimal results image should contain a headshot near the centre of the image. The file size must be less than 20KB.
        </Typography>
      </div>
      <div className={classes.standardFields}>
        <Field name="gender" className={classes.selectField} component={TextField} select label="Gender" validate={[required()]} fullWidth>
          {genders.map((gender) => (
            <MenuItem key={gender.value} value={gender.value}>
              {gender.label}
            </MenuItem>
          ))}
        </Field>

        <Field
          name="campusLocation"
          className={classes.selectField}
          component={TextField}
          select
          label="Campus location"
          validate={[required()]}
          fullWidth
        >
          {ADVISER_CAMPUS_LOCATIONS.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </Field>

        <Field
          name="calendarUrl"
          component={TextField}
          placeholder="Google calendar URL"
          label="Google calendar URL"
          margin="normal"
          validate={[url({ protocol: 'https', message: 'Must be a valid https:// calendar url', allowBlank: true })]}
          fullWidth
        />
        <Field
          name="calendlyUrl"
          component={TextField}
          placeholder="Calendly calendar URL"
          label="Calendly calendar URL"
          margin="normal"
          validate={[
            url({ protocol: 'https', message: 'Must be a valid Calendly URL starting with https://calendly.com/', allowBlank: true }),
            format({
              with: /^https:\/\/calendly\.com\/.+$/i,
              message: 'Must be a valid Calendly url starting with https://calendly.com/',
              allowBlank: true,
            }),
          ]}
          fullWidth
        />
        <Field
          name="studentCap"
          component={TextField}
          placeholder="Maximum number of students"
          label="Student cap"
          margin="normal"
          fullWidth
          type="number"
          InputProps={{ inputProps: { min: advisingStudentCount } }}
          validate={[numericality({ greaterThan: 0, greaterThanOrEqualTo: advisingStudentCount.toString(), allowBlank: true })]}
        />
        <Field
          name="aboutMe"
          label="About me"
          placeholder="If you would like the student to see a bio about you, enter it here"
          fullWidth
          component={TextField}
          multiline
          rows="5"
        />
        {aboutMeDraft && (
          <div className={classes.aboutMeDialog}>
            <AboutMeDialog
              openLinkText="Preview student view"
              adviserGreetingName={adviserGreetingName}
              adviserAboutMeText={aboutMeDraft}
              adviserImage={{ requestLink: linkToAvatar }}
            />
          </div>
        )}
        <div className={classes.signature}>
          <InputLabel className={classes.signatureLabel}>Signature</InputLabel>
          <Field
            name="signature"
            component={RichTextReduxComponentWithQuickLinks}
            label="If you would like a message signature, enter it here"
            type="text"
            margin="normal"
            rows="8"
            multiline
            validate={[length({ max: 3000, msg: 'Signature too long. Please shorten before saving' })]}
            maxHeight={300}
            fullWidth
          />
        </div>

        {adviserEmail && <TextField name="adviser" margin="normal" label="Adviser" value={adviserEmail} disabled fullWidth />}
      </div>
      {!concise && (
        <div className={classes.ssoFields}>
          <div className={classes.note}>
            <strong>Note:</strong> These fields are overwritten when user logs in via Okta.
          </div>

          <Field name="givenNames" margin="normal" component={TextField} placeholder="Given names" label="Given names" fullWidth />
          <Field name="surname" margin="normal" component={TextField} placeholder="Your surname" label="Surname" validate={required()} fullWidth />
          <Field
            name="email"
            component={TextField}
            placeholder="Your email address"
            label="Email"
            type="email"
            margin="normal"
            validate={[required(), email('Enter a valid email')]}
            fullWidth
          />
          <Field name="roles" component={MultiSelect} options={roles} label="Roles" margin="normal" />
        </div>
      )}

      <div className={classes.actions}>
        {onCancel && (
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button variant="contained" type="submit" disabled={submitting || attachmentUploading} color="primary">
          {submitting ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

UserProfileForm.propTypes = {
  handleSubmit: func.isRequired,
  onImageChange: func,
  onCancel: func,
  submitting: bool.isRequired,
  roles: array,
  genders: array.isRequired,
  initialValues: object.isRequired,
  advisingStudentCount: number.isRequired,
  adviserEmail: string,
  adviserGreetingName: string.isRequired,
  concise: bool,
  previewAvatar: bool,
};

UserProfileForm.defaultProps = {
  onCancel: undefined,
  onImageChange: () => {},
  roles: [],
  adviserEmail: '',
  concise: false,
  previewAvatar: false,
};

export default reduxForm({ form: 'userProfile' })(UserProfileForm);
