import { includes, isArray, some } from 'lodash';
import { any, arrayOf, oneOfType, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as model from '../../model';
import { getLoggedInUser } from '../../reducers';

const hasAnyRole = (user, roles) => {
  if (!user || !roles) {
    return false;
  }
  const requiredRoles = (isArray(roles) ? roles : [roles]).map((r) => r.toUpperCase());
  return !!user && some(user.roles, (r) => includes(requiredRoles, r.toUpperCase()));
};

/**
 * Only render children if the logged in user is authorised.
 * If no roles are provided, then it just ensures there is a current logged in user.
 * If roles are provided as an array it checks if the current user has any role (case-insensitive).
 * If roles are provided as a string it checks if the current user has the role (case-insensitive).
 */
export const AuthorisedInner = ({ roles, children, user }) => ((!roles && !!user) || hasAnyRole(user, roles)
  ? <>{children}</>
  : null
);

AuthorisedInner.propTypes = {
  roles: oneOfType([string, arrayOf(string)]),
  children: any.isRequired,
  user: model.user,
};

AuthorisedInner.defaultProps = {
  roles: undefined,
  user: undefined,
};

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
});

export default connect(mapStateToProps)(AuthorisedInner);
