import React from 'react';
import { Tooltip, Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';

const JuvoTooltip = ({ title, placement, children }) => (
  <Tooltip title={title} placement={placement} enterDelay={200} leaveDelay={200} TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }}>
    {children}
  </Tooltip>
);

JuvoTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.object.isRequired,
};

JuvoTooltip.defaultProps = {
  placement: 'top',
};

export default JuvoTooltip;
