import { combineReducers } from 'redux';

const defaultContentState = { value: null, loading: false };

const settings = (state = defaultContentState, action) => {
  switch (action.type) {
    case 'APPLICATION_SETTINGS_FETCH_SUCCESS':
    case 'APPLICATION_SETTINGS_UPDATE_SUCCESS':
      return {
        values: action.response,
        loading: false,
      };
    case 'APPLICATION_SETTINGS_FETCH_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'APPLICATION_SETTINGS_UPDATE_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export const getSettings = (state) => state.applicationSettings.settings.values;
export const getLoading = (state) => state.applicationSettings.settings.loading;

export default combineReducers({
  settings,
});
