import { Button, Grid, makeStyles, MenuItem } from '@material-ui/core';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-s-alert';
import { Field, reduxForm } from 'redux-form';
import { email, length, required } from 'redux-form-validators';
import { fetchMessageTemplateSummaries } from '../../actions/message-templates';
import { getMessageTemplateSummaries } from '../../reducers';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { Checkbox, TextField } from '../wrappers/material';
import DropzoneField from './DropzoneField';
import FormError from './FormError';
import RichTextReduxComponentWithQuickLinks from './RichTextReduxComponentWithQuickLinks';

function validateTemplateVariables(value) {
  const isInvalid = value.search(/&lt;insert|<insert/g) > -1;
  return isInvalid
    ? 'There are still unreplaced variables in your template. Please look through your message for the text <insert :something:> and replace it.'
    : null;
}

const useStyles = makeStyles((theme) => ({
  templateSelect: {
    marginBottom: theme.spacing(2),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
  },
  actionsRight: {
    textAlign: 'right',
  },
  subActions: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(),
    },
  },
  saveDraftIcon: {
    marginRight: theme.spacing(1),
  },
  actionsGroup: {
    marginBottom: theme.spacing(2),
  },
  advisingAppointmentCheckbox: {
    marginBottom: theme.spacing(2),
  },
}));

const AddCommentForm = ({
  error,
  submitting,
  label,
  studentId,
  allowTemplates,
  inModal,
  supportAdditionalRecipient,
  supportAdvisingAppointment,
  validateTemplateVariablesReplaced,
  preventNewMessages,
  disableAttachments,
  handleSubmit,
  onSaveDraft,
  draftSaved,
  buttonColor,
  initialize,
  change,
  onClear,
  promptForMissingAttachments,
  onSubmit,
  children,
  disabled,
  viewingAsStudent,
  uploadFile,
}) => {
  const [contentLoading, setContentLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const messageTemplates = useSelector((state) => getMessageTemplateSummaries(state));
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (allowTemplates) {
      dispatch(fetchMessageTemplateSummaries());
    }
  }, []);

  const fetchTemplateContent = (templateId) => {
    change('content', 'Loading template content. Please wait ...');
    setContentLoading(true);
    api.messageTemplates
      .getContent(templateId, studentId)
      .then((resp) => change('content', resp.value))
      .catch((err) => Alert.error(err.message))
      .finally(() => setContentLoading(false));
  };

  const reset = () => {
    // Re-initialising form here because Redux's reset() will reset back to initialValues
    // and we have functionality that can copy a student note into this form - should be clearable.
    initialize({});
    if (onClear) {
      onClear();
    }
  };

  const doSubmit = (form) =>
    Promise.resolve(onSubmit(form))
      .catch((err) => Alert.error(`Error: ${err.message}`))
      .then(() => reset());

  const onClickSend = (form) => {
    const attachmentInText = form.content.search(/attachment|attached/gim) >= 0;

    if (promptForMissingAttachments && attachmentInText && !form.attachments) {
      setDialogOpen(true);
      setFormValues(form);
      return Promise.resolve();
    }
    return doSubmit(form);
  };

  const onAgreeToConfirmation = () => {
    const formVals = { ...formValues };
    setDialogOpen(false);
    setFormValues(null);
    return doSubmit(formVals);
  };

  const validations = [required({ msg: 'Please add some text' }), length({ max: 10000, msg: 'Message too long. Please shorten before submitting' })];
  if (validateTemplateVariablesReplaced) validations.push(validateTemplateVariables);

  const optionalEmail = (inputValue) => {
    const emailValidator = email();
    if (inputValue) return emailValidator(inputValue);

    return null;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onClickSend)} className={classes.root}>
        <FormError value={error} />
        {children}

        {allowTemplates && (
          <Field
            name="templateId"
            className={classes.templateSelect}
            component={TextField}
            select
            label={messageTemplates.length ? 'Select a template to use' : 'Loading templates ...'}
            onChange={(e) => fetchTemplateContent(e.target.value)}
            disabled={preventNewMessages || !messageTemplates.length}
            fullWidth
          >
            {messageTemplates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.title}
              </MenuItem>
            ))}
          </Field>
        )}
        <Field
          autoFocus
          disabled={preventNewMessages || contentLoading}
          className={cx('resizeable-textarea')}
          name="content"
          component={RichTextReduxComponentWithQuickLinks}
          label={label}
          type="text"
          margin="normal"
          rows="6"
          multiline
          validate={validations}
          fullWidth
          viewingAsStudent={viewingAsStudent}
        />
        {supportAdditionalRecipient && (
          <Field
            name="additionalRecipient"
            margin="normal"
            component={TextField}
            placeholder="example@test.com"
            label="Additional recipient"
            fullWidth
            validate={optionalEmail}
          />
        )}

        <Grid className={classes.actionsContainer} container justify="space-between">
          {supportAdvisingAppointment && (
            <Grid className={classes.advisingAppointmentCheckbox} item xs={12}>
              <Field
                name="advisingAppointment"
                color="primary"
                margin="normal"
                type="checkbox"
                component={Checkbox}
                label="Advising appointment"
                fullWidth
              />
            </Grid>
          )}
          {!disableAttachments && (
            <Grid item className={classes.actionsGroup}>
              <Field
                name="attachments"
                label="Attach"
                component={DropzoneField}
                onUploadingChanged={setUploading}
                disabled={preventNewMessages}
                color="default"
                multiple
                uploadFile={uploadFile}
              />
            </Grid>
          )}

          <Grid item className={cx(classes.actionsGroup, classes.actionsRight)}>
            {!inModal && (
              <>
                <Button type="button" disabled={preventNewMessages || submitting || contentLoading} onClick={reset}>
                  Clear
                </Button>
                {!!onSaveDraft && (
                  <Button variant="outlined" onClick={onSaveDraft} type="button" disabled={draftSaved}>
                    {draftSaved ? 'Saved' : 'Save draft'}
                  </Button>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  disabled={preventNewMessages || submitting || contentLoading || uploading || disabled}
                  color={buttonColor}
                >
                  {submitting ? 'Submitting...' : 'Submit'}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </form>

      <ConfirmationDialog
        content="You mentioned the word 'attachment' or 'attached' in your message, but didn't attach a file. Do you still want to send this message?"
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onConfirm={onAgreeToConfirmation}
      />
    </>
  );
};

AddCommentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  studentId: PropTypes.string,
  allowTemplates: PropTypes.bool,
  error: PropTypes.any,
  submitting: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  inModal: PropTypes.bool,
  supportAdditionalRecipient: PropTypes.bool,
  supportAdvisingAppointment: PropTypes.bool,
  validateTemplateVariablesReplaced: PropTypes.bool,
  preventNewMessages: PropTypes.bool,
  disableAttachments: PropTypes.bool,
  promptForMissingAttachments: PropTypes.bool,
  onSaveDraft: PropTypes.func,
  onClear: PropTypes.func,
  draftSaved: PropTypes.bool,
  buttonColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  disabled: PropTypes.bool,
  viewingAsStudent: PropTypes.bool,
  uploadFile: PropTypes.func.isRequired,
};

AddCommentForm.defaultProps = {
  studentId: null,
  error: undefined,
  allowTemplates: false,
  inModal: false,
  supportAdditionalRecipient: false,
  supportAdvisingAppointment: false,
  validateTemplateVariablesReplaced: false,
  preventNewMessages: false,
  disableAttachments: false,
  promptForMissingAttachments: false,
  onSaveDraft: null,
  onClear: null,
  draftSaved: false,
  buttonColor: 'primary',
  children: undefined,
  disabled: false,
  viewingAsStudent: false,
};

const InternalCommentForm = reduxForm({ form: 'addStudentCommentInternalForm', destroyOnUnmount: false })(AddCommentForm);
const SharedCommentForm = reduxForm({ form: 'addStudentCommentForm', destroyOnUnmount: false })(AddCommentForm);

const AddCommentFormWrapper = ({ internal, ...rest }) => (internal ? <InternalCommentForm {...rest} /> : <SharedCommentForm {...rest} />);
AddCommentFormWrapper.propTypes = {
  internal: PropTypes.bool,
};
AddCommentFormWrapper.defaultProps = {
  internal: false,
};

export default AddCommentFormWrapper;
