import api from '../services/api';
import { asyncAction } from './actions';

export const fetchAllAdvisers = () => asyncAction(
  'ADVISERS_FETCH_ALL',
  api.advisers.list(),
);

export const startAdviserLeave = (adviserId) => asyncAction(
  'ADVISERS_START_LEAVE',
  api.advisers.startLeave(adviserId),
);

export const endAdviserLeave = (adviserId) => asyncAction(
  'ADVISERS_END_LEAVE',
  api.advisers.endLeave(adviserId),
);
