import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import sortBy from 'lodash/sortBy';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { TextField } from '../../wrappers/material';
import flagsApi from '../../../services/api/flags';
import { getReferenceData } from '../../../reducers';
import MultiSelect from '../../forms/MultiSelect';
import LabelledCheckbox from '../../forms/LabelledCheckbox';
import StudentFlagsField, { multiSelectWithClassificationStyles } from '../../forms/StudentFlagsField';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(),
  },
  alertTypesWrapper: {
    marginTop: theme.spacing(2),
  },
  searchField: {
    '& .MuiInput-input': {
      height: 25,
    },
  },
  optionsAndActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchOptions: {
    display: 'flex',
    '& .MuiTypography-root': {
      fontSize: '0.85rem',
    },
  },
  actions: {
    textAlign: 'right',
    paddingRight: theme.spacing(5),
  },
  searchFieldInput: {
    height: 25,
    color: 'pink',
  },
  gridContainer: {
    margin: theme.spacing(0, -2),
  },
  gridItem: {
    padding: theme.spacing(0.5, 2, 0, 2),
  },
}));

const StudentSearchForm = () => {
  const classes = useStyles();
  const [flags, setFlags] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const flagIds = useSelector((state) => formValueSelector('studentSearch')(state, 'flagIds'));
  const alertTypes = useSelector((state) => getReferenceData(state, 'AlertType'));

  const toggleShowMore = (event) => {
    setShowMore(!showMore);
    event.preventDefault();
  };

  const isFlagsSelected = flagIds && !!flagIds.length;
  const disableShowLess = !flags.length || (isFlagsSelected && showMore);

  useEffect(() => {
    flagsApi.list().then((res) => setFlags(res));
    if (isFlagsSelected && !showMore) {
      setShowMore(true);
    }
  }, []);

  return (
    <div className={classes.root}>
      <form noValidate onSubmit={(e) => e.preventDefault()}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Field
              name="text"
              margin="normal"
              className={classes.searchField}
              component={TextField}
              placeholder="Search student id, name, email, course code"
              label="Search students"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div className={classes.alertTypesWrapper}>
              <Field
                name="alertTypes"
                label="Filter by alert type"
                component={MultiSelect}
                options={sortBy(alertTypes, 'id').map((alertType) => ({
                  value: alertType.id,
                  label: alertType.description,
                  classification: alertType.props.classification,
                }))}
                customStyles={multiSelectWithClassificationStyles}
              />
            </div>
          </Grid>

          {showMore && !!flags.length && (
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <div>
                <StudentFlagsField name="flagIds" label="Filter by flag" flags={flags} />
              </div>
            </Grid>
          )}
        </Grid>
        <div className={classes.optionsAndActions}>
          <div className={classes.searchOptions}>
            <Field name="all" type="checkbox" label="All students" component={LabelledCheckbox} />
            <Field name="snoozedOnly" type="checkbox" label="Snoozed only" component={LabelledCheckbox} />
          </div>
          <div className={classes.actions}>
            <Tooltip title={disableShowLess ? 'Clear flags to show less' : `Show ${showMore ? 'less' : 'more'}`}>
              <div>
                <IconButton disabled={disableShowLess} onClick={toggleShowMore}>
                  {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({ form: 'studentSearch', destroyOnUnmount: false })(StudentSearchForm);
