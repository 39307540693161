/* eslint-disable react/no-array-index-key */
import { Chip, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import api from '../../services/api';
import './ManageUsersPage.less';

const ManageUsersPage = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    api.users
      .list()
      .then((usrs) => setUsers(usrs))
      .catch((error) => Alert.error(`Error fetching users: ${error.message}`));
  }, []);

  return (
    <div className="manage-users-page">
      <Paper>
        <div className="header">
          <Typography variant="h5">Manage users</Typography>
        </div>

        <Table className="user-table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Link to={`/admin/users/${user.id}`}>{user.email}</Link>
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>
                  <div className="roles">
                    {user.enabled && user.roles.map((role) => <Chip key={role} className="role" label={role} />)}
                    {!user.enabled && <p className="role pending">Pending Acceptance</p>}
                  </div>
                </TableCell>
                <TableCell>{user.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default ManageUsersPage;
