import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import dates from '../../../util/dates';
import CourseInformationTable from './CourseInformationTable';

const useStyles = makeStyles({
  tableRow: {
    height: 35,
  },
  lighterText: {
    color: grey[500],
  },
  darkerText: {
    fontWeight: 700,
  },
});

const SpecialRequirements = ({ specialRequirements }) => {
  const classes = useStyles();

  return (
    <CourseInformationTable headings={['Code', 'Description', 'Completion date', 'Expiry date', 'Reference']} label="special requirements">
      {specialRequirements.map((specialRequirement) => (
        <TableRow key={specialRequirement.code} className={classes.tableRow}>
          <TableCell>
            <span className={classes.darkerText}>{specialRequirement.code}</span>
          </TableCell>
          <TableCell>
            <span className={classes.lighterText}>{specialRequirement.description}</span>
          </TableCell>
          <TableCell>{dates.formatDate(specialRequirement.completedDate)}</TableCell>
          <TableCell>{dates.formatDate(specialRequirement.expiryDate)}</TableCell>
          <TableCell>{specialRequirement.reference}</TableCell>
        </TableRow>
      ))}
    </CourseInformationTable>
  );
};

SpecialRequirements.propTypes = {
  specialRequirements: PropTypes.array,
};

SpecialRequirements.defaultProps = {
  specialRequirements: [],
};

export default SpecialRequirements;
