import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { capitalize } from 'lodash/string';
import dates from '../../../util/dates';
import CourseInformationTable from './CourseInformationTable';

const useStyles = makeStyles({
  tableRow: {
    height: 35,
  },
  lighterText: {
    color: grey[500],
  },
  darkerText: {
    fontWeight: 700,
  },
});

const UnitSetAttempts = ({ unitSetAttempts }) => {
  const classes = useStyles();

  return (
    <CourseInformationTable headings={['Code', 'Title', 'Category', 'Selection date', 'Requirements Completed?']} label="unit set attempts">
      {unitSetAttempts.map((unitSetAttempt) => (
        <TableRow key={unitSetAttempt.code} className={classes.tableRow}>
          <TableCell>
            <span className={classes.darkerText}>{unitSetAttempt.code}</span>
          </TableCell>
          <TableCell>
            <span className={classes.lighterText}>{unitSetAttempt.title}</span>
          </TableCell>
          <TableCell>{capitalize(unitSetAttempt.category)}</TableCell>
          <TableCell>{dates.formatDate(unitSetAttempt.selectionDate)}</TableCell>
          <TableCell>{unitSetAttempt.requirementsCompleted ? 'Yes' : 'No'}</TableCell>
        </TableRow>
      ))}
    </CourseInformationTable>
  );
};

UnitSetAttempts.propTypes = {
  unitSetAttempts: PropTypes.array,
};

UnitSetAttempts.defaultProps = {
  unitSetAttempts: [],
};

export default UnitSetAttempts;
