import React from 'react';
import { List, ListItemText, ListItem, ListItemIcon, withStyles } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import PropType from 'prop-types';

const styles = {
  errorText: {
    color: 'red',
  },
};

const ErrorMessage = ({ classes, errorMessages }) => {
  if (errorMessages && errorMessages.length > 0) {
    return (
      <List component="nav">
        <ListItem>
          <ListItemIcon>
            <ErrorIcon className={classes.errorText} />
          </ListItemIcon>
          <ListItemText primary={errorMessages[0]} classes={{ primary: classes.errorText }} />
        </ListItem>
      </List>
    );
  }

  return null;
};

ErrorMessage.propTypes = {
  classes: PropType.object.isRequired,
  errorMessages: PropType.array,
};

ErrorMessage.defaultProps = {
  errorMessages: [],
};

export default withStyles(styles)(ErrorMessage);
