/* eslint-disable react/no-danger */
import {
  Divider,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Delete as DeleteIcon, Edit as EditIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import cx from 'classnames';
import { array, func, object } from 'prop-types';
import React, { Component } from 'react';
import Alert from 'react-s-alert';
import ConfirmationDialog from '../common/ConfirmationDialog';
import JuvoTooltip from '../common/JuvoTooltip';

class MessageTemplateList extends Component {
  static propTypes = {
    messageTemplates: array.isRequired,
    classes: object.isRequired,
    deleteItem: func.isRequired,
    updateItem: func.isRequired,
  };

  state = {
    dialogOpen: false,
    expandedPanels: {},
  };

  openDialog = (messageTemplateId) => {
    this.setState({ dialogOpen: true, messageTemplateId });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false, messageTemplateId: null });
  };

  onDelete = () => {
    const { messageTemplateId } = this.state;
    this.props.deleteItem(messageTemplateId).then(() => {
      Alert.success('Message template deleted');
      this.closeDialog();
    });
  };

  handleChange = (panel) => (event, expanded) => {
    const expandedPanels = { ...this.state.expandedPanels };
    expandedPanels[panel] = expanded;
    this.setState({ expandedPanels });
  };

  render() {
    const { messageTemplates, classes, updateItem } = this.props;
    const { dialogOpen, expandedPanels } = this.state;

    if (!messageTemplates.length) {
      return <div className={classes.noData}>No message templates exist yet. Stay tuned!</div>;
    }
    return (
      <div>
        {messageTemplates.map((messageTemplate) => (
          <ExpansionPanel
            className={cx(classes.messageTemplatePanel, { [classes.messageTemplatePanelActive]: messageTemplate.active })}
            key={messageTemplate.id}
            expanded={!!expandedPanels[messageTemplate.id]}
            onChange={this.handleChange(messageTemplate.id)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <JuvoTooltip title={messageTemplate.active ? '' : 'Template currently inactive'} placement="right">
                <Typography className={classes.messageTemplateHeading}>
                  <span className={cx({ [classes.messageTemplateHeadingActive]: messageTemplate.active })}>{messageTemplate.title}</span>
                </Typography>
              </JuvoTooltip>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classes.content}>
                <div dangerouslySetInnerHTML={{ __html: messageTemplate.content }} />
              </div>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
              <IconButton variant="text" onClick={() => updateItem(messageTemplate)} size="small">
                <EditIcon />
              </IconButton>
              <IconButton variant="text" onClick={() => this.openDialog(messageTemplate.id)}>
                <DeleteIcon />
              </IconButton>
            </ExpansionPanelActions>
          </ExpansionPanel>
        ))}

        <ConfirmationDialog
          content="Are you sure you would like to delete the message template?"
          open={dialogOpen}
          onCancel={this.closeDialog}
          onConfirm={this.onDelete}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  noData: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  messageTemplateHeading: {
    fontWeight: 200,
    fontSize: 14,
  },
  messageTemplateHeadingActive: {
    fontWeight: 500,
    fontSize: 16,
  },
  actionsCol: {
    maxWidth: 58,
    margin: 0,
    paddingTop: '5px !important',
    paddingRight: '5px !important',
    paddingLeft: '0 !important',
  },
  titleCol: {
    minWidth: '14rem',
    width: '30%',
    paddingLeft: 20,
    paddingRight: 0,
    fontWeight: 600,
  },
  actions: {
    marginTop: 5,
    marginBottom: 5,
  },
  contentRow: {
    verticalAlign: 'top',
    '& td': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  content: {
    wordBreak: 'break-all',
  },
  messageTemplatePanel: {
    borderLeft: `4px solid ${grey[400]}`,
    backgroundColor: grey[100],
  },
  messageTemplatePanelActive: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.default,
  },
});

export default withStyles(styles)(MessageTemplateList);
