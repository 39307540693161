import { TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import cx from 'classnames';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedDate } from 'react-intl';

const ArchivedStudentRow = ({ student, classes, navigateToStudent }) => (
  <TableRow data-id={student.id} hover className={cx(['clickable', classes.differentAdviser])} onClick={() => navigateToStudent(student.id)}>
    <TableCell>
      <ul className="unstyled-list">
        <li>{student.name}</li>
        <li>
          <Typography variant="caption">{student.personId}</Typography>
        </li>
      </ul>
    </TableCell>
    <TableCell>{student.email}</TableCell>
    <TableCell>{student.campusLocation}</TableCell>
    <TableCell>
      <ul className="unstyled-list">
        {student.courseCodes.map((title) => (
          <li key={title}>{title}</li>
        ))}
      </ul>
    </TableCell>
    <TableCell>
      <FormattedDate value={student.archivedDate} />
    </TableCell>
  </TableRow>
);

ArchivedStudentRow.propTypes = {
  classes: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  navigateToStudent: PropTypes.func.isRequired,
};

const styles = () => ({
  table: {
    minWidth: 700,
  },
});

const mapDispatchToProps = (dispatch) => ({
  navigateToStudent: (studentId) => dispatch(push(`/adviser/students/${studentId}`)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ArchivedStudentRow));
