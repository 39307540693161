/* eslint-disable no-template-curly-in-string */
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { getLoggedInUser } from '../../reducers';

const MessageTemplateHelp = ({ classes, student, currentUser }) => (
  <div className={classes.root}>
    <div>
      <p>
        The following table shows some common placeholders you can use in message templates. To see the <i>full</i> list of fields, you can see the
        sample{' '}
        <a href={`/api/students/${student.id}`} target="_blank" rel="noopener noreferrer">
          student
        </a>{' '}
        and{' '}
        <a href="/api/users/me" target="_blank" rel="noopener noreferrer">
          currentUser
        </a>
        . (This requires basic knowledge of JSON and the{' '}
        <a href="https://chrome.google.com/webstore/detail/jsonview/chklaanhfefbnpoihckbnefhakgolnmc" target="_blank" rel="noopener noreferrer">
          JSONView plugin
        </a>
        .)
      </p>
    </div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Placeholder</TableCell>
          <TableCell>Example value</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.code}>{'${student.greetingName}'}</TableCell>
          <TableCell>{student.greetingName}</TableCell>
          <TableCell>
            Student preferred name where it exists. If no preferred name for student, the student's first name (since the givenNames field can contain
            middle name too)
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.code}>{'${student.name}'}</TableCell>
          <TableCell>{student.name}</TableCell>
          <TableCell>Student full name</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.code}>{'${student.adviser.name}'}</TableCell>
          <TableCell>{student.adviser.name}</TableCell>
          <TableCell>Student adviser full name</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.code}>{'${currentUser.greetingName}'}</TableCell>
          <TableCell>{currentUser.greetingName}</TableCell>
          <TableCell>Current logged in user first name (since the givenNames field can contain middle name too)</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.code}>{'${currentUser.name}'}</TableCell>
          <TableCell>{currentUser.name}</TableCell>
          <TableCell>Current logged in user full name</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.code}>{'${currentUser.signature}'}</TableCell>
          <TableCell>
            Kind regards,
            <br /> {currentUser.name}
            <Typography className={classes.createSignatureLink} variant="caption">
              Go to your <Link to="/my-profile">profile page</Link> to configure your signature.
            </Typography>
          </TableCell>
          <TableCell>Current logged in user signature, configured in their profile page</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
);

MessageTemplateHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {},
  code: {
    fontFamily: 'monospace, monospace',
    fontSize: '0.9em',
    paddingLeft: 5,
    paddingRight: 5,
  },
  createSignatureLink: {
    marginTop: theme.spacing(2),
    display: 'inline-block',
  },
});

const mapStateToProps = (state) => ({
  currentUser: getLoggedInUser(state),
});

export default withStyles(styles)(connect(mapStateToProps)(MessageTemplateHelp));
