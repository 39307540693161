import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide, Tooltip, withStyles } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import { endAdviserLeave, startAdviserLeave } from '../../actions/advisers';
import { fetchLockedJobs } from '../../actions/jobs';
import ErrorMessage from '../../components/common/ErrorMessage';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getAllLockedJobs, isAdmin as isAdminReducer } from '../../reducers';
import DialogTitleWithClose from '../../components/common/DialogTitleWithClose';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

class LeaveStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      isUpdating: false,
      errorMessages: null,
    };
  }

  getLeaveUpdateFunction = () => (this.props.adviser.onLeave ? this.props.endAdviserLeave : this.props.startAdviserLeave);

  handleCancel = () => this.setState({ showDialog: false });

  handleConfirm = () => {
    this.setState({ isUpdating: true });
    const leaveUpdateFunction = this.getLeaveUpdateFunction();
    leaveUpdateFunction(this.props.adviser.id)
      .then(() => {
        this.setState({ showDialog: false });
        this.props.fetchAllLockedJobs();
        Alert.success('Leave process initiated successfully');
      })
      .catch((error) => this.setState({ errorMessages: error.messages }))
      .finally(() => this.setState({ isUpdating: false }));
  };

  showDialogWindow = () => this.setState({ showDialog: true });

  leaveStatusCurrentlyUpdating() {
    const { allLockedJobs } = this.props;
    return includes(allLockedJobs, 'LEAVE_STATUS_UPDATE');
  }

  getLeaveButtonTooltip() {
    const { isAdmin } = this.props;
    if (isAdmin) {
      return this.leaveStatusCurrentlyUpdating() ? 'Leave status currently updating, please try again shortly' : 'Update adviser leave status';
    }

    return 'To change this please contact your administrator';
  }

  render() {
    const { adviser, classes, isAdmin } = this.props;
    const { showDialog, errorMessages } = this.state;
    const status = adviser.onLeave ? 'Inactive' : 'Active';

    return (
      <>
        <div>
          <Tooltip title={this.getLeaveButtonTooltip()} placement="bottom">
            {isAdmin ? (
              <span className={classes.buttonWrapper}>
                <Button
                  role="dialog"
                  className={cx(classes.subtitle, classes[toLower(status)])}
                  onClick={this.showDialogWindow}
                  disabled={this.leaveStatusCurrentlyUpdating()}
                >
                  <span className={classes.indicator} />
                  {status}
                </Button>
              </span>
            ) : (
              <span className={classes.statusNotButton}>
                <span className={classes.indicator} />
                {status}
              </span>
            )}
          </Tooltip>
        </div>
        <Dialog open={showDialog} onClose={this.handleCancel} TransitionComponent={Transition} keepMounted>
          <DialogTitleWithClose title="Change leave status" onClose={this.handleCancel} />
          <DialogContent>
            <DialogContentText>Would you like to change your leave status to {adviser.onLeave ? 'active' : 'inactive'}?</DialogContentText>
            <ErrorMessage errorMessages={errorMessages} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="default">
              No, keep {adviser.onLeave ? 'inactive' : 'active'}
            </Button>
            <Button
              onClick={this.handleConfirm}
              color="primary"
              variant={adviser.onLeave ? 'contained' : 'outlined'}
              disabled={this.leaveStatusCurrentlyUpdating()}
            >
              Change to {adviser.onLeave ? 'active' : 'inactive'}
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.isUpdating && <LoadingIndicator size={60} text="Loading..." />}
      </>
    );
  }
}

LeaveStatus.propTypes = {
  adviser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  startAdviserLeave: PropTypes.func.isRequired,
  endAdviserLeave: PropTypes.func.isRequired,
  fetchAllLockedJobs: PropTypes.func.isRequired,
  allLockedJobs: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const styles = {
  inactive: {
    '& $indicator': {
      backgroundColor: red[500],
    },
  },
  indicator: {
    height: 10,
    width: 10,
    marginRight: 5,
    display: 'inline-block',
    borderRadius: 30,
    backgroundColor: green[500],
  },
  statusNotButton: {
    padding: '8px 16px',
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 500,
  },
  buttonWrapper: {
    display: 'inline-block',
  },
};

const mapStateToProps = (state) => ({
  allLockedJobs: getAllLockedJobs(state),
  isAdmin: isAdminReducer(state),
});

const mapDispatchToProps = (dispatch) => ({
  startAdviserLeave: (adviserId) => dispatch(startAdviserLeave(adviserId)),
  endAdviserLeave: (adviserId) => dispatch(endAdviserLeave(adviserId)),
  fetchAllLockedJobs: () => dispatch(fetchLockedJobs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeaveStatus));
