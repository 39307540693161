import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import DatePickerReduxComponent from '../forms/DatePickerReduxComponent';
import { TextField } from '../wrappers/material';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: 20,
  },
  form: {
    padding: '0 10px 10px 10px',
    background: grey[100],
    width: '100%',
  },
  flex: {
    display: 'flex',
  },
  textInput: {
    marginTop: 0,
  },
  topRightButton: {
    color: grey[400],
    right: theme.spacing(),
    top: theme.spacing(),
    position: 'absolute',
  },
  datesWrapper: {
    marginBottom: theme.spacing(),
  },
}));

const CommentsSearchForm = ({ reset, label }) => {
  const [open, updateOpen] = useState(false);
  const classes = useStyles();
  const cancelSearch = () => {
    updateOpen(!open);
    reset();
  };

  return (
    <div className={classes.root}>
      {open ? (
        <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field name="text" margin="normal" component={TextField} placeholder={label} label={label} className={classes.textInput} fullWidth />
            </Grid>

            <Grid item xs={6}>
              <div className={classes.datesWrapper}>
                <Field name="startDate" label="Date from" component={DatePickerReduxComponent} showClearDate />

                <Field name="endDate" label="Date to" component={DatePickerReduxComponent} showClearDate />
              </div>
            </Grid>
          </Grid>

          <IconButton className={classes.topRightButton} variant="text" onClick={cancelSearch} size="small">
            <CloseIcon />
          </IconButton>
        </form>
      ) : (
        <IconButton className={classes.topRightButton} variant="text" onClick={() => updateOpen(!open)} size="small">
          <SearchIcon />
        </IconButton>
      )}
    </div>
  );
};

CommentsSearchForm.propTypes = {
  label: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'commentsSearch' })(CommentsSearchForm);
