/* eslint-disable react/no-array-index-key,react/jsx-indent,react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { object, string } from 'prop-types';
import { Card, CardContent, CardHeader, Button, withStyles } from '@material-ui/core';
import { Refresh as RefreshIcon, Search as SearchIcon } from '@material-ui/icons';
import connect from 'react-redux/es/connect/connect';
import Alert from 'react-s-alert';
import { formValueSelector } from 'redux-form';
import { getLoggedInUser } from '../../reducers';
import api from '../../services/api';
import Comment from '../comments/Comment';
import Progress from '../common/Progress';

class MessageTemplatePreview extends Component {
  static propTypes = {
    classes: object.isRequired,
    currentUser: object.isRequired,
    student: object,
    content: string,
  };

  static defaultProps = {
    content: undefined,
    student: undefined,
  };

  state = {
    loading: false,
    previewContent: null,
    previewError: false,
  };

  fetchTemplateContent = () => {
    const { content, student } = this.props;
    this.setState({ loading: true });
    api.messageTemplates
      .preview(student.id, content)
      .then((resp) => this.setState({ previewContent: resp.value, previewError: false }))
      .catch((error) => {
        Alert.error(error.message);
        this.setState({ previewContent: null, previewError: true });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { classes, currentUser, content, student } = this.props;
    const { loading, previewContent, previewError } = this.state;
    const dummyComment = previewContent && {
      author: currentUser,
      isStudent: false,
      content: previewContent,
      created: new Date().toISOString(),
    };

    return (
      <div>
        <div className={classes.actions}>
          <Button variant="contained" onClick={this.fetchTemplateContent} disabled={!student || !content}>
            Preview
            <RefreshIcon className={classes.buttonIcon} />
          </Button>
        </div>
        <div className={classes.previewContainer}>
          {loading && <Progress />}
          {!loading && previewContent && (
            <Card>
              <CardHeader
                classes={{ root: classes.previewHeader, avatar: classes.avatar, title: classes.previewTitle, subheader: classes.previewSubheader }}
                avatar={<SearchIcon className={classes.icon} />}
                title="Preview"
                subheader={
                  <>
                    <span className={classes.muted}>Student: </span>
                    {student.name}
                  </>
                }
              />
              <CardContent style={{ marginTop: 10, marginBottom: -25 }}>
                <Comment comment={dummyComment} />
              </CardContent>
            </Card>
          )}
          {!loading && previewError && <div className={classes.error}>Error in template content. Please check syntax and try again.</div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  content: formValueSelector('messageTemplateForm')(state, 'content'),
  currentUser: getLoggedInUser(state),
});

const styles = ({ spacing, palette }) => ({
  root: {
    padding: spacing(3),
  },
  actions: {
    textAlign: 'right',
    marginBottom: 10,
  },
  buttonIcon: {
    marginLeft: 5,
  },
  previewContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  previewHeader: {
    padding: spacing(2),
  },
  previewTitle: {
    fontSize: 20,
    lineHeight: '20px',
  },
  previewSubheader: {
    fontSize: 12,
    fontWeight: 400,
  },
  avatar: {
    color: '#64B5FE',
    fontSie: 36,
  },
  icon: {
    height: 35,
    width: 35,
  },
  muted: {
    fontWeight: 300,
  },
  error: {
    backgroundColor: palette.error.main,
    color: '#FFFFFF',
    padding: spacing(3),
    borderRadius: 3,
  },
});

export default withStyles(styles)(connect(mapStateToProps)(MessageTemplatePreview));
