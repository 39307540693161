import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StaffLayout from '../pages/admin/StaffLayout';
import ReferralsPage from '../pages/ReferralsPage';
import AsyncComponent from './AsyncComponent';
import { hasAnyRole, loginRequired } from './hooks';

export default () => (
  <AsyncComponent asyncActions={[loginRequired, hasAnyRole('STAFF')]}>
    <StaffLayout>
      <Switch>
        <Route exact path="/referrals" component={ReferralsPage} />
      </Switch>
    </StaffLayout>
  </AsyncComponent>
);
