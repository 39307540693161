export function selectUnit(studentId, courseCode, unitId, selected) {
  return {
    type: 'SELECT_ENROLMENT_UNIT',
    params: { studentId, courseCode, unitId, selected },
  };
}

export function unselectAll(studentId, courseCode) {
  return {
    type: 'UNSELECT_ALL_ENROLMENT_UNITS',
    params: { studentId, courseCode },
  };
}
