import { Grid, IconButton, Paper, Tooltip, withStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import { bool, func, object, string } from 'prop-types';
/* eslint-disable react/no-array-index-key,react/jsx-indent,react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import HelpIcon from '@material-ui/icons/Help';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Progress from '../common/Progress';
import ErrorMessages from '../forms/ErrorMessages';
import MessageTemplateForm from '../forms/MessageTemplateForm';
import MessageTemplateHelp from './MessageTemplateHelp';
import MessageTemplatePreview from './MessageTemplatePreview';

class SaveMessageTemplate extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    navigateTo: func.isRequired,
    title: string.isRequired,
    successMessage: string.isRequired,
    classes: object.isRequired,
    loading: bool,
    initialValues: object,
  };

  static defaultProps = {
    initialValues: {},
    loading: false,
  };

  state = {
    student: null,
    dialogOpen: false,
  };

  openHelp = () => {
    this.setState({ dialogOpen: true });
  };

  closeHelp = () => {
    this.setState({ dialogOpen: false });
  };

  componentDidMount() {
    api.students.getSample().then((student) => this.setState({ student }));
  }

  goBack = () => this.props.navigateTo('/admin/message-templates');

  handleSubmit = (values) =>
    this.props
      .onSubmit(values)
      .then(() => {
        Alert.success(this.props.successMessage);
        this.goBack();
      })
      .catch((error) => Alert.error(<ErrorMessages error={error} />));

  render() {
    const { classes, title, initialValues, loading } = this.props;
    const { student, dialogOpen } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.header}>
          <h1 className="display-1 short">{title}</h1>
        </div>
        {loading ? (
          <Progress />
        ) : (
          <div className={classes.formContainer}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                <div>
                  <MessageTemplateForm onSubmit={this.handleSubmit} onCancel={this.goBack} initialValues={initialValues} />
                </div>
                {student && (
                  <Tooltip title="Show syntax help">
                    <IconButton key="help" aria-label="Help" color="inherit" onClick={this.openHelp}>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <MessageTemplatePreview student={student} />
              </Grid>
            </Grid>
          </div>
        )}
        <ConfirmationDialog
          content={<MessageTemplateHelp student={student} />}
          title="Message template help"
          open={dialogOpen}
          maxWidth="lg"
          onConfirm={this.closeHelp}
        />
      </Paper>
    );
  }
}

const mapDispatchToProps = {
  navigateTo: push,
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {},
  previewPane: {
    textAlign: 'center',
    paddingTop: 120,
  },
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(SaveMessageTemplate));
