import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminLayout from '../pages/admin/AdminLayout';
import AdvisersPage from '../pages/admin/AdvisersPage';
import AdviserStudentsPage from '../pages/admin/AdviserStudentsPage';
import CreateMessageTemplatePage from '../pages/admin/CreateMessageTemplatePage';
import FlagsPage from '../pages/admin/FlagsPage';
import ManageUsersPage from '../pages/admin/ManageUsersPage';
import MessageTemplatesPage from '../pages/admin/MessageTemplatesPage';
import UpdateMessageTemplatePage from '../pages/admin/UpdateMessageTemplatePage';
import UserProfilePage from '../pages/admin/UserProfilePage';
import AnnouncementsPage from '../pages/AnnouncementsPage';
import NotFoundPage from '../pages/NotFoundPage';
import ArchivedStudentsPage from '../pages/student/ArchivedStudentsPage';
import SystemSettingsPage from '../pages/SystemSettingsPage';
import AsyncComponent from './AsyncComponent';
import { hasAnyRole, loadApplicationSettings, loadRefData, loginRequired } from './hooks';
import MoodleIngestionsPage from '../pages/admin/MoodleIngestionsPage';

export default () => (
  <AsyncComponent asyncActions={[loginRequired, hasAnyRole('ADMIN'), loadRefData, loadApplicationSettings]}>
    <AdminLayout>
      <Switch>
        <Redirect exact from="/admin" to="/admin/advisers" />
        <Route exact path="/admin/advisers" component={AdvisersPage} />
        <Route exact path="/admin/advisers/:adviserId" component={AdviserStudentsPage} />
        <Route exact path="/admin/users" component={ManageUsersPage} />
        <Route exact path="/admin/users/:userId" component={UserProfilePage} />
        <Route exact path="/admin/announcements" component={AnnouncementsPage} />
        <Route exact path="/admin/flags" component={FlagsPage} />
        <Route exact path="/admin/moodle-ingestions" component={MoodleIngestionsPage} />
        <Route exact path="/admin/message-templates" component={MessageTemplatesPage} />
        <Route exact path="/admin/message-templates/new" component={CreateMessageTemplatePage} />
        <Route exact path="/admin/message-templates/:id" component={UpdateMessageTemplatePage} />
        <Route exact path="/admin/archived-students" component={ArchivedStudentsPage} />
        <Route exact path="/admin/settings" component={SystemSettingsPage} />
        <Route path="/admin/*" component={NotFoundPage} />
      </Switch>
    </AdminLayout>
  </AsyncComponent>
);
