import { putJSON, requestJSON } from './http';

const getApplicationSettings = () =>
  requestJSON('/application/settings');

const updateApplicationSettings = (settings) => putJSON('/application/settings', settings);

export default {
  getApplicationSettings,
  updateApplicationSettings,
};
