import * as PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { length, required } from 'redux-form-validators';
import FormError from './FormError';
import RichTextReduxComponentWithQuickLinks from './RichTextReduxComponentWithQuickLinks';

const CreateAnnouncementForm = ({ error, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <FormError value={error} />
    <Field
      name="value"
      component={RichTextReduxComponentWithQuickLinks}
      placeholder="Type announcement ..."
      label="Announcement"
      type="text"
      margin="normal"
      rows="8"
      multiline
      validate={[required({ msg: 'Please add some text' }), length({ max: 3000 })]}
      maxHeight={300}
      fullWidth
    />
  </form>
);

CreateAnnouncementForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.any,
};

CreateAnnouncementForm.defaultProps = {
  error: undefined,
};

export default reduxForm({ form: 'createAnnouncementForm' })(CreateAnnouncementForm);
