import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import DatePickerReduxComponent from '../forms/DatePickerReduxComponent';
import FormError from '../forms/FormError';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 360,
  },
}));

const AlertCountForm = ({ error, handleSubmit }) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <FormError value={error} />
        <Field
          id="fromDate"
          name="fromDate"
          label="Date from"
          placeholder="Date inclusive"
          component={DatePickerReduxComponent}
          validate={[required()]}
        />
        <Field id="toDate" name="toDate" label="Date to" placeholder="Date inclusive" component={DatePickerReduxComponent} validate={[required()]} />
      </div>
    </form>
  );
};

AlertCountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.any,
};

AlertCountForm.defaultProps = {
  error: undefined,
};

const validate = (values) => {
  const errors = {};
  const { fromDate, toDate } = values;
  if (fromDate && toDate && fromDate > toDate) {
    errors.fromDate = 'Invalid date range';
    errors.toDate = 'Invalid date range';
  }
  return errors;
};

export default reduxForm({ form: 'alertCountForm', validate })(AlertCountForm);
