import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
const LOCAL_DATE_FORMAT = 'DD/MM/YYYY';

const parseDate = (str) => (str && moment(str, LOCAL_DATE_FORMAT).toDate()) || str;
const serializeDate = (date) => (date && moment(date).format(LOCAL_DATE_FORMAT)) || date;

const parseTimestamp = (str) => (str && new Date(Date.parse(str))) || str;
const serializeTimestamp = (str) => (str && new Date(Date.parse(str))) || str;
const formatDate = (str) => {
  if (!str) return str;
  return moment(str).format('DD/MM/YYYY');
};

export default {
  parseDate,
  serializeDate,
  parseTimestamp,
  serializeTimestamp,
  formatDate,
  moment,
};
