export default (state = { loading: false }, action) => {
  switch (action.type) {
    case 'FETCH_AUDIT_LOGS_SUCCESS':
      return {
        loading: false,
        [action.params.studentId]: [...action.response],
      };
    case 'FETCH_AUDIT_LOGS_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};


export const getAll = (state, studentId) => state[studentId] || [];
export const getAllLoading = (state) => state.loading;
