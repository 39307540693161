import React from 'react';

export default () => (
  <div className="home-page">
    <h1 className="display-2">Home page</h1>
    <div>
      Placeholder for our home page.
    </div>
  </div>
);
