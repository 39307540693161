import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Launch as LaunchIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const AdviserResourcesButton = ({ classes }) => (
  <Button className={classes.buttonExternal} size="small" target="_blank" href="https://sites.google.com/monash.edu/esa-resources">
    Adviser resources <LaunchIcon />
  </Button>
);

AdviserResourcesButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  buttonExternal: {
    color: grey[800],
    fontWeight: 300,
    '& svg': {
      fontSize: 15,
      position: 'relative',
      top: -1,
      left: 4,
    },
  },
});

export default withStyles(styles)(AdviserResourcesButton);
