import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import CourseCard from './CourseCard';

const currentEnrolments = () => (course) => (course.enrolmentStatus === 'ENROLLED' ? 0 : 1);

const CourseList = ({ courses, studentId }) => {
  const sortedCourses = orderBy(
    courses,
    [
      currentEnrolments(),
      'commencementDate',
    ],
    ['asc', 'desc'],
  );

  return (sortedCourses || []).map((course) => <CourseCard key={course.code} course={course} studentId={studentId} />);
};

CourseList.propTypes = {
  courses: PropTypes.array.isRequired,
  studentId: PropTypes.string.isRequired,
};

export default CourseList;
