import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, Tooltip, AppBar, Toolbar, Tabs, Tab, Badge, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import EnrolmentUnits from './EnrolmentUnits';
import UnitSetAttempts from '../Course/UnitSetAttempts';
import SpecialRequirements from '../Course/SpecialRequirements';
import FacultyNotes from '../Course/FacultyNotes';

const useStyles = makeStyles({
  expand: {
    width: '100%',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  badge: {
    padding: '0 15px',
  },
  badgeHidden: {
    '& span': {
      display: 'none',
    },
  },
  clearfix: {
    clear: 'both',
  },
  header: {
    backgroundColor: '#FFF',
  },
  toolbar: {
    minHeight: 0,
    padding: 0,
  },
  tabs: {
    minHeight: 0,
    '& button': {
      height: 40,
    },
  },
  tabBar: {
    backgroundColor: 'inherit',
  },
});

const EnrolmentRecord = ({ course, studentId }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState('units');

  useEffect(() => {
    if (course.enrolmentStatus !== 'ENROLLED') {
      setExpanded(true);
    }
  }, []);

  return (
    <>
      <Tooltip id="tooltip-top-start" title="Enrolment record" placement="bottom">
        <Button
          className={cx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="Show more"
          disableRipple
        >
          <ExpandMoreIcon />
        </Button>
      </Tooltip>
      <div className={classes.clearfix}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className={classes.header}>
            <AppBar elevation={0} position="static" color="default" className={classes.tabBar}>
              <Toolbar className={classes.toolbar}>
                <Tabs className={classes.tabs} value={page} onChange={(e, val) => setPage(val)} indicatorColor="primary">
                  <Tab label="Units" value="units" />
                  <Tab
                    label={
                      <Badge
                        className={cx(classes.badge, { [classes.badgeHidden]: !course.unitSetAttempts.length })}
                        color="primary"
                        badgeContent={course.unitSetAttempts.length}
                      >
                        Unit set attempts
                      </Badge>
                    }
                    value="sets"
                  />
                  <Tab
                    label={
                      <Badge
                        className={cx(classes.badge, { [classes.badgeHidden]: !course.specialRequirements.length })}
                        color="primary"
                        badgeContent={course.specialRequirements.length}
                      >
                        Special requirements
                      </Badge>
                    }
                    value="specialRequirements"
                  />
                  <Tab
                    label={
                      <Badge
                        className={cx(classes.badge, { [classes.badgeHidden]: !course.notes.length })}
                        color="primary"
                        badgeContent={course.notes.length}
                      >
                        Faculty notes
                      </Badge>
                    }
                    value="facultyNotes"
                  />
                </Tabs>
              </Toolbar>
            </AppBar>
          </div>
          <div className={classes.container}>
            {page === 'units' && <EnrolmentUnits course={course} studentId={studentId} />}
            {page === 'sets' && <UnitSetAttempts unitSetAttempts={course.unitSetAttempts} />}
            {page === 'specialRequirements' && <SpecialRequirements specialRequirements={course.specialRequirements} />}
            {page === 'facultyNotes' && <FacultyNotes facultyNotes={course.notes} />}
          </div>
        </Collapse>
      </div>
    </>
  );
};

EnrolmentRecord.propTypes = {
  course: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
};

export default EnrolmentRecord;
