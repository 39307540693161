/* eslint-disable react/no-array-index-key */
import { InputLabel, makeStyles, Paper, Typography } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import React, { useEffect, useState } from 'react';
import { required } from 'redux-form-validators';
import { Field, formValueSelector } from 'redux-form';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import AddCommentForm from '../forms/AddCommentForm';
import DropzoneField from '../forms/DropzoneField';
import commentsApi from '../../services/api/comments';
import StudentCsvHelp from '../students/group-messages/StudentCsvHelp';
import storage from '../../services/api/storage';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    padding: theme.spacing(3),
  },
  header: {
    minHeight: theme.spacing(10),
  },
  muted: {
    fontWeight: 200,
  },
  formContainer: {
    maxWidth: 600,
  },
  studentGroup: {
    marginBottom: theme.spacing(0.5),
  },
  studentCsvUpload: {
    marginBottom: theme.spacing(2),
    '&.padded': {
      marginBottom: theme.spacing(3),
    },
    '& .attachment-list': {
      marginTop: 0,
    },
  },
  studentCount: {
    fontSize: '1.4rem',
    marginRight: 3,
  },
  studentCountMessage: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginLeft: 10,
    marginTop: theme.spacing(-0.5),
    paddingBottom: theme.spacing(),
    color: grey[800],
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
}));

const GroupComments = ({ title, onSubmit, countMessageSuffix, internal }) => {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [studentCount, setStudentCount] = useState(null);

  const getStudentCount = (attachment) => {
    console.log('Getting student count for attachment: ', attachment);
    setProcessing(true);
    return commentsApi
      .countBulkStudents(attachment)
      .then(({ value }) => setStudentCount(value))
      .finally(() => setProcessing(false));
  };

  const formSelector = formValueSelector(internal ? 'addStudentCommentInternalForm' : 'addStudentCommentForm');
  const studentCsv = useSelector((state) => formSelector(state, 'studentCsv'));

  useEffect(() => {
    if (studentCsv && !studentCount) {
      getStudentCount(studentCsv);
    }
  }, []);

  const clearStudentGroup = () => setStudentCount(null);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{title}</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.studentGroup}>
          <InputLabel shrink>Specify students via CSV</InputLabel>
        </div>
        <AddCommentForm
          onSubmit={onSubmit}
          onClear={clearStudentGroup}
          disabled={uploading || processing}
          label="Message text..."
          allowTemplates={!internal}
          promptForMissingAttachments={!internal}
          buttonColor={internal ? 'secondary' : 'primary'}
          internal={internal}
          uploadFile={(file) => storage.uploadStudentGroupMessageAttachment(file)}
        >
          <div className={cx(classes.studentCsvUpload, { padded: internal })}>
            <Field
              name="studentCsv"
              label="Student CSV"
              accept="text/csv,.csv"
              component={DropzoneField}
              helpComponent={<StudentCsvHelp className={classes.iconLeft} />}
              icon={<PeopleIcon className={classes.iconLeft} />}
              onUploadingChanged={setUploading}
              validate={[required({ msg: 'Please upload a CSV containing student ids' })]}
              onUpload={getStudentCount}
              onChange={(attachment) => !attachment && clearStudentGroup()}
              uploadFile={(file) => storage.uploadStudentIdentifier(file)}
            />
            {studentCount && (
              <div className={classes.studentCountMessage}>
                <span className={classes.studentCount}>{studentCount}</span> students {countMessageSuffix}
              </div>
            )}
          </div>
        </AddCommentForm>
      </div>
    </Paper>
  );
};

GroupComments.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  countMessageSuffix: PropTypes.string.isRequired,
  internal: PropTypes.bool,
};

GroupComments.defaultProps = {
  internal: false,
};

export default GroupComments;
