import { AppBar, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, withStyles, withWidth } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ExitToApp as ExitToAppIcon, Menu as MenuIcon, Person } from '@material-ui/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import juvoLogoWhite from '../../../images/juvo-logo-white.png';
import monashLogo from '../../../images/logo.png';
import { closeDrawer, openDrawer } from '../../actions/drawer';
import { getLoggedInUser, getOpenDrawer } from '../../reducers';
import JuvoAvatar from '../common/JuvoAvatar';
import { intersection } from 'lodash';

const hasAnyRole = (user, roles) => intersection(user.roles, roles).length > 0;

class Header extends Component {
  state = {
    anchorEl: null,
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, openMenu, closeMenu, openDrawerName, width, loggedInUser, hideMenu } = this.props;
    const { anchorEl } = this.state;
    const sidebarOpen = openDrawerName === 'sidebar';
    const profileMenuOpen = Boolean(anchorEl);

    return (
      <AppBar position="absolute" className={cx(classes.appBar, { [classes.appBarShift]: width !== 'xs' && sidebarOpen })}>
        <Toolbar disableGutters className={classes.toolbar}>
          {!hideMenu ? (
            <div className={classes.section}>
              <div className={classes.sectionLeftInner}>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={() => (sidebarOpen ? closeMenu('sidebar') : openMenu('sidebar'))}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>
          ) : null}

          <div className={cx(classes.section, { [classes.logoOnLeft]: hideMenu })}>
            <img src={hideMenu ? monashLogo : juvoLogoWhite} alt={hideMenu ? 'Monash University Logo' : 'Juvo Logo'} className={classes.juvoLogo} />
          </div>

          <div className={classes.section}>
            <div className={classes.sectionRightInner}>
              <IconButton
                aria-owns={profileMenuOpen ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                className={classes.profileButton}
                color="inherit"
              >
                <JuvoAvatar image={loggedInUser.image} size="sm" />
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={profileMenuOpen}
                onClose={this.handleClose}
              >
                {hasAnyRole(loggedInUser, ['ADMIN', 'ADVISER']) && (
                  <Link to="/my-profile" className={classes.blankLink}>
                    <MenuItem>
                      <ListItemIcon className={classes.icon}>
                        <Person />
                      </ListItemIcon>
                      <ListItemText classes={{ inset: classes.menuItemText }} inset primary="Profile" />
                    </MenuItem>
                  </Link>
                )}
                <Link to="/logout" className={classes.blankLink}>
                  <MenuItem>
                    <ListItemIcon className={classes.icon}>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ inset: classes.menuItemText }} inset primary="Logout" />
                  </MenuItem>
                </Link>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  openDrawerName: PropTypes.string,
  openMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  hideMenu: PropTypes.bool,
};

Header.defaultProps = {
  openDrawerName: null,
  hideMenu: false,
};

const styles = (theme) => ({
  appBar: {
    transition: 'all 150ms ease 0s',
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#000',
  },
  menuItemText: {
    paddingLeft: 0,
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - ${239}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& $menuButton': {
      opacity: 0,
    },
  },
  section: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  sectionLeftInner: {
    marginRight: 'auto',
  },
  sectionRightInner: {
    marginLeft: 'auto',
  },
  logoOnLeft: {
    marginLeft: 15,
    justifyContent: 'flex-start',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  menuButton: {
    marginLeft: 12,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  profileButton: {
    marginRight: 15,
    '& .inner': {
      marginLeft: 'auto',
    },
  },
  logoLink: {
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 'bold',
    position: 'relative',
  },
  monashLogo: {
    width: 100,
    verticalAlign: 'middle',
    border: '0',
  },
  juvoLogo: {
    alignSelf: 'center',
    width: 115,
  },
  blankLink: {
    textDecoration: 'none',
  },
});

export default connect(
  (state) => ({
    openDrawerName: getOpenDrawer(state),
    loggedInUser: getLoggedInUser(state),
  }),
  {
    openMenu: openDrawer,
    closeMenu: closeDrawer,
  },
)(withStyles(styles)(withWidth()(Header)));
