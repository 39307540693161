const semesterOrder = [
  'SSA-02',
  'RES-Q4',
  'SS-29A',
  'T4-57',
  'S1-60',
  'T3-58',
  'S2-SS-02',
  'S2-S1-02',
  'RES-Q3',
  'T3-57',
  'S2-32',
  'S2-01',
  'S2-29',
  'WS-01',
  'T2-58',
  'RES-Q2',
  'T2-57',
  'FY-32',
  'FY-01',
  'S2-60',
  'S1-29',
  'S1-32',
  'S1-01',
  'RES-Q1',
  'T1-58',
  'T1-57',
  'SS-S1-01',
  'SSB-01',
];

export default semesterOrder;
