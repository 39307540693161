import { normalize } from 'normalizr';
import schemas from '../schemas';
import api from '../services/api';
import { asyncAction } from './actions';

export const fetchAdviserStudents = (adviserId, search) => asyncAction(
  'FETCH_ADVISER_STUDENTS',
  api.students.listForAdviser(adviserId, search),
  {
    responseTransformer: (users) => {
      const normalizedStudents = normalize(users.data, schemas.arrayOfStudents);
      return {
        ...normalizedStudents,
        totalResultCount: users.totalResultCount,
      };
    },
    params: { adviserId },
  },
);

export const uploadStudentFlagsCsv = (file) => asyncAction(
  'STUDENT_FLAGS_UPLOAD',
  api.students.uploadStudentFlagsCsv(file),
);
