/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Drawer, List, IconButton, withStyles, withWidth } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { array, bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import cx from 'classnames';
import { compose } from 'recompose';
import logo from '../../../../images/logo.png';
import * as authActions from '../../../actions/auth';
import { getLoggedInUser, getOpenDrawer } from '../../../reducers/index';
import ListItemCustom from './ListItemCustom';
import { closeDrawer } from '../../../actions/drawer';

const MenuDrawer = ({
  loggedInUser,
  logout,
  navigateTo,
  classes,
  staticContext,
  menuItems,
  isAdmin,
  openDrawer,
  closeMenu,
  width,
  location: { pathname },
  ...rest
}) => (
  <Drawer
    variant={width === 'xs' ? 'temporary' : 'permanent'}
    onClose={() => closeMenu('sidebar')}
    open={openDrawer === 'sidebar'}
    classes={{ paper: cx(classes.drawerPaper, openDrawer !== 'sidebar' && classes.drawerPaperClose) }}
    {...rest}
  >
    <div>
      <div className={classes.logo}>
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            closeMenu('sidebar');
          }}
        >
          <img src={logo} alt="logo" className={classes.logoImg} />
        </Link>
        <IconButton className={classes.leftArrowButton} onClick={() => closeMenu('sidebar')}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <List component="nav">
        {menuItems.map((item) => (
          <ListItemCustom {...item} key={item.title} isAdmin={isAdmin} pathname={pathname} />
        ))}
      </List>
    </div>
  </Drawer>
);

MenuDrawer.propTypes = {
  loggedInUser: object,
  logout: func.isRequired,
  navigateTo: func.isRequired,
  closeMenu: func.isRequired,
  classes: object,
  staticContext: object,
  menuItems: array,
  isAdmin: bool,
  openDrawer: string,
  width: string.isRequired,
  location: object.isRequired,
};

MenuDrawer.defaultProps = {
  loggedInUser: {},
  classes: {},
  staticContext: {},
  menuItems: [],
  isAdmin: false,
  openDrawer: null,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  openDrawer: getOpenDrawer(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()).then(() => dispatch(push('/'))),
  navigateTo: (path) => dispatch(push(path)),
  closeMenu: (name) => dispatch(closeDrawer(name)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  navigateTo: (path) => {
    dispatchProps.navigateTo(path);
  },
});

const styles = (theme) => ({
  drawerPaper: {
    backgroundColor: '#fff',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  logo: {
    position: 'relative',
    padding: '12px 24px',
    zIndex: 4,
    backgroundColor: 'black',
  },
  logoImg: {
    maxWidth: 115,
    top: 22,
    verticalAlign: 'middle',
    border: '0',
  },
  list: {
    marginTop: 20,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    listStyle: 'none',
  },
  leftArrowButton: {
    width: 40,
    height: 40,
    position: 'relative',
    left: 50,
    color: 'white',
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  withStyles(styles),
  withWidth(),
  withRouter,
)(MenuDrawer);
