import { Button, Toolbar, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { lighten } from '@material-ui/core/styles';
import classNames from 'classnames';
import StudentActionsMenu from './StudentActionsMenu';

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  spacer: {
    flex: '1',
  },
  actions: {
    color: theme.palette.text.primary,
  },
  title: {
    flex: '0 0 auto',
  },
  selectAll: {
    marginLeft: '30px',
  },
});

const SelectedStudentsHeader = ({
  selectedStudents,
  classes,
  fetchStudents,
  title,
  allowBulkMessage,
  adviser,
  searchCriteria,
  studentTotalMatchCount,
  selectAllStudentsMatchingSearch,
  allStudentsMatchingSearchSelected,
  onActionCompleted,
}) => {
  const numSelected = allStudentsMatchingSearchSelected ? studentTotalMatchCount : selectedStudents.length;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <>
            <Typography color="inherit" variant="body1">
              {numSelected} student{numSelected > 1 && 's'} / {studentTotalMatchCount} selected
            </Typography>
          </>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>
      {numSelected > 0 && studentTotalMatchCount > 100 && !allStudentsMatchingSearchSelected && (
        <Button className={classes.selectAll} variant="outlined" onClick={selectAllStudentsMatchingSearch}>
          Select all {studentTotalMatchCount} students
        </Button>
      )}
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && (
          <span>
            <StudentActionsMenu
              students={selectedStudents}
              fetchStudents={fetchStudents}
              allowBulkMessage={allowBulkMessage}
              searchCriteria={searchCriteria}
              adviser={adviser}
              allStudentsMatchingSearchSelected={allStudentsMatchingSearchSelected}
              studentTotalMatchCount={studentTotalMatchCount}
              onActionCompleted={onActionCompleted}
            />
          </span>
        )}
      </div>
    </Toolbar>
  );
};

SelectedStudentsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedStudents: PropTypes.array.isRequired,
  fetchStudents: PropTypes.func.isRequired,
  allowBulkMessage: PropTypes.bool.isRequired,
  searchCriteria: PropTypes.object.isRequired,
  adviser: PropTypes.object.isRequired,
  studentTotalMatchCount: PropTypes.number.isRequired,
  selectAllStudentsMatchingSearch: PropTypes.func.isRequired,
  allStudentsMatchingSearchSelected: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onActionCompleted: PropTypes.func,
};

SelectedStudentsHeader.defaultProps = {
  title: 'Students',
  onActionCompleted: () => {},
};

export default withStyles(toolbarStyles)(SelectedStudentsHeader);
