/* eslint-disable react/no-array-index-key */
import { Button, Paper, Typography, withStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import cx from 'classnames';
import { array, bool, func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { createAnnouncement, deleteAnnouncement, fetchAllAnnouncements } from '../actions/announcements';
import AnnouncementList from '../components/announcements/AnnouncementList';
import Authorised from '../components/common/Authorised';
import Progress from '../components/common/Progress';
import CreateAnnouncementForm from '../components/forms/CreateAnnouncementForm';
import FormDialog from '../components/forms/FormDialog';
import { getAllAnnouncements, isGetAnnouncementsLoading } from '../reducers';

class AnnouncementsPage extends Component {
  static propTypes = {
    announcements: array.isRequired,
    fetchAnnouncements: func.isRequired,
    deleteItem: func.isRequired,
    create: func.isRequired,
    loading: bool.isRequired,
    classes: object.isRequired,
    padded: bool,
  };

  static defaultProps = {
    padded: false,
  };

  state = { createDialogOpen: false };

  componentDidMount() {
    this.props.fetchAnnouncements();
  }

  openCreateDialog = () => {
    this.setState({ createDialogOpen: true });
  };

  closeCreateDialog = () => {
    this.setState({ createDialogOpen: false });
  };

  handleCreate = (formValues) =>
    this.props.create(formValues).then(() => {
      Alert.success('Announcement created');
      this.closeCreateDialog();
    });

  render() {
    const { createDialogOpen } = this.state;
    const { announcements, classes, loading, padded, deleteItem } = this.props;

    return (
      <Paper className={cx({ [classes.padded]: padded })}>
        <div className={classes.header}>
          <Typography variant="h5">Announcements</Typography>
          <Authorised roles="ADMIN">
            <div className="user-actions">
              <Button variant="contained" color="primary" onClick={this.openCreateDialog}>
                <AddIcon className={classes.iconLeft} />
                Create
              </Button>
            </div>
          </Authorised>
        </div>
        {loading ? <Progress /> : <AnnouncementList announcements={announcements} deleteItem={deleteItem} />}

        <FormDialog
          title="Create announcement"
          submitButtonText="Create"
          formComponent={CreateAnnouncementForm}
          formName="createAnnouncementForm"
          open={createDialogOpen}
          onCancel={this.closeCreateDialog}
          onSubmit={this.handleCreate}
          maxWidth="md"
          allowOverflow
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  announcements: getAllAnnouncements(state),
  loading: isGetAnnouncementsLoading(state),
});

const mapDispatchToProps = {
  fetchAnnouncements: fetchAllAnnouncements,
  deleteItem: deleteAnnouncement,
  create: createAnnouncement,
};

const styles = (theme) => ({
  header: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(13),
  },
  iconLeft: {
    marginRight: 5,
  },
  padded: {
    margin: theme.spacing(4),
  },
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AnnouncementsPage));
