import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  if (action.response && action.response.entities) {
    return {
      ...state,
      ...action.response.entities.students,
    };
  }

  return state;
};

const ifLatest = (state, action, result) => (action.params.requestTime >= state.requestTime ? result : state);

const byAdviser = (state = { loading: false, requestTime: 0 }, action) => {
  const requestTime = action.params && action.params.requestTime;
  switch (action.type) {
    case 'FETCH_ADVISER_STUDENTS_SUCCESS':
      return ifLatest(state, action, {
        [action.params.adviserId]: [...action.response.result],
        loading: false,
        totalResultCount: action.response.totalResultCount,
        requestTime,
      });
    case 'FETCH_ADVISER_STUDENTS_INPROGRESS':
      return ifLatest(state, action, {
        ...state,
        loading: true,
        requestTime,
      });
    default:
      return state;
  }
};

const getByAdviser = (state, id) => state.byAdviser[id] || [];

export const getAllForAdviser = (state, id) =>
  getByAdviser(state, id)
    .map((studentId) => state.byId[studentId]);

export const getById = (state, id) => state.byId[id];
export const getAllForAdviserLoading = (state) => state.byAdviser.loading;
export const getStudentsMatchCount = (state) => state.byAdviser.totalResultCount || 0;

export default combineReducers({
  byId,
  byAdviser,
});
