import { getJSON, postJSON, uploadFile } from './http';

const listForAdviser = (adviserId, search = {}) => postJSON(`/advisers/${adviserId}/students`, search);
const get = (id) => getJSON(`/students/${id}`);
const getAdviser = (emailOrId) => getJSON(`/students/adviser?studentEmailOrId=${encodeURIComponent(emailOrId)}`);
const getCurrent = () => getJSON('/students/me');
const getSample = () => getJSON('/students/sample');
const listArchived = (searchText) => getJSON(`/students/archived?searchText=${searchText}`);
const uploadStudentFlagsCsv = (file) => uploadFile('/students/upload-flags', 'POST', file);
const setSnoozed = (studentId, isSnoozed) => postJSON(`/students/${studentId}/snoozed?value=${isSnoozed}`);
const updateStudentDetails = (studentId, updatedDetails) => postJSON(`/students/${studentId}/student-details`, updatedDetails);

export default {
  listForAdviser,
  get,
  getAdviser,
  getCurrent,
  getSample,
  listArchived,
  uploadStudentFlagsCsv,
  setSnoozed,
  updateStudentDetails,
};
