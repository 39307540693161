import { Field } from 'redux-form';
import React from 'react';
import * as PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import MultiSelect from './MultiSelect';
import { classificationColour } from '../common/Classifications';

export const multiSelectWithClassificationStyles = {
  option: (base, { data }) => ({
    ...base,
    borderLeft: '4px solid',
    borderLeftColor: classificationColour(data.classification),
    paddingLeft: 8,
  }),
};

const StudentFlagsField = ({ name, label, flags, selectProps, validate }) => (
  <Field
    name={name}
    label={label}
    component={MultiSelect}
    options={sortBy(flags, [(flag) => flag.text.toUpperCase()]).map((flag) => ({
      value: flag.id,
      label: flag.text,
      classification: flag.classification,
    }))}
    customStyles={multiSelectWithClassificationStyles}
    disabled={!flags.length}
    selectProps={selectProps}
    validate={validate}
  />
);

StudentFlagsField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  flags: PropTypes.array.isRequired,
  selectProps: PropTypes.object,
  validate: PropTypes.array,
};

StudentFlagsField.defaultProps = {
  selectProps: {},
  validate: [],
};

export default StudentFlagsField;
