export const loadUserState = (userId) => {
  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const parsed = JSON.parse(serializedState);
    return parsed[userId];
  } catch (err) {
    return undefined;
  }
};

export const saveUserState = (userId, state) => {
  try {
    const serializedState = JSON.stringify({ [userId]: state });
    window.localStorage.setItem('state', serializedState);
  } catch (err) {
    // Shouldn't reach here
  }
};

export const removeState = () => {
  if (window.localStorage) {
    window.localStorage.removeItem('state');
  }
};
