import { AppBar, Badge, CircularProgress, FormControlLabel, makeStyles, Switch, Tab, Tabs, Toolbar } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import cx from 'classnames';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Alert from 'react-s-alert';
import { fetchOpenStudentAlerts } from '../../actions/alerts';
import { fetchAdvisingAppointmentCount } from '../../actions/comments';
import { fetchStudentDetails, setSnoozed } from '../../actions/studentDetails';
import PageLoading from '../../components/PageLoading';
import CompletedAlerts from '../../components/students/CompletedAlerts';
import StudentHeader from '../../components/students/StudentHeader';
import StudentHistory from '../../components/students/StudentHistory';
import StudentOverview from '../../components/students/StudentOverview';
import {
  getOpenAlertsForStudent,
  getOpenAlertsForStudentLoading,
  getStudentDetails,
  getStudentDetailsLoading,
  getAdvisingAppointmentCount,
} from '../../reducers';
import StudentInternalCommentsContainer from '../InternalCommentsAsAdviserContainer';
import SharedCommentsAsAdviserContainer from '../SharedCommentsAsAdviserContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  header: {
    paddingTop: 30,
    backgroundColor: '#FFF',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.15)',
    flex: '0 1 auto',
    position: 'relative',
    zIndex: 1,
  },
  toolbar: {
    minHeight: 0,
  },
  tabs: {
    minHeight: 0,
    '& button': {
      height: 40,
    },
  },
  tabBar: {
    backgroundColor: 'inherit',
  },
  container: {
    width: '100%',
    padding: theme.spacing(4),
    flex: '1 1 auto',
    overflowY: 'scroll',
  },
  badge: {
    padding: '0 15px',
  },
  badgeHidden: {
    '& span': {
      display: 'none',
    },
  },
  snoozeToggle: {
    marginLeft: 'auto',
    position: 'relative',
  },
  progressSpinnerSnoozed: {
    position: 'absolute',
    top: 14,
    left: -33,
  },
  historyBadge: {
    backgroundColor: blueGrey[300],
    color: '#fff',
  },
}));

const AdviserStudentPage = () => {
  const { id, page = 'overview' } = useParams();
  const classes = useStyles();
  const [snoozed, updateSnoozed] = useState(false);
  const [isSavingSnoozed, updateIsSavingSnoozed] = useState(false);

  const student = useSelector((state) => getStudentDetails(state, id));
  const alerts = useSelector((state) => getOpenAlertsForStudent(state, id));
  const loadingStudent = useSelector((state) => getStudentDetailsLoading(state));
  const loadingAlerts = useSelector((state) => getOpenAlertsForStudentLoading(state));
  const advisingAppointmentCount = useSelector((state) => getAdvisingAppointmentCount(state, id));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdvisingAppointmentCount(id));
    dispatch(fetchStudentDetails(id)).then((fetchedStudent) => {
      updateSnoozed(fetchedStudent.snoozed);
    });
    dispatch(fetchOpenStudentAlerts(id));
  }, []);

  const onClickTab = (e, val) => {
    dispatch(push(`/adviser/students/${student.id}/${val}`));
  };

  const onSetSnoozed = () => {
    updateSnoozed(!snoozed);
    updateIsSavingSnoozed(true);
    dispatch(setSnoozed(student.id, !student.snoozed))
      .then((updatedStudent) => {
        Alert.success(updatedStudent.snoozed ? 'Student is snoozed' : 'Student is no longer snoozed');
        updateSnoozed(updatedStudent.snoozed);
        updateIsSavingSnoozed(false);
      })
      .catch(() => {
        updateSnoozed(snoozed);
        updateIsSavingSnoozed(false);
        Alert.error('Something went wrong with that. Please try again or contact support.');
      });
  };

  if (loadingStudent || loadingAlerts) return <PageLoading />;
  if (!student) return <div>No data</div>;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <StudentHeader student={student} />

        <AppBar elevation={0} position="static" color="default" className={classes.tabBar}>
          <Toolbar className={classes.toolbar}>
            <Tabs className={classes.tabs} indicatorColor="primary" value={page} onChange={onClickTab}>
              <Tab
                label={
                  <Badge className={cx(classes.badge, { [classes.badgeHidden]: !alerts.length })} color="primary" badgeContent={alerts.length}>
                    Overview
                  </Badge>
                }
                value="overview"
              />
              <Tab label="Notes/Referrals" value="notes" />
              <Tab label="Messages" value="messages" />
              <Tab label="Completed Alerts" value="completed-alerts" />
              <Tab
                label={
                  <Badge
                    className={cx(classes.badge, { [classes.badgeHidden]: advisingAppointmentCount < 1 })}
                    // color="primary"
                    classes={{ badge: classes.historyBadge }}
                    badgeContent={advisingAppointmentCount}
                  >
                    History
                  </Badge>
                }
                value="history"
              />
            </Tabs>

            <span className={classes.snoozeToggle}>
              {isSavingSnoozed && (
                <span className={classes.progressSpinnerSnoozed}>
                  <CircularProgress className="spinner" size={20} />
                </span>
              )}
              <FormControlLabel onClick={onSetSnoozed} disabled={isSavingSnoozed} control={<Switch checked={snoozed} />} label="Snooze student" />
            </span>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.container}>
        {page === 'overview' && <StudentOverview student={student} alerts={alerts} />}
        {page === 'notes' && <StudentInternalCommentsContainer student={student} />}
        {page === 'messages' && <SharedCommentsAsAdviserContainer student={student} />}
        {page === 'completed-alerts' && <CompletedAlerts studentId={student.id} />}
        {page === 'history' && <StudentHistory studentId={student.id} />}
      </div>
    </div>
  );
};

export default AdviserStudentPage;
