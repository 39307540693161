import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Forward as ForwardIcon } from '@material-ui/icons';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import FlipMove from 'react-flip-move';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import Comment from './Comment';

class CommentsList extends React.Component {
  forwardAsMessage = (comment) => {
    const { onForward, navigateTo, studentId } = this.props;
    copy(comment.content);
    onForward(comment);
    navigateTo(`/adviser/students/${studentId}/messages`);
  };

  render() {
    const { comments, classes, studentId, viewingAsStudent, forwardable, highlight } = this.props;
    if (comments.length === 0) return null;
    const commentsCopy = comments.map((comment) => ({ ...comment, isStudent: comment.author.personId === studentId }));
    return (
      <div className={classes.root}>
        <FlipMove maintainContainerHeight>
          {commentsCopy.map((comment) => (
            <Comment highlight={highlight} key={comment.id} comment={comment} viewingAsStudent={viewingAsStudent}>
              {forwardable ? (
                <Tooltip id="copy" title="Copy this into a new message to student">
                  <Button onClick={() => this.forwardAsMessage(comment)} size="small" variant="text" className={classes.replyButton}>
                    <ForwardIcon className={classes.icon} />
                  </Button>
                </Tooltip>
              ) : null}
            </Comment>
          ))}
        </FlipMove>

        <div className={classes.clearfix} />
      </div>
    );
  }
}

CommentsList.propTypes = {
  comments: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onForward: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  studentId: PropTypes.string,
  viewingAsStudent: PropTypes.bool,
  forwardable: PropTypes.bool,
  highlight: PropTypes.string,
};

CommentsList.defaultProps = {
  studentId: null,
  viewingAsStudent: false,
  forwardable: false,
  highlight: '',
};

const styles = () => ({
  root: {
    paddingTop: 30,
  },
  clearfix: {
    clear: 'both',
  },

  replyButton: {
    minWidth: 24,
    position: 'relative',
    top: -1,
    margin: 0,
    '& svg': {
      height: 20,
      width: 20,
    },
  },
});

export default connect(null, (dispatch) => ({
  onForward: (comment) => {
    dispatch({ type: 'ADD_FORWARDED_COMMENT', comment });
  },
  navigateTo: (url) => dispatch(push(url)),
}))(withStyles(styles)(CommentsList));
