import { Button, Grid, TextField as MaterialTextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { length, required } from 'redux-form-validators';
import ErrorMessage from '../common/ErrorMessage';
import { Checkbox, TextField } from '../wrappers/material';
import DropzoneField from './DropzoneField';
import FormError from './FormError';

const ReferralForm = ({ handleSubmit,
  lookupAdviser, submitting, error, serverErrorMessages, adviserName, validationErrors, formValues, uploadFile }) => {
  const [uploading, updateUploading] = useState(false);

  let shrinkAdviserLabel = false;
  if (adviserName) {
    shrinkAdviserLabel = true;
  }
  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormError value={error}/>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={8}>
          <Field
            name="studentEmailOrId"
            component={TextField}
            placeholder="Student email or ID"
            label="Student email or ID"
            type="text"
            validate={required({ msg: 'Student email or ID is required' })}
            fullWidth
            onBlur={() => lookupAdviser(validationErrors, formValues)}
          />
        </Grid>
        <Grid item xs={4}>
          <MaterialTextField
            id="studentAdviser"
            fullWidth
            disabled
            label="Student adviser"
            value={adviserName}
            InputLabelProps={{ shrink: shrinkAdviserLabel }}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            className="resizeable-textarea"
            name="message"
            component={TextField}
            label="Message"
            type="text"
            margin="normal"
            rows="8"
            multiline
            validate={[required({ msg: 'Please add some text' }), length({ max: 10000 })]}
            fullWidth
          />
        </Grid>
      </Grid>
      <ErrorMessage errorMessages={serverErrorMessages}/>

      <Field name="attachments" component={DropzoneField} padded onUploadingChanged={(isUploading) => updateUploading(isUploading)} multiple uploadFile={uploadFile} />

      <div>
        <Field name="specialConsideration" component={Checkbox} label="This is regarding special consideration"/>
      </div>

      <div className="actions">
        <Button variant="contained" color="primary" type="submit" disabled={submitting || uploading} fullWidth>
          {submitting ? 'Submitting' : 'Submit'}
        </Button>
      </div>
    </form>
  );
};

const formName = 'referral';

ReferralForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  lookupAdviser: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  error: PropTypes.any,
  adviserName: PropTypes.string,
  serverErrorMessages: PropTypes.array,
  uploadFile: PropTypes.func.isRequired,
};

ReferralForm.defaultProps = {
  error: undefined,
  adviserName: undefined,
  serverErrorMessages: [],
  formValues: {},
};

const ReferralForRedux = reduxForm({ form: 'referral' })(ReferralForm);

const mapStateToProps = (state) => ({
  formValues: getFormValues(formName)(state),
  validationErrors: getFormSyncErrors(formName)(state),
});
export default connect(mapStateToProps)(ReferralForRedux);
