import { Button, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';
import React from 'react';
import DialogTitleWithClose from './DialogTitleWithClose';

const Transition = React.forwardRef((props, ref) => <Slide ref={ref} direction="left" {...props} />);

const ConfirmationDialog = ({ title, content, children, open, cancelButtonText, okButtonText, onCancel, onConfirm, submitting, maxWidth }) => {
  const onClose = onCancel || onConfirm;

  const actions = (
    <>
      {onCancel && (
        <Button variant="text" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      )}
      <Button variant="text" color="primary" disabled={submitting} onClick={onConfirm}>
        {okButtonText}
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth} TransitionComponent={Transition}>
      <DialogTitleWithClose title={title} onClose={onClose} />
      <DialogContent>{content || children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  title: string,
  content: oneOfType([node, string]),
  onCancel: func,
  onConfirm: func.isRequired,
  open: bool,
  cancelButtonText: string,
  okButtonText: string,
  maxWidth: string,
  submitting: bool,
  children: oneOfType([node, arrayOf(node)]),
};

ConfirmationDialog.defaultProps = {
  open: false,
  title: 'Confirm',
  cancelButtonText: 'Cancel',
  okButtonText: 'OK',
  maxWidth: 'sm',
  submitting: false,
  onCancel: undefined,
  content: undefined,
  children: undefined,
};

export default ConfirmationDialog;
