import React from 'react';
import { Paper, Button, SnackbarContent, DialogTitle, DialogActions, Dialog, DialogContent, withStyles, withWidth } from '@material-ui/core';
import { Warning as WarningIcon, CalendarToday as LaunchIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { blue } from '@material-ui/core/colors';
import * as model from '../../model';

class StudentBookAppointment extends React.Component {
  state = {
    dialogOpen: false,
  };

  openDialog = () => this.setState({ dialogOpen: true });

  closeDialog = () => this.setState({ dialogOpen: false });

  render() {
    const { classes, adviser, width } = this.props;
    const { dialogOpen } = this.state;

    return (
      <>
        <SnackbarContent
          className={classes.snackbar}
          aria-describedby="client-snackbar"
          message={
            <div id="client-snackbar" className={classes.message}>
              <WarningIcon className={classes.icon} />
              <div>
                We strongly recommend against booking same-day appointments as your Education Success Adviser will not have had time to prepare for
                your meeting and may not see the calendar event in time. Please consider booking an appointment for tomorrow or later unless your case
                is urgent.
              </div>
            </div>
          }
        />

        {width !== 'xs' && (
          <Button style={{ marginBottom: 15 }} onClick={this.openDialog} variant="contained">
            Can't see the calendar?
          </Button>
        )}

        <Paper className={classes.root}>
          <div className={classes.container}>
            {adviser.calendarUrl ? (
              <>
                {width === 'xs' ? (
                  <Button
                    className={classes.externalCalendarButton}
                    variant="outlined"
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={adviser.calendarUrl}
                  >
                    Pick a timeslot <LaunchIcon />
                  </Button>
                ) : (
                  <iframe className={classes.iframe} title="Appointments" src={adviser.calendarUrl} />
                )}
              </>
            ) : (
              <div>Your adviser has not yet setup their calendar for appointments.</div>
            )}
          </div>
        </Paper>

        <Dialog onClose={this.closeDialog} aria-labelledby="no-calendar-dialog" open={dialogOpen} maxWidth="md">
          <DialogTitle id="no-calendar-dialog">Can't see the calendar?</DialogTitle>
          <DialogContent>
            If no calendar displays, it may be due to a difference in your computer or browser plugins or permissions. Please try:
            <ul>
              <li>
                <a href={adviser.calendarUrl} rel="noopener noreferrer" target="_blank">
                  clicking here
                </a>{' '}
                to access this appointment calendar in a new tab
              </li>
              <li>
                opening this page in a different browser, preferably{' '}
                <a style={{ color: 'white' }} href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">
                  Google Chrome
                </a>
              </li>
            </ul>
            If all else fails, send a message to your adviser to arrange an appointment time directly.
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

StudentBookAppointment.propTypes = {
  classes: PropTypes.object.isRequired,
  adviser: model.user.isRequired,
  width: PropTypes.string.isRequired,
};

const styles = {
  root: {
    padding: 10,
  },
  container: {
    position: 'relative',
    paddingBottom: '56.25%' /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */,
    paddingTop: '30px',
    height: '0',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    border: 'none',
    top: '-165px',
    left: '0',
    width: '100%',
    height: 'calc(100% + 165px)',
  },
  externalCalendarButton: {
    width: '100%',
    '& svg': {
      marginLeft: 5,
    },
  },
  snackbar: {
    backgroundColor: blue[700],
    marginBottom: 20,
    maxWidth: 1000,
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: 20,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default compose(
  withStyles(styles),
  withWidth(),
)(StudentBookAppointment);
