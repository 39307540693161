import { get } from 'lodash';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import includes from 'lodash/includes';

import admin, * as fromAdmin from './admin';
import advisers, * as fromAdvisers from './advisers';
import announcements, * as fromAnnouncements from './announcements';
import applicationSettings, * as fromApplicationSettings from './application-settings';
import auth, * as fromAuth from './auth';
import alerts, * as fromAlerts from './by-student/alerts';
import auditLogs, * as fromAuditLogs from './by-student/audit-logs';
import comments, * as fromComments from './by-student/comments';
import advisingAppointments, * as fromAdvisingAppointments from './by-student/advising-appointments';
import forwardedComment, * as fromForwardedComment from './forwardedComment';
import messageTemplates, * as fromMessageTemplates from './message-templates';
import referenceData, * as fromReferenceData from './referenceData';
import studentDetails, * as fromStudentDetails from './studentDetails';
import currentStudentDetails, * as fromCurrentStudentDetails from './currentStudentDetails';
import students, * as fromStudents from './students';
import users, * as fromUsers from './users';
import drawer, * as fromDrawer from './drawer';
import jobs, * as fromJobs from './jobs';
import enrolmentUnits, * as fromEnrolmentUnits from './enrolmentUnits';

/**
 * Root reducer for the app.
 */
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form,
    auth,
    admin,
    users,
    students,
    referenceData,
    advisers,
    studentDetails,
    currentStudentDetails,
    alerts,
    comments,
    advisingAppointments,
    announcements,
    auditLogs,
    messageTemplates,
    forwardedComment,
    applicationSettings,
    drawer,
    jobs,
    enrolmentUnits,
  });

export const getLoggedInUserId = (state) => get(fromAuth.getUser(state.auth), 'id');

export const getLoggedInUser = (state) => fromUsers.getById(state.users, getLoggedInUserId(state));
export const isAdmin = (state) => includes(getLoggedInUser(state).roles, 'ADMIN');
export const isAdviser = (state) => includes(getLoggedInUser(state).roles, 'ADVISER');
export const isStaff = (state) => includes(getLoggedInUser(state).roles, 'STAFF');

export const getIsAuthenticated = (state) => fromAuth.getIsAuthenticated(state.auth);

export const isInviteUserDialogOpen = (state) => fromAdmin.isInviteUserDialogOpen(state.admin);
export const getUsers = (state) => fromUsers.getAll(state.users);
export const getUser = (state, id) => fromUsers.getById(state.users, id);

export const getStudentsForCurrentAdviser = (state) => fromStudents.getAllForAdviser(state.students);
export const getStudentsForAdviser = (state, adviserId) => fromStudents.getAllForAdviser(state.students, adviserId);
export const getStudentsForAdviserLoading = (state) => fromStudents.getAllForAdviserLoading(state.students);
export const getStudentsMatchCount = (state) => fromStudents.getStudentsMatchCount(state.students);

export const getStudentDetails = (state, id) => fromStudentDetails.getById(state.studentDetails, id);
export const getStudentDetailsLoading = (state) => fromStudentDetails.getLoading(state.studentDetails);

export const getCurrentStudentDetails = (state) => fromCurrentStudentDetails.get(state.currentStudentDetails);
export const getCurrentStudentDetailsLoading = (state) => fromCurrentStudentDetails.getLoading(state.currentStudentDetails);

export const isAlertCompleted = (state, id) => {
  const alert = fromAlerts.getById(state.alerts, id);
  return alert && alert.completed;
};
export const getOpenAlertsForStudent = (state, studentId) => fromAlerts.getOpenForStudent(state.alerts, studentId);
export const getOpenAlertsForStudentLoading = (state) => fromAlerts.getAllForStudentLoading(state.alerts);
export const getCompletedAlertsForStudent = (state, studentId) => fromAlerts.getCompletedForStudent(state.alerts, studentId);
export const isCompletedAlertsForStudentLoading = (state) => fromAlerts.isAllCompletedForStudentLoading(state.alerts);

export const getComments = (state, studentId = getLoggedInUserId(state)) => fromComments.getAll(state.comments, studentId);
export const getCommentsLoading = (state) => fromComments.getAllLoading(state.comments);

export const getAuditLogs = (state, studentId) => fromAuditLogs.getAll(state.auditLogs, studentId);
export const getAuditLogsLoading = (state) => fromAuditLogs.getAllLoading(state.auditLogs);

export const getReferenceDataRoles = (state) => fromReferenceData.getAll(state.referenceData, 'Role');
export const getReferenceDataCampuses = (state) => fromReferenceData.getAll(state.referenceData, 'CampusLocation');
export const getReferenceDataGenders = (state) => fromReferenceData.getAll(state.referenceData, 'Gender');

export const getReferenceData = (state, type) => fromReferenceData.getAll(state.referenceData, type);
export const getReferenceDataDescription = (state, type, id) => fromReferenceData.getDescription(state.referenceData, type, id);
export const getReferenceDataOrdinal = (state, type, id) => fromReferenceData.getOrdinal(state.referenceData, type, id);

export const getAuditActionDescription = (state, action) => getReferenceDataDescription(state, 'AuditAction', action);

export const getMessageTemplateSummaries = (state) => fromMessageTemplates.getAllSummaries(state.messageTemplates);
export const getMessageTemplates = (state) => fromMessageTemplates.getAll(state.messageTemplates);
export const isMessageTemplatesLoading = (state) => fromMessageTemplates.getAllIsLoading(state.messageTemplates);
export const getMessageTemplate = (state, id) => fromMessageTemplates.getById(state.messageTemplates, id);

export const getAllAnnouncements = (state) => fromAnnouncements.getAll(state.announcements);
export const isGetAnnouncementsLoading = (state) => fromAnnouncements.isLoading(state.announcements);

export const getAllAdvisers = (state) => fromAdvisers.getAll(state.advisers);

export const getApplicationSettings = (state) => fromApplicationSettings.getSettings(state);

export const getForwardedComment = (state) => fromForwardedComment.get(state.forwardedComment);
export const getOpenDrawer = (state) => fromDrawer.getOpenDrawer(state.drawer);

export const getAllLockedJobs = (state) => fromJobs.getAllLockedJobs(state.jobs);

export const getSelectedEnrolmentUnits = (state, studentId, courseId) =>
  fromEnrolmentUnits.getSelectedEnrolmentUnits(state.enrolmentUnits, studentId, courseId);

export const getAdvisingAppointmentCount = (state, studentId) =>
  fromAdvisingAppointments.getAdvisingAppointmentCount(state.advisingAppointments, studentId);

export default rootReducer;
