import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, withStyles } from '@material-ui/core';
import { BeachAccess as BeachAccessIcon } from '@material-ui/icons';

const AdviserOnLeave = ({ classes, adviser }) => {
  if (adviser.onLeave) {
    return (
      <ListItemIcon className={classes.onLeave}>
        <BeachAccessIcon />
      </ListItemIcon>
    );
  }
  return null;
};

AdviserOnLeave.propTypes = {
  classes: PropTypes.object.isRequired,
  adviser: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  onLeave: {
    marginLeft: '10px',
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(AdviserOnLeave);
