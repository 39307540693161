import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import find from 'lodash/find';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getReferenceData } from '../../../reducers';

const StudentSearchCriteriaDisplayOnly = ({ classes, criteria, alertTypes }) => {
  const includesTextSearch = !!criteria.text;
  const includesAlertTypeFiltering = (criteria.alertTypes && !!criteria.alertTypes.length);
  const noFilters = !(includesTextSearch || includesAlertTypeFiltering);

  return (
    <div className={classes.root}>
      This action will apply to <b>{noFilters ? 'all' : 'any'} {criteria.all ? "(including other adviser's)" : 'of your'}</b> students
      {includesTextSearch && <span> that match the text search <b>{`"${criteria.text}"`}</b></span>}
      {includesAlertTypeFiltering && (
        <span> {criteria.text ? 'and match' : 'that match'} any of the following alert types: <b>{criteria.alertTypes.map((at) => `"${find(alertTypes, { id: at }).description}"`).join(', ')}</b></span>
      )}
    </div>
  );
};

StudentSearchCriteriaDisplayOnly.propTypes = {
  classes: PropTypes.object.isRequired,
  alertTypes: PropTypes.array.isRequired,
  criteria: PropTypes.object.isRequired,
};

const styles = {
  root: {
    marginBottom: 10,
  },
  alertTypesSelect: {
    margin: '10px 0',
    '& select': {
      height: 65,
    },
  },
};

export default compose(
  withStyles(styles),
  connect((state) => ({
    alertTypes: getReferenceData(state, 'AlertType'),
  })),
)(StudentSearchCriteriaDisplayOnly);
