import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Progress from '../../components/common/Progress';
import TimeAgo from '../../components/common/TimeAgo';
import { getReferenceData } from '../../reducers';
import api from '../../services/api';

const useStyles = makeStyles((theme) =>
  createStyles({
    noData: {
      textAlign: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    fixedContainer: { height: 850, overflow: 'auto' },
    maxContainer: { maxHeight: 850, overflow: 'auto' },
    muted: {
      color: grey[500],
    },
    groupedLogType: {
      display: 'inline-block',
      textAlign: 'right',
      width: 110,
      fontWeight: 500,
    },
    groupedLogDetails: {
      display: 'inline-block',
      marginLeft: theme.spacing(3),
    },
    groupedLogCreated: {
      display: 'inline-block',
      width: 150,
      marginLeft: theme.spacing(3),
      textAlign: 'right',
    },
    cellTopAligned: {
      verticalAlign: 'top',
    },
  }),
);

const ActivityLogWidget = ({ adviserId, fixHeight }) => {
  const [groupedActivityLogs, setGroupedActivityLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const activityTypes = useSelector((state) => getReferenceData(state, 'ActivityType'));
  const history = useHistory();
  const classes = useStyles();

  const toDescription = (type) => {
    const match = activityTypes.find((it) => it.id === type);
    return match ? match.description : type;
  };

  useEffect(() => {
    setLoading(true);
    api.advisers
      .listGroupedActivityLogs(adviserId)
      .then((result) => setGroupedActivityLogs(result))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Card className={fixHeight ? classes.fixedContainer : classes.maxContainer}>
      <CardHeader title="Activity logs" />
      <CardContent>
        {loading && <Progress />}

        {!loading && groupedActivityLogs.length === 0 ? (
          <div className={classes.noData}>No activity to show yet.</div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedActivityLogs.map((activityLog) => (
                <TableRow
                  key={`${activityLog.student.personId}-${activityLog.created}`}
                  className="clickable"
                  onClick={() => history.push(`/adviser/students/${activityLog.student.personId}`)}
                >
                  <TableCell className={classes.cellTopAligned}>
                    <ul className="unstyled-list">
                      <li>{activityLog.student.name}</li>
                      <li>
                        <Typography variant="caption">
                          <TimeAgo value={activityLog.created} />
                        </Typography>
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell className={classes.cellTopAligned}>
                    <ul className="unstyled-list">
                      {activityLog.groupedLogs.map((groupedLog) => (
                        <li key={`${groupedLog.type}-${groupedLog.created}`}>
                          <div className={classes.groupedLogType}>
                            <span>{toDescription(groupedLog.type)}</span>
                          </div>
                          <div className={classes.groupedLogCreated}>
                            <Typography variant="caption">{moment(groupedLog.created).format('lll')}</Typography>
                          </div>
                          <div className={classes.groupedLogDetails}>
                            <Typography variant="caption">{groupedLog.details}</Typography>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

ActivityLogWidget.propTypes = {
  adviserId: PropTypes.string.isRequired,
  fixHeight: PropTypes.bool.isRequired,
};

export default ActivityLogWidget;
