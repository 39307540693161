import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Alert from 'react-s-alert';
import api from '../../../services/api';
import ActionDialog from '../../common/ActionDialog';
import _ from 'lodash';

const ArchiveStudents = ({
  enabled,
  students,
  onActionCompleted,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const toggleDialogState = () => setDialogOpen(!dialogOpen);

  const handleCompleteAlerts = () => {
    const promise = api.advisers.archiveStudents(students.map((student) => student.id));
    return promise
      .then(() => {
        Alert.success('Archive has been queued for completion. Please note your student count will only be recalculated after this process has been completed');
        toggleDialogState();
        onActionCompleted();
      }).catch((error) => {
        toggleDialogState();
        onActionCompleted();
        console.log(error);
        Alert.error('Failed to archive some/all students. Please try it again or contact Juvo support');
      });
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={toggleDialogState} disabled={!enabled}>
        Archive students
      </Button>

      <ActionDialog
        title={
          _.filter(students, (student) => student.openActionAlertCount > 0).length === 0 ?
            `Archiving ${students.length} student(s)`
            : `Archiving ${students.length} student(s) and ${_.filter(students, (student) => student.openActionAlertCount > 0).length} have at least one open action alert`
        }
        submitButtonText="Archive Students"
        open={dialogOpen}
        onCancel={toggleDialogState}
        onSubmit={handleCompleteAlerts}
      />
    </>
  );
};

ArchiveStudents.propTypes = {
  enabled: PropTypes.bool.isRequired,
  students: PropTypes.array.isRequired,
  onActionCompleted: PropTypes.func.isRequired,
};

export default ArchiveStudents;
