import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import debounce from 'lodash/debounce';
import moment from 'moment';
import filter from 'lodash/filter';
import * as JsSearch from 'js-search';
import CommentsList from './CommentsList';
import CommentsSearchForm from './CommentsSearchForm';

function dateToNumber(date) {
  if (!date) return null;
  return parseInt(moment(date).format('YYYYMMDD'), 10);
}

const removeHtmlTags = (content) => content.replace(/(<([^>]+)>)/gi, '');

class Comments extends Component {
  static propTypes = {
    comments: PropTypes.array.isRequired,
    studentId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    noCommentsMessage: PropTypes.string,
    viewingAsStudent: PropTypes.bool,
    forwardable: PropTypes.bool,
    searchable: PropTypes.bool,
    searchLabel: PropTypes.string,
  };

  static defaultProps = {
    viewingAsStudent: false,
    forwardable: false,
    searchable: false,
    noCommentsMessage: null,
    searchLabel: 'Search messages',
  };

  state = {
    searchCriteria: {},
    indexedComments: [],
    filteredComments: [],
  };

  componentDidMount() {
    this.prepareCommentsForSearch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.comments.length !== this.props.comments.length) {
      this.prepareCommentsForSearch();
    }
  }

  prepareCommentsForSearch() {
    const { comments } = this.props;
    const commentsCopy = comments.map((comment) => ({
      ...comment,
      searchableContent: `${removeHtmlTags(comment.content)} ${comment.author.name}`,
      dateAsNumber: dateToNumber(comment.created),
    }));

    this.searchIndex = new JsSearch.Search('id');
    this.searchIndex.addIndex('searchableContent');
    this.searchIndex.addDocuments(commentsCopy);

    this.setState({
      indexedComments: commentsCopy,
      filteredComments: commentsCopy,
    });
  }

  onSearch = (search) => {
    let results = this.state.indexedComments.map((comment) => ({ ...comment }));

    if (search.text && search.text.length > 1) {
      results = this.searchIndex.search(search.text);
    }

    if (search.startDate || search.endDate) {
      const startDateAsNumber = dateToNumber(search.startDate);
      const endDateAsNumber = dateToNumber(search.endDate);

      results = filter(results, (comment) => {
        if (startDateAsNumber && comment.dateAsNumber < startDateAsNumber) return false;
        return !(endDateAsNumber && comment.dateAsNumber > endDateAsNumber);
      });
    }

    this.setState({ filteredComments: results, searchCriteria: search });
  };

  search = debounce(this.onSearch.bind(this), 300);

  render() {
    const { classes, studentId, viewingAsStudent, forwardable, searchable, searchLabel, comments, noCommentsMessage } = this.props;

    const { filteredComments, searchCriteria } = this.state;

    return (
      <Paper elevation={1}>
        {searchable && !!comments.length && <CommentsSearchForm onChange={this.search} label={searchLabel} />}

        <div className={classes.commentsWrapper}>
          {!comments.length && noCommentsMessage ? (
            <div className={classes.noCommentsMessage}>{noCommentsMessage}</div>
          ) : (
            <CommentsList
              highlight={searchCriteria.text}
              comments={filteredComments}
              studentId={studentId}
              viewingAsStudent={viewingAsStudent}
              forwardable={forwardable}
            />
          )}
        </div>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  attachments: {
    marginTop: theme.spacing(2),
    paddingLeft: 10,
  },
  noCommentsMessage: {
    paddingTop: theme.spacing(4),
    textAlign: 'center',
    opacity: '0.7',
  },
  commentsWrapper: {
    padding: theme.spacing(0, 4, 4, 4),
    minHeight: 260,
  },
  feedbackContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  feedbackText: {
    paddingLeft: theme.spacing(),
    color: grey[800],
    fontWeight: 300,
    marginTop: 3,
  },
});

export default withStyles(styles)(Comments);
