import * as PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ErrorMessage from '../../common/ErrorMessage';
import StudentSearchCriteriaDisplayOnly from './StudentSearchCriteriaDisplayOnly';
import ChipDisplay, { ChipComponentDisplay } from '../../common/ChipDisplay';
import { getReferenceDataDescription } from '../../../reducers';
import DatePicker from './DatePicker';

const useStyles = makeStyles({
  warningMessage: {
    margin: 20,
  },
});

const CompleteAlertsContent = ({
  students,
  allStudentsMatchingSearchSelected,
  searchCriteria,
  errorMessages,
  acceptDate,
}) => {
  const classes = useStyles();
  const toDescription = (alertType) => useSelector((state) => getReferenceDataDescription(state, 'AlertType', alertType));
  return (
    <div>
      <ErrorMessage errorMessages={errorMessages}/>

      {allStudentsMatchingSearchSelected ? (
        <StudentSearchCriteriaDisplayOnly criteria={searchCriteria}/>
      ) : (
        <ChipDisplay
          label="Students"
          entries={students.map((student) => ({ key: student.id, label: student.name }))}
        />
      )}
      <ChipDisplay
        label="Alert types"
        entries={searchCriteria.alertTypes.map((alertType) => ({ key: alertType, label: toDescription(alertType) }))}
      />
      <ChipComponentDisplay label="Complete alerts created before/on the picked date">
        <DatePicker onChange={acceptDate}/>
      </ChipComponentDisplay>
      <div className={classes.warningMessage}>
        <strong>Warning: </strong>This will automatically complete all open alerts of the types specified for the{' '}
        selected students. This cannot be undone.
      </div>
    </div>
  );
};


CompleteAlertsContent.defaultProps = {
  errorMessages: [],
};

CompleteAlertsContent.propTypes = {
  students: PropTypes.array.isRequired,
  allStudentsMatchingSearchSelected: PropTypes.bool.isRequired,
  errorMessages: PropTypes.array,
  searchCriteria: PropTypes.object.isRequired,
  acceptDate: PropTypes.func.isRequired,
};

export default CompleteAlertsContent;
