import { Grid, Typography, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import AdviserResourcesButton from '../common/AdviserResourcesButton';
import JuvoAvatar from '../common/JuvoAvatar';
import AdviserStats from './AdviserStats';

const AdviserHeader = ({ adviser, classes }) => (
  <div className={classes.root}>
    <div className={classes.avatarWrapper}>
      <div>
        <JuvoAvatar image={adviser.image} />
      </div>
    </div>
    <div className={classes.adviserDetailsWrapper}>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Typography className={cx(classes.title, { [classes.titleRed]: adviser.onLeave })} variant="h6">
            {adviser.name}
          </Typography>
          <AdviserStats adviser={adviser} />
        </Grid>

        <Grid item sm={12} md={6}>
          <div className={classes.links}>
            <AdviserResourcesButton />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

AdviserHeader.propTypes = {
  adviser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = {
  root: {
    backgroundColor: '#fff',
    padding: '20px 30px 10px',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.15)',
  },
  avatarWrapper: {
    float: 'left',
  },
  adviserDetailsWrapper: {
    marginLeft: 100,
  },
  title: {
    fontSize: '2em',
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: 10,
  },
  titleRed: {
    color: red[600],
  },
  links: {
    float: 'right',
  },
  studentCount: {
    color: 'black',
    fontSize: '1.5em',
    position: 'relative',
    top: 3,
  },
};

export default withStyles(styles)(AdviserHeader);
