import { func, object } from 'prop-types';
/* eslint-disable react/no-array-index-key,react/jsx-indent,react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMessageTemplate, updateMessageTemplate } from '../../actions/message-templates';
import SaveMessageTemplate from '../../components/message-templates/SaveMessageTemplate';
import { getMessageTemplate } from '../../reducers';


class UpdateMessageTemplatePage extends Component {
  static propTypes = {
    update: func.isRequired,
    messageTemplate: object,
    fetchMessageTemplateFromServer: func.isRequired,
  };

  static defaultProps = {
    messageTemplate: undefined,
  };

  componentDidMount() {
    this.props.fetchMessageTemplateFromServer();
  }

  render() {
    const { update, messageTemplate } = this.props;

    return (
      <SaveMessageTemplate
        title="Update message template"
        successMessage="Message template updated"
        onSubmit={update}
        initialValues={messageTemplate}
        loading={!messageTemplate}
      />
    );
  }
}

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  messageTemplate: getMessageTemplate(state, id),
});
const mapDispatchToProps = (dispatch, { match: { params: { id } } }) => ({
  update: (data) => dispatch(updateMessageTemplate(id, data)),
  fetchMessageTemplateFromServer: () => dispatch(fetchMessageTemplate(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMessageTemplatePage);
