import { requestJSON } from './http';

const create = (quickLink) => requestJSON('/quick-links', 'POST', quickLink);

const update = (quickLink) => requestJSON('/quick-links', 'PUT', quickLink);

const deleteLink = (quickLinkId) => requestJSON(`/quick-links/${quickLinkId}`, 'DELETE');

const list = () => requestJSON('/quick-links');

export default {
  list,
  create,
  update,
  deleteLink,
};
